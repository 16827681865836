/* eslint-disable react/destructuring-assignment */
import React from 'react';
import withRouter from '../hocs/withRouter';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (prevProps.location.pathname !== location.pathname) {
      this.setState({ hasError: false });
    }
  }

  componentDidCatch(error, info) {
    // You can use your own error logging service here
    console.log({ error, info });
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>An unexpected error occurred</h1>;
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
