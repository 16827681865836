import Select from 'react-select';
import { cn } from '@/lib/utils';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused ? null : null,
  }),
};

const CustomSelect = ({ className, placeholder, ...props }) => {
  return (
    <Select
      {...props}
      className={cn('w-32 text-sm font-medium text-[#646464]', className)}
      menuPlacement="auto"
      styles={customStyles}
      theme={(theme) => ({
        ...theme,
        borderRadius: 4,
        colors: {
          ...theme.colors,
          primary25: '#EDF6EA',
          primary: '#20ac76',
        },
      })}
      placeholder={placeholder}
    />
  );
};

export default CustomSelect;
