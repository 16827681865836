import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Table, { ActionsHeader, ActionsPopover } from '../../components/table/appTable';
import searchIcon from '../../assets/images/icons/search-icon.svg';
import { deleteStore } from '../../redux/features/warehouse/warehouseSlice';

const AllStoresTable = ({ fetchData, pageCount, onSearch, setInitialPageSize, initialPageSize }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');

  const handleDelete = useCallback(
    (id) => {
      dispatch(deleteStore(id))
        .unwrap()
        .then(() => {
          fetchData({ pageSize: initialPageSize, pageIndex: 0 });
        });
    },
    [initialPageSize],
  );

  const handleChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
    onSearch(value);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'store.id',
      },
      {
        Header: 'Store Name',
        accessor: 'store.name',
      },
      {
        Header: 'Store Manager',
        accessor: 'manager',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ value }) => {
          if (!value) return <span>-</span>;
          return <span>{value.first_name + ' ' + value.last_name}</span>;
        },
      },
      {
        Header: 'State',
        accessor: 'store.state',
      },
      {
        Header: 'LGA',
        accessor: 'store.lga',
      },
      {
        Header: ActionsHeader,
        accessor: '_id',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ value }) => (
          <ActionsPopover
            actions={[
              {
                text: 'View',
                onClickAction: () => navigate(`/warehouse/all-stores/${value}`),
              },
              {
                text: 'Edit',
                onClickAction: () => navigate(`/warehouse/all-stores/edit/${value}`),
              },
              {
                text: 'Delete',
                onClickAction: () => {
                  const response = window.confirm('Are you sure you want to delete this item?');

                  if (response) {
                    handleDelete(value);
                  }
                },
              },
            ]}
          />
        ),
      },
    ],
    [handleDelete],
  );

  const { warehouses, warehousesLoading } = useSelector((state) => state.warehouse);

  return (
    <>
      <div className="mb-7 mt-4 flex flex-col justify-between lg:flex-row ">
        <h3 className="mb-2 text-2xl font-semibold lg:mb-0">All Stores</h3>
        <div className="flex flex-col gap-3 md:flex-row md:items-center md:gap-4">
          <div className="relative h-[32px] overflow-auto">
            <input
              onChange={handleChange}
              className="form-input mb-2 h-full w-[25vw] min-w-[300px] rounded border border-[#C3C3C3] bg-white px-7 text-[13px] placeholder-[#7D7D7D] focus:outline-none md:mb-0"
              type="search"
              name="search"
              id="search"
              value={searchTerm}
              placeholder="Search"
            />
            <img className="absolute left-[10px] top-[50%] w-[12px] translate-y-[-50%]" src={searchIcon} alt="Search icon" />
          </div>
          {/* <button className="bg-[#64B548] rounded-md h-[35px] w-[90px] text-white text-[13px]">
            <span className="flex justify-center gap-[10px]">
              <img className="w-4" src={filterIcon} alt="Filter icon" />
              <span>Filter</span>
            </span>
          </button> */}
        </div>
      </div>
      <div className="overflow-hidden text-gray-900">
        <main className="overflow-hidden">
          <div className="w-full">
            <Table columns={columns} data={warehouses} fetchData={fetchData} loading={warehousesLoading} pageCount={pageCount} pageSize={initialPageSize} setInitialPageSize={setInitialPageSize} />
          </div>
        </main>
      </div>
    </>
  );
};

export default AllStoresTable;
