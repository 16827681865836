import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStoreCountTableColumns from './storeCountTableColumns';
import AppTable from '@/components/table/appTableNew';
import { deleteStoreCount } from '@/redux/features/storeCount/storeCountSlice';
import Spinner from '@/components/Spinner';

const StoreCountTable = ({ pageCount, setPageSize, pageSize, setPage }) => {
  const dispatch = useDispatch();
  const { storeCounts, storeCountsLoading, deleteStoreCountLoading } = useSelector((state) => state.storeCount);

  const handleDelete = useCallback((id) => {
    dispatch(deleteStoreCount(id))
      .unwrap()
      .then(() => {
        setPage(0);
      });
  }, []);

  const columns = useStoreCountTableColumns(handleDelete);

  return (
    <>
      {deleteStoreCountLoading && <Spinner />}
      <div className="overflow-hidden text-gray-900">
        <main className="overflow-hidden">
          <div className="w-full">
            <AppTable columns={columns} data={storeCounts} loading={storeCountsLoading} pageCount={pageCount} pageSize={pageSize} setPageSize={setPageSize} setPage={setPage} />
          </div>
        </main>
      </div>
    </>
  );
};

export default StoreCountTable;
