import React from 'react';
import { motion } from 'framer-motion';
import cameraIcon from '@/assets/images/icons/camera-icon.svg';

const UploadProof = ({ setShow }) => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ type: 'tween', duration: 0.2 }}
      exit={{
        opacity: 0,
        scale: 0,
        transition: { ease: 'easeInOut', duration: 0.2 },
      }}
      className="w-[90%] max-w-[550px] rounded-xl bg-white px-6 py-6"
    >
      <h3 className="mb-4 mt-4 text-lg font-semibold text-[#101828]">Upload Attachment</h3>
      <form>
        <div className="col-span-full mb-7">
          <div className="mt-2 flex justify-center rounded-lg border border-[#EAECF0] px-6 py-10">
            <div className="text-center">
              <img className="mx-auto h-12 w-12" src={cameraIcon} alt="camera icon" aria-hidden="true" />
              {/* <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" /> */}
              <div className="mt-4 flex text-sm leading-6 text-gray-600">
                <label htmlFor="file-upload" className="relative cursor-pointer rounded-md bg-white font-semibold text-[#92CC42] focus-within:outline-none hover:text-[#92CC42]">
                  <span>Click to upload</span>
                  <input id="file-upload" name="file-upload" type="file" className="sr-only" />
                </label>
                <p className="pl-1">or drag and drop</p>
              </div>
              <p className="text-xs leading-5 text-gray-600">PNG or JPG (max. 5mb)</p>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between gap-3">
          <button className="w-full rounded border border-[#C3C3C3] py-3 text-xs text-[#646464]" type="button" onClick={() => setShow(false)}>
            Cancel
          </button>
          <button className="w-full rounded border border-transparent bg-[#64B548] py-3 text-xs text-white" type="submit">
            Confirm
          </button>
        </div>
      </form>
    </motion.div>
  );
};

export default UploadProof;
