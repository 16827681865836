/* eslint-disable no-unused-vars */
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ActionsHeader, ActionsPopover } from '@/components/table/appTable';

export default function usePurchaseOrderTableColumns(handleDelete) {
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'orderId',
        Cell: ({ value }) => (!value ? <span>-</span> : <span className="inline-block max-w-[60px] truncate align-middle">{value}</span>),
      },
      {
        Header: 'Store Name',
        accessor: 'store.store.name',
        Cell: ({ value }) => (!value ? <span>-</span> : <span>{value}</span>),
      },
      {
        Header: 'Agent ID',
        accessor: 'supplier._id',
        Cell: ({ value }) => (!value ? <span>-</span> : <span className="inline-block max-w-[65px] truncate align-middle">{value}</span>),
      },
      {
        Header: 'Agent Name',
        accessor: 'supplier.name',
        Cell: ({ value }) => (!value ? <span>-</span> : <span>{value}</span>),
      },
      {
        Header: 'Product Type',
        accessor: 'product.category.name',
        Cell: ({ value }) => (!value ? <span>-</span> : <span>{value}</span>),
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
        Cell: ({ value }) => (!value ? <span>-</span> : <span>{value}</span>),
      },
      {
        Header: 'Total Weight(Kg)',
        accessor: 'weight',
        Cell: ({ value }) => (!value ? <span>-</span> : <span>{value}</span>),
      },
      {
        Header: ActionsHeader,
        accessor: '_id',
        Cell: ({ value }) => (
          <ActionsPopover
            actions={[
              {
                text: 'View',
                onClickAction: () => navigate(`purchase-order/${value}`),
              },
              {
                text: 'Delete',
                onClickAction: () => {
                  const response = window.confirm('Confirm delete?');

                  if (response) {
                    handleDelete(value);
                  }
                },
              },
            ]}
          />
        ),
      },
    ],
    [],
  );

  return columns;
}
