import { Field } from 'formik';
import classNames from 'classnames';

const Input = (props) => {
  const { label, name, toggleViewPasswordIcon, ...rest } = props;

  return (
    <>
      <label className="form-control-label" htmlFor={name}>
        {label}
      </label>
      <Field name={name}>
        {({
          field,
          //  form,
          meta,
        }) => {
          return (
            <>
              <div className="relative">
                <input className="form-control-input" {...field} {...rest} />
                {toggleViewPasswordIcon && <div className="absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer">{toggleViewPasswordIcon}</div>}
              </div>
              <small
                className={classNames('text-[red]', 'block', 'opacity-0', {
                  'opacity-100': meta.touched && meta.error,
                })}
              >
                {meta.error || 'error'}
              </small>
            </>
          );
        }}
      </Field>
    </>
  );
};

export default Input;
