/* eslint-disable no-unused-vars */
import { useMemo } from 'react';
import { ActionsHeader, Ellipsis, RequestStatusPill } from '@/components/table/appTable';
import { getRowLabel } from '../../shared/roles';
import { RequestTableActions } from './requestTableActions';

const statuses = ['pending', 'rejected', 'toBePaid', 'completed'];

export default function useRequestTableColumns() {
  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: '_id',
        Cell: ({ value }) => (!value ? <span>-</span> : <span className="inline-block max-w-[50px] truncate align-middle">{value}</span>),
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: ({ value }) => (!value ? <span>-</span> : <span className="inline-block max-w-[100px] truncate align-middle">{value}</span>),
      },
      {
        Header: 'Item',
        accessor: 'item',
        Cell: ({ value }) => (!value ? <span>-</span> : <span>{value}</span>),
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
        Cell: ({ value }) => (!value ? <span>-</span> : <span>{value}</span>),
      },
      {
        Header: 'Unit',
        accessor: 'unit.name',
        Cell: ({ value }) => (!value ? <span>-</span> : <span>{value}</span>),
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        Cell: ({ value }) => (!value ? <span>-</span> : <span>{value}</span>),
      },
      {
        Header: 'Comment',
        accessor: 'comment',
        Cell: ({ value }) => (!value ? <span>-</span> : <span className="inline-block max-w-[100px] truncate align-middle">{value}</span>),
      },
      {
        Header: 'Requester',
        accessor: 'requestedBy',
        Cell: ({ value }) => (!value ? <span>-</span> : <span>{`${value.first_name[0]}. ${value.last_name[0]}`}</span>),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => <RequestStatusPill value={value} />,
      },
      {
        Header: <ActionsHeader show />,
        accessor: 'id',
        Cell: ({ row: { original } }) => <RequestTableActions value={original} />,
      },
    ],
    [],
  );

  return columns;
}
