import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import FormikControl from '@/components/formik/formikControl';
import Spinner from '@/components/Spinner';
import FormTemplate from '@/components/templates/formTemplate';
import { getAllCategories } from '@/redux/features/settings/outboundSettings/outboundCategorySlice';
import { editItem, getItem } from '@/redux/features/settings/outboundSettings/outboundItemSlice';
import { getAllUnits } from '@/redux/features/settings/outboundSettings/outboundUnitSlice';

const EditOutboundSettingsItem = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { editItemLoading, getItemLoading } = useSelector((state) => state.outboundItem);
  const { getAllCategoriesLoading } = useSelector((state) => state.outboundCategory);
  const { getAllUnitsLoading } = useSelector((state) => state.outboundUnit);
  const [initialValues, setInitialValues] = useState({
    name: '',
    category: '',
    unit: '',
    active: false,
  });
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);

  const statusOptions = [
    { label: 'Select status', value: '', isDisabled: true },
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
  ];

  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    category: Yup.string().required('Required'),
    unit: Yup.string().required('Required'),
    active: Yup.bool('Value must be true or false').required('Required'),
  });

  const onSubmit = (values) => {
    dispatch(editItem({ id: params.id, ...values }))
      .unwrap()
      .then(() => {
        navigate(-1);
      });
  };

  useEffect(() => {
    dispatch(getAllUnits())
      .unwrap()
      .then((result) => {
        const options = [];
        result.data.data.forEach((value) => {
          options.push({
            label: value.name,
            value: value._id,
          });
        });
        setUnitOptions([{ label: 'Select Units', value: '', isDisabled: true }, ...options]);
      });
    dispatch(getAllCategories())
      .unwrap()
      .then((result) => {
        const options = [];
        result.data.data.forEach((value) => {
          options.push({
            label: value.name,
            value: value._id,
          });
        });
        setCategoryOptions([{ label: 'Select Categories', value: '', isDisabled: true }, ...options]);
      });
  }, []);

  useEffect(() => {
    dispatch(getItem(params.id))
      .unwrap()
      .then((response) => {
        setInitialValues({
          name: response.data.name,
          category: response.data.category,
          unit: response.data.unit,
          active: response.data.active,
        });
      });
  }, []);

  const loading = editItemLoading || getItemLoading || getAllCategoriesLoading || getAllUnitsLoading;

  return (
    <FormTemplate initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} title="Edit Item" submitBtnText="Save Changes" enableReinitialize>
      {loading && <Spinner />}
      <div>
        <FormikControl control="input" type="text" label="Add Items" name="name" placeholder="Item Name" />
      </div>
      <div>
        <FormikControl control="select" label="Category" name="category" options={categoryOptions} />
      </div>
      <div>
        <FormikControl control="select" label="Unit" name="unit" options={unitOptions} />
      </div>
      <div>
        <FormikControl control="select" label="Status" name="active" options={statusOptions} />
      </div>
    </FormTemplate>
  );
};

export default EditOutboundSettingsItem;
