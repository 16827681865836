import { useState } from 'react';
import { AppHeader, AppSidebar } from '../components';

const Layout = ({ children }) => {
  const [showSidebar, setShowSidebar] = useState(true);
  const [collapseSidebar, setCollapseSidebar] = useState(false);

  return (
    <div>
      <AppSidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} collapseSidebar={collapseSidebar} setCollapseSidebar={setCollapseSidebar} />
      <div className={`${collapseSidebar ? 'md:ml-[80px]' : 'md:ml-[240px]'} flex min-h-screen flex-col`}>
        <AppHeader setShowSidebar={setShowSidebar} />
        <div className="grow bg-neutral">
          <div className="px-4 py-4 md:px-8 md:py-8">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
