import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '@/services/instance';
import { pendingHandler, rejectionHandler, successHandler } from '@/shared/requestHandlers';

export const getStoreCounts = createAsyncThunk('storeCount/getStoreCounts', async (queryParams) => {
  const { data } = await instance.get('/storeCounts', { params: queryParams });
  return data;
});

export const getStoreCount = createAsyncThunk('storeCount/getStoreCount', async (id) => {
  const { data } = await instance.get(`/storeCounts/getOne?_id=${id}`);
  return data;
});

export const createStoreCountComment = createAsyncThunk('storeCount/createStoreCountComment', async (payload) => {
  const { data } = await instance.post('/storeCounts/comments', payload);
  return data;
});

export const deleteStoreCount = createAsyncThunk('storeCount/deleteStoreCount', async (id) => {
  const { data } = await instance.delete(`/storeCounts?_id=${id}`);
  return data;
});

export const getInventoryDetails = createAsyncThunk('storeCount/getInventoryDetails', async (queryParams) => {
  const { data } = await instance.get('/storeCounts/inventoryDetails', { params: queryParams });
  return data;
});

const initialState = {
  storeCounts: [],
  storeCount: null,
  totalInbounds: 0,
  totalOutbounds: 0,
  totalInventoryPosition: 0,
  inventoryDetails: null,
  storeCountsLoading: false,
  getStoreCountLoading: false,
  deleteStoreCountLoading: false,
  createCommentLoading: false,
  inventoryDetailsLoading: false,
  error: '',
};

const storeCountSlice = createSlice({
  name: 'storeCount',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStoreCounts.pending, (state) => {
        pendingHandler(state, 'storeCountsLoading');
      })
      .addCase(getStoreCounts.fulfilled, (state, { payload: { data } }) => {
        state.storeCounts = data.data;
        state.totalInbounds = data.totalInbounds;
        state.totalOutbounds = data.totalOutbounds;
        state.totalInventoryPosition = data.totalInventoryPosition;
        successHandler(state, 'storeCountsLoading');
      })
      .addCase(getStoreCounts.rejected, (state, action) => {
        rejectionHandler(state, action, 'storeCountsLoading');
      })
      .addCase(getStoreCount.pending, (state) => {
        pendingHandler(state, 'getStoreCountLoading');
      })
      .addCase(getStoreCount.fulfilled, (state, { payload: { data } }) => {
        state.storeCount = data;
        successHandler(state, 'getStoreCountLoading');
      })
      .addCase(getStoreCount.rejected, (state, action) => {
        rejectionHandler(state, action, 'getStoreCountLoading');
      })
      .addCase(createStoreCountComment.pending, (state) => {
        pendingHandler(state, 'createCommentLoading');
      })
      .addCase(createStoreCountComment.fulfilled, (state) => {
        // state.units.push(data)
        successHandler(state, 'createCommentLoading', 'Commend added');
      })
      .addCase(createStoreCountComment.rejected, (state, action) => {
        rejectionHandler(state, action, 'createCommentLoading');
      })
      .addCase(getInventoryDetails.pending, (state) => {
        pendingHandler(state, 'inventoryDetailsLoading');
      })
      .addCase(getInventoryDetails.fulfilled, (state, { payload: { data } }) => {
        state.inventoryDetails = data;
        successHandler(state, 'inventoryDetailsLoading');
      })
      .addCase(getInventoryDetails.rejected, (state, action) => {
        rejectionHandler(state, action, 'inventoryDetailsLoading');
      })
      .addCase(deleteStoreCount.pending, (state) => {
        pendingHandler(state, 'deleteStoreCountLoading');
      })
      .addCase(deleteStoreCount.fulfilled, (state) => {
        successHandler(state, 'deleteStoreCountLoading', 'Store count deleted');
      })
      .addCase(deleteStoreCount.rejected, (state, action) => {
        rejectionHandler(state, action, 'deleteStoreCountLoading');
      });
  },
});

export default storeCountSlice.reducer;
