/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import AuthPageLayout from '@/layout/authPageLayout';
import { loginUser } from '@/redux/features/user/userSlice';
import AuthInput from './shared/authInput';
import * as authService from '@/services/authService';
import Button from './shared/authButton';

const OTPVerify = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const [userDetails, setUserDetails] = useState({
    username: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [otp, setOtp] = useState('');

  const redirectPath = location.state?.path || '/';

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({ ...userDetails, [name]: value });
  };

  // eslint-disable-next-line no-shadow
  const handleOtpChange = (otp) => {
    setOtp(otp);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      loginUser({
        phone_number: userDetails.username,
        password: userDetails.password,
      }),
    );
  };

  if (authService.getToken()) {
    return <Navigate to={redirectPath} replace />;
  }

  return (
    <AuthPageLayout
      title="Input Code"
      subTitle="Please input the verification code sent to your
      email address"
    >
      <form autoComplete="off" onSubmit={handleSubmit}>
        <div className="text-black">
          <OtpInput
            containerStyle={{ display: 'flex', gap: 40 }}
            inputStyle={{
              width: '100%',
              borderBottom: '1px solid #646464',
              outline: 'none',
              fontSize: 24,
            }}
            value={otp}
            onChange={handleOtpChange}
            numInputs={6}
            separator={<span />}
          />
        </div>
        <p className="mt-8 cursor-pointer text-center text-sm">
          You didn’t receive a code? <span className="text-light-green">Resend code</span>
        </p>
        <Button type="button" onClick={() => navigate('/reset-password')}>
          Verify Code
        </Button>
      </form>
    </AuthPageLayout>
  );
};

export default OTPVerify;
