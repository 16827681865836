/* eslint-disable no-nested-ternary */
import { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import Spinner from '@/components/Spinner';
import GridItem from '@/components/gridItem';
import ViewItemTemplate from '@/components/templates/viewItemTemplate';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { getOneOrder } from '@/redux/features/orders/ordersSlice';
import { getInboundData } from '../../redux/features/inbound/inboundSlice';
import { buildQueryParams } from '@/shared/buildQueryParams';
import PaymentHistoryTable from './paymentHistoryTable';
import InboundDetailsTable from './inboundDetailsTable';

const ViewFinanceDetails = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const { orderItem, loading } = useSelector((state) => state.orders);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const { id, orderId } = params;
  // Table states
  // eslint-disable-next-line no-unused-vars
  const [_page, setPage] = useState(0);
  const [pageSize1, setPageSize] = useState(5);
  // eslint-disable-next-line no-unused-vars
  const [pageCount, setPageCount] = useState(0);

  const [tabs, setTabs] = useState([
    {
      title: 'Inbound Record',
      active: true,
      status: '',
    },
  ]);

  // Set active tab
  const setActiveTab = (selectedTab, index) => {
    setActiveTabIndex(index);
    setTabs(
      tabs.map((tab) => {
        tab.active = tab.title === selectedTab.title;
        return tab;
      }),
    );
  };

  // Initial Query Params object
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const userQuery = { skip: 0, limit: 5, orderId };
  const fetchIdRef = useRef(0);

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      userQuery.limit = pageSize;
      userQuery.skip = pageIndex * pageSize;

      // eslint-disable-next-line no-plusplus
      const fetchId = ++fetchIdRef.current;
      if (fetchId === fetchIdRef.current) {
        dispatch(getInboundData(buildQueryParams(userQuery)))
          .unwrap()
          .then((result) => {
            setPageCount(Math.ceil(result.data.totalDocumentCount / pageSize));
          });
      }
    },
    [dispatch, userQuery],
  );

  const renderTable = () => {
    switch (activeTabIndex) {
      case 0:
        return <InboundDetailsTable fetchData={fetchData} pageCount={pageCount} setPageSize={setPageSize} initialPageSize={pageSize1} setPage={setPage} tableHeading={tabs[activeTabIndex].title} />;
      case 1:
        return <PaymentHistoryTable pageCount={0} setPageSize={setPageSize} pageSize={pageSize1} setPage={setPage} tableHeading={tabs[activeTabIndex].title} tableData={orderItem?.payments} />;
      default:
        return <InboundDetailsTable fetchData={fetchData} pageCount={pageCount} setPageSize={setPageSize} initialPageSize={pageSize1} setPage={setPage} tableHeading={tabs[activeTabIndex].title} />;
    }
  };

  useEffect(() => {
    dispatch(getOneOrder(id));
  }, [dispatch, id]);

  return (
    <>
      <ViewItemTemplate title={orderItem?.type === 'purchaseOrder' ? 'Purchase Order Details' : orderItem?.type === 'salesOrder' ? 'Sales Order Details' : `Transshipment Details`} headerCta={<div />}>
        {loading && <Spinner />}
        <div className="grid grid-cols-3 gap-8">
          <GridItem title="Order ID" content={orderItem?.orderId || '-'} />
          {orderItem?.type === 'transshipment' && <GridItem title="Supplying Store" content={orderItem?.supplyingStore?.store?.name || '-'} />}
          {orderItem?.type === 'transshipment' && <GridItem title="Receiving Store" content={orderItem?.receivingStore?.store?.name || '-'} />}
          {orderItem?.type === 'transshipment' && <GridItem title="Supplying Store ID" content={orderItem?.supplyingStore?.store?.id || '-'} />}
          {orderItem?.type === 'transshipment' && <GridItem title="Receiving Store ID" content={orderItem?.receivingStore?.store?.id || '-'} />}
          {orderItem?.type === 'purchaseOrder' || orderItem?.type === 'salesOrder' ? <GridItem title="Store Name" content={orderItem?.store?.store?.name || '-'} /> : null}
          {/* {orderItem?.type === 'purchaseOrder' || orderItem?.type === 'salesOrder' ? <GridItem title="Supplier ID" content={orderItem?.supplier?._id || '-'} /> : null} */}
          {orderItem?.type === 'purchaseOrder' || orderItem?.type === 'salesOrder' ? <GridItem title="Store ID" content={orderItem?.store?.store?.id || '-'} /> : null}
          <GridItem title="Product Type" content={orderItem?.product?.category?.name || '-'} />
          <GridItem title="Product Name" content={orderItem?.product?.name || '-'} />
          {orderItem?.type === 'purchaseOrder' || orderItem?.type === 'salesOrder' ? <GridItem title="Supplier Name" content={orderItem?.store?.store?.name || '-'} /> : null}
          <GridItem title="Status" content={<Badge variant={orderItem?.status === 'completed' ? 'primary' : 'destructive'}>{orderItem?.status}</Badge>} />
          <GridItem title="Quantity" content={orderItem?.quantity} />
          <GridItem title="Amount" content={`₦ ${orderItem?.amount}`} />
        </div>
      </ViewItemTemplate>

      <h3 className="mb-10 mt-10 text-2xl font-semibold">Order History</h3>
      <div className="flex gap-4 overflow-auto">
        {tabs.map((tab, index) => (
          <Button key={uuid()} variant={tab.active ? 'default' : 'outline'} className="rounded" size="sm" onClick={() => setActiveTab(tab, index)}>
            {tab.title}
          </Button>
        ))}
      </div>
      {renderTable()}
    </>
  );
};

export default ViewFinanceDetails;
