/* eslint-disable no-plusplus */
import { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getWarehouses } from '@/redux/features/warehouse/warehouseSlice';
import { buildQueryParams } from '@/shared/buildQueryParams';
import AllStoresTable from './allStoresTable';

// Initial Query Params object
const userQuery = { skip: 0, limit: 5 };

const AllStoresHome = () => {
  const dispatch = useDispatch();

  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = useRef(0);
  const [initialPageSize, setInitialPageSize] = useState(5);

  // FetchData function for Paginated React Table
  const fetchData = useCallback(({ pageSize, pageIndex }) => {
    userQuery.limit = pageSize;
    userQuery.skip = pageIndex * pageSize;

    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current) {
      dispatch(getWarehouses(buildQueryParams(userQuery)))
        .unwrap()
        .then((result) => {
          setPageCount(Math.ceil(result.data.totalDocumentCount / pageSize));
        });
    }
  }, []);

  const handleSearch = (term) => {
    if (term) {
      userQuery.search = term;
    } else {
      delete userQuery.search;
    }
    fetchData({ pageSize: initialPageSize, pageIndex: 0 });
  };

  return <AllStoresTable setInitialPageSize={setInitialPageSize} initialPageSize={initialPageSize} fetchData={fetchData} pageCount={pageCount} onSearch={handleSearch} />;
};

export default AllStoresHome;
