/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */

import { useDispatch } from 'react-redux';
import { useCallback, useRef, useState } from 'react';
import AuditLogsTable from './logs';
import { buildQueryParams } from '@/shared/buildQueryParams';
import { getAuditLogs } from '@/redux/features/settings/auditLog/auditLogSlice';

// Initial Query Params object
const userQuery = { skip: 0, limit: 5 };

const AuditLogHome = () => {
  const dispatch = useDispatch();
  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = useRef(0);

  // FetchData function for Paginated React Table
  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      userQuery.limit = pageSize;
      userQuery.skip = pageIndex * pageSize;

      const fetchId = ++fetchIdRef.current;
      if (fetchId === fetchIdRef.current) {
        dispatch(getAuditLogs(buildQueryParams(userQuery)))
          .unwrap()
          .then((result) => {
            setPageCount(Math.ceil(result.data.totalDocumentCount / pageSize));
          });
      }
    },
    [dispatch],
  );

  return <AuditLogsTable fetchData={fetchData} pageCount={pageCount} />;
};

export default AuditLogHome;
