import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import MetricCard from '@/components/metricCard/metricCard';
import AddIcon from '@/components/svgs/AddIcon';
import ChevronSolid from '@/components/svgs/ChevronSolid';
import { ActionsPopover } from '@/components/table/appTable';
import { Button } from '@/components/ui/button';
import AllOrderTable from './allOrderTable';
import { buildQueryParams } from '@/shared/buildQueryParams';
import PurchaseOrderTable from './purchaseOrderTable';
import SalesOrderTable from './salesOrderTable';
import TransShipmentTable from './transShipmentTable';
import { getAllOrdersData } from '@/redux/features/orders/ordersSlice';

const OrderRecordsHome = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { totalCreated, totalPending, totalCompleted } = useSelector((state) => state.orders);

  // Table states
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [pageCount, setPageCount] = useState(0);

  // Query Params
  const [userQuery, setUserQuery] = useState({});

  // Update userQuery when page size and current page change
  useEffect(() => {
    setUserQuery((prevState) => ({ ...prevState, skip: page * pageSize, limit: pageSize }));
  }, [page, pageSize]);

  // Fetch data when user query changes
  useEffect(() => {
    if (!_.isEmpty(userQuery)) {
      dispatch(getAllOrdersData(buildQueryParams(userQuery)))
        .unwrap()
        .then((result) => {
          setPageCount(Math.ceil(result.data.totalDocumentCount / userQuery.limit));
        });
    }
  }, [userQuery]);

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabs, setTabs] = useState([
    {
      title: 'All Order',
      active: true,
      status: '',
    },
    {
      title: 'Purchase Order',
      active: false,
      status: 'pending',
    },
    {
      title: 'Sales Order',
      active: false,
      status: 'completed',
    },
    {
      title: 'Transshipment',
      active: false,
    },
  ]);

  const setActiveTab = (selectedTab, index) => {
    setActiveTabIndex(index);
    setTabs(
      tabs.map((tab) => {
        tab.active = tab.title === selectedTab.title;
        return tab;
      }),
    );
  };

  const renderTable = () => {
    switch (activeTabIndex) {
      case 0:
        return <AllOrderTable pageCount={pageCount} setPageSize={setPageSize} pageSize={pageSize} setPage={setPage} tableHeading={tabs[activeTabIndex].title} />;
      case 1:
        return <PurchaseOrderTable pageCount={pageCount} setPageSize={setPageSize} pageSize={pageSize} setPage={setPage} tableHeading={tabs[activeTabIndex].title} />;
      case 2:
        return <SalesOrderTable pageCount={pageCount} setPageSize={setPageSize} pageSize={pageSize} setPage={setPage} tableHeading={tabs[activeTabIndex].title} />;
      case 3:
        return <TransShipmentTable pageCount={pageCount} setPageSize={setPageSize} pageSize={pageSize} setPage={setPage} tableHeading={tabs[activeTabIndex].title} />;
      default:
        return <AllOrderTable pageCount={pageCount} setPageSize={setPageSize} pageSize={pageSize} setPage={setPage} tableHeading={tabs[activeTabIndex].title} />;
    }
  };

  return (
    <>
      <div className="mb-8 flex flex-col justify-between gap-3 lg:flex lg:flex-row lg:gap-0">
        <h1 className="text-2xl font-semibold text-title-color">Order Records</h1>
        <div className="filters flex flex-col gap-4 md:flex-row md:items-center">
          <ActionsPopover
            actions={[
              {
                text: 'Purchase Order',
                onClickAction: () => navigate('create-purchase-order'),
              },
              {
                text: 'Sales Order',
                onClickAction: () => navigate('create-sales-order'),
              },
              {
                text: 'Transshipment',
                onClickAction: () => navigate('create-transshipment'),
              },
            ]}
            trigger={
              <Button className="rounded" size="lg">
                <AddIcon className="mr-2" /> Create Order <ChevronSolid className="ml-2" />
              </Button>
            }
            side="bottom"
            sideOffset={0}
            alignOffset={0}
            align="end"
          />
        </div>
      </div>
      <div className="mb-5 grid grid-cols-1 justify-between gap-8 md:grid-cols-2 lg:grid-cols-3">
        <MetricCard title="Total Created" leftUnit="" leftValue={totalCreated} />
        <MetricCard title="Total Pending" leftUnit="" leftValue={totalPending} />
        <MetricCard title="Total Completed" leftUnit="" leftValue={totalCompleted} />
      </div>
      <div className="mt-6 flex gap-4 overflow-auto">
        {tabs.map((tab, index) => (
          <Button key={uuid()} variant={tab.active ? 'default' : 'outline'} className="rounded" size="sm" onClick={() => setActiveTab(tab, index)}>
            {tab.title}
          </Button>
        ))}
      </div>
      {renderTable()}
    </>
  );
};

export default OrderRecordsHome;
