import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '@/services/instance';
import { pendingHandler, rejectionHandler, successHandler } from '@/shared/requestHandlers';

export const createImageType = createAsyncThunk('imageTypeSettings/createImageType', async (postData) => {
  const { data } = await instance.post('/image-types', postData);
  return data;
});

export const editImageType = createAsyncThunk('imageTypeSettings/editImageType', async (patchData) => {
  const { data } = await instance.patch('/image-types', patchData);
  return data;
});

export const deleteImageType = createAsyncThunk('imageTypeSettings/deleteImageType', async (id) => {
  const { data } = await instance.delete(`/image-types?_id=${id}`);
  return data;
});

export const getImageType = createAsyncThunk('imageTypeSettings/getImageType', async (id) => {
  const { data } = await instance.get(`/image-types/getOne?_id=${id}`);
  return data;
});

export const getImageTypes = createAsyncThunk('imageTypeSettings/getImageTypes', async (queryParams) => {
  const { data } = await instance.get(`/image-types${queryParams}`);
  return data;
});

const initialState = {
  imageTypes: [],
  allImageTypes: [],
  createImageTypeLoading: false,
  getImageTypesLoading: false,
  getImageTypeLoading: false,
  editImageTypeLoading: false,
  deleteImageTypeLoading: false,
  getAllSuppliersLoading: false,
  error: '',
};

const imageTypeSlice = createSlice({
  name: 'imageTypeSettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createImageType.pending, (state) => {
        pendingHandler(state, 'createImageTypeLoading');
      })
      .addCase(createImageType.fulfilled, (state) => {
        // state.suppliers.push(data)
        successHandler(state, 'createImageTypeLoading', 'Image type created');
      })
      .addCase(createImageType.rejected, (state, action) => {
        rejectionHandler(state, action, 'createImageTypeLoading');
      })
      .addCase(deleteImageType.pending, (state) => {
        pendingHandler(state, 'deleteImageTypeLoading');
      })
      .addCase(deleteImageType.fulfilled, (state) => {
        successHandler(state, 'deleteImageTypeLoading', 'Image type deleted');
      })
      .addCase(deleteImageType.rejected, (state, action) => {
        rejectionHandler(state, action, 'deleteImageTypeLoading');
      })
      .addCase(getImageTypes.pending, (state) => {
        pendingHandler(state, 'getImageTypesLoading');
      })
      .addCase(getImageTypes.fulfilled, (state, { payload: { data } }) => {
        state.imageTypes = data.data;
        successHandler(state, 'getImageTypesLoading');
      })
      .addCase(getImageTypes.rejected, (state, action) => {
        rejectionHandler(state, action, 'getImageTypesLoading');
      })
      .addCase(getImageType.pending, (state) => {
        pendingHandler(state, 'getImageTypeLoading');
      })
      .addCase(getImageType.fulfilled, (state) => {
        successHandler(state, 'getImageTypeLoading');
      })
      .addCase(getImageType.rejected, (state, action) => {
        rejectionHandler(state, action, 'getImageTypeLoading');
      })
      .addCase(editImageType.pending, (state) => {
        pendingHandler(state, 'editImageTypeLoading');
      })
      .addCase(editImageType.fulfilled, (state) => {
        successHandler(state, 'editImageTypeLoading', 'Supplier updated');
      })
      .addCase(editImageType.rejected, (state, action) => {
        rejectionHandler(state, action, 'editImageTypeLoading');
      });
  },
});

export default imageTypeSlice.reducer;
