/* eslint-disable no-plusplus */
import { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getWarehouses } from '../../../../redux/features/warehouse/warehouseSlice';
import { buildQueryParams } from '../../../../shared/buildQueryParams';
import AddStoreForm from './addStoreForm';
import AddStoreTable from './addStoreTable';

// Initial Query Params object
const userQuery = { skip: 0, limit: 5 };

const AddStore = () => {
  const dispatch = useDispatch();
  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = useRef(0);
  const [initialPageSize, setInitialPageSize] = useState(2);

  const fetchData = useCallback(({ pageSize, pageIndex }) => {
    userQuery.limit = pageSize;
    userQuery.skip = pageIndex * pageSize;

    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current) {
      dispatch(getWarehouses(buildQueryParams(userQuery)))
        .unwrap()
        .then((result) => {
          setPageCount(Math.ceil(result.data.totalDocumentCount / pageSize));
        });
    }
  }, []);

  return (
    <div className="mb-9">
      <h3 className="mb-6 text-2xl font-semibold">Add Store</h3>
      <AddStoreForm initialPageSize={initialPageSize} fetchData={fetchData} />
      <AddStoreTable setInitialPageSize={setInitialPageSize} initialPageSize={initialPageSize} fetchData={fetchData} pageCount={pageCount} />
    </div>
  );
};

export default AddStore;
