import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import instance from '@/services/instance';
import { pendingHandler, rejectionHandler, successHandler } from '@/shared/requestHandlers';

export const getInboundData = createAsyncThunk('inbound/getInboundData', async (queryParams) => {
  const { data } = await instance.get(`/inBound/getAll${queryParams}`);
  return data;
});
// &_id=${'668f92206f096c00151d7378'}
export const exportInboundData = createAsyncThunk('inbound/exportInboundData', async () => {
  const { data } = await instance.get(`/inBound/export`);
  return data;
});

export const getInboundItem = createAsyncThunk('inbound/getInboundItem', async (id) => {
  const { data } = await instance.get(`/inBound/getOne?_id=${id}`);
  return data;
});

export const editInboundItem = createAsyncThunk('inbound/editInboundItem', async (payload) => {
  const { data } = await instance.patch(`/inBound`, payload);
  return data;
});

export const getInboundPercentages = createAsyncThunk('inbound/getInboundPercentages', async () => {
  const { data } = await instance.get('/inBound/getPercentages');
  return data;
});

export const createBulkInbound = createAsyncThunk('inbound/createBulkInbound', async (payload) => {
  const { data } = await instance.post('/inBound/createMany', payload);
  return data;
});

export const deleteInboundItem = createAsyncThunk('inbound/deleteInboundItem', async (id) => {
  const { data } = await instance.delete(`/inBound?_id=${id}`);
  return data;
});

export const getPaymentHistory = createAsyncThunk('inBound/payments/getPaymentHistory', async (id) => {
  const { data } = await instance.get(`/inBound/payments?_id=${id}`);
  return data;
});

const initialState = {
  inboundData: [],
  inboundItem: null,
  totalBags: 0,
  totalCost: 0,
  totalWeight: 0,
  percentages: [],
  pageCount: 0,
  loading: false,
  itemLoading: false,
  editItemLoading: false,
  deleteItemLoading: false,
  exportInboundDataLoading: false,
  getInboundPercentagesLoading: false,
  createBulkInboundLoading: false,
  getPaymentHistory: [],
  getPaymentHistoryLoading: false,
  error: '',
};

const inboundSlice = createSlice({
  name: 'inbound',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInboundData.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(getInboundData.fulfilled, (state, { payload: { data } }) => {
        state.inboundData = data.data;
        state.totalBags = data.totalBags;
        state.totalCost = data.totalCost;
        state.totalWeight = data.totalWeight;
        state.loading = false;
        state.error = '';
      })
      .addCase(getInboundData.rejected, (state, action) => {
        state.error = action.error.message || 'An error occurred';
        state.loading = false;
        toast.error(action.error.message || 'An error occurred');
      })
      .addCase(exportInboundData.pending, (state) => {
        state.exportInboundDataLoading = true;
        state.error = '';
      })
      .addCase(exportInboundData.fulfilled, (state) => {
        state.exportInboundDataLoading = false;
        state.error = '';
      })
      .addCase(exportInboundData.rejected, (state, action) => {
        state.error = action.error.message || 'An error occurred';
        state.exportInboundDataLoading = false;
        toast.error(action.error.message || 'An error occurred');
      })
      .addCase(getInboundItem.pending, (state) => {
        state.itemLoading = true;
        state.error = '';
      })
      .addCase(getInboundItem.fulfilled, (state, { payload: { data } }) => {
        state.inboundItem = data;
        state.itemLoading = false;
        state.error = '';
      })
      .addCase(getInboundItem.rejected, (state, action) => {
        state.error = action.error.message || 'An error occurred';
        state.itemLoading = false;
        toast.error(action.error.message || 'An error occurred');
      })
      .addCase(editInboundItem.pending, (state) => {
        state.editItemLoading = true;
        state.error = '';
      })
      .addCase(editInboundItem.fulfilled, (state) => {
        state.editItemLoading = false;
        state.error = '';
        toast.success('Item updated');
      })
      .addCase(editInboundItem.rejected, (state, action) => {
        state.error = action.error.message || 'An error occurred';
        state.editItemLoading = false;
        toast.error(action.error.message || 'An error occurred');
      })
      .addCase(getInboundPercentages.pending, (state) => {
        state.getInboundPercentagesLoading = true;
        state.error = '';
      })
      .addCase(getInboundPercentages.fulfilled, (state, { payload: { data } }) => {
        state.percentages = data;
        state.getInboundPercentagesLoading = false;
        state.error = '';
      })
      .addCase(getInboundPercentages.rejected, (state, action) => {
        state.error = action.error.message || 'An error occurred';
        state.getInboundPercentagesLoading = false;
        toast.error(action.error.message || 'An error occurred');
      })
      .addCase(createBulkInbound.pending, (state) => {
        state.createBulkInboundLoading = true;
        state.error = '';
      })
      .addCase(createBulkInbound.fulfilled, (state) => {
        state.createBulkInboundLoading = false;
        state.error = '';
        toast.success('Items imported');
      })
      .addCase(createBulkInbound.rejected, (state, action) => {
        state.error = action.error.message || 'An error occurred';
        state.createBulkInboundLoading = false;
        toast.error(action.error.message || 'An error occurred');
      })
      .addCase(deleteInboundItem.pending, (state) => {
        pendingHandler(state, 'deleteItemLoading');
      })
      .addCase(deleteInboundItem.fulfilled, (state) => {
        successHandler(state, 'deleteItemLoading', 'Inbound item deleted');
      })
      .addCase(deleteInboundItem.rejected, (state, action) => {
        rejectionHandler(state, action, 'deleteItemLoading');
      })

      .addCase(getPaymentHistory.pending, (state) => {
        state.getPaymentHistoryLoading = true;
        state.error = '';
      })
      .addCase(getPaymentHistory.fulfilled, (state, { payload: { data } }) => {
        state.getPaymentHistory = data;
        state.getPaymentHistoryLoading = false;
        state.error = '';
      })
      .addCase(getPaymentHistory.rejected, (state, action) => {
        state.error = action.error.message || 'An error occurred';
        state.getPaymentHistoryLoading = false;
        toast.error(action.error.message || 'An error occurred');
      });
  },
});

export default inboundSlice.reducer;
