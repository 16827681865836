import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '@/services/instance';
import { pendingHandler, rejectionHandler, successHandler } from '@/shared/requestHandlers';

export const addActivity = createAsyncThunk('activitySettings/inbound/addActivity', async (postData) => {
  const { data } = await instance.post('/activities', postData);
  return data;
});

export const editActivity = createAsyncThunk('activitySettings/inbound/editActivity', async (patchData) => {
  const { data } = await instance.patch('/activities', patchData);
  return data;
});

export const deleteActivity = createAsyncThunk('activitySettings/inbound/deleteActivity', async (id) => {
  const { data } = await instance.delete(`/activities?_id=${id}`);
  return data;
});

export const getActivity = createAsyncThunk('activitySettings/inbound/getActivity', async (id) => {
  const { data } = await instance.get(`/activities/getOne?_id=${id}`);
  return data;
});

export const getActivities = createAsyncThunk('activitySettings/inbound/getActivities', async (queryParams) => {
  const { data } = await instance.get(`/activities${queryParams}&bound=inbound`);
  return data;
});

export const getAllActivities = createAsyncThunk('activitySettings/inbound/getAllActivities', async () => {
  const { data } = await instance.get(`/activities?bound=inbound`);
  return data;
});

const initialState = {
  activities: [],
  allActivities: [],
  addActivityLoading: false,
  editActivityLoading: false,
  deleteActivityLoading: false,
  getActivityLoading: false,
  getActivitiesLoading: false,
  getAllActivitiesLoading: false,
  error: '',
};

const inboundActivitySlice = createSlice({
  name: 'activitySettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addActivity.pending, (state) => {
        pendingHandler(state, 'addActivityLoading');
      })
      .addCase(addActivity.fulfilled, (state) => {
        // state.activities.push(data)
        successHandler(state, 'addActivityLoading', 'Activity added');
      })
      .addCase(addActivity.rejected, (state, action) => {
        rejectionHandler(state, action, 'addActivityLoading');
      })
      .addCase(getActivity.pending, (state) => {
        pendingHandler(state, 'getActivityLoading');
      })
      .addCase(getActivity.fulfilled, (state) => {
        successHandler(state, 'getActivityLoading');
      })
      .addCase(getActivity.rejected, (state, action) => {
        rejectionHandler(state, action, 'getActivityLoading');
      })
      .addCase(editActivity.pending, (state) => {
        pendingHandler(state, 'editActivityLoading');
      })
      .addCase(editActivity.fulfilled, (state) => {
        successHandler(state, 'editActivityLoading', 'Activity updated');
      })
      .addCase(editActivity.rejected, (state, action) => {
        rejectionHandler(state, action, 'editActivityLoading');
      })
      .addCase(deleteActivity.pending, (state) => {
        pendingHandler(state, 'deleteActivityLoading');
      })
      .addCase(deleteActivity.fulfilled, (state) => {
        successHandler(state, 'deleteActivityLoading', 'Activity deleted');
      })
      .addCase(deleteActivity.rejected, (state, action) => {
        rejectionHandler(state, action, 'deleteActivityLoading');
      })
      .addCase(getActivities.pending, (state) => {
        pendingHandler(state, 'getActivitiesLoading');
      })
      .addCase(getActivities.fulfilled, (state, { payload: { data } }) => {
        state.activities = data.data;
        successHandler(state, 'getActivitiesLoading');
      })
      .addCase(getActivities.rejected, (state, action) => {
        rejectionHandler(state, action, 'getActivitiesLoading');
      })
      .addCase(getAllActivities.pending, (state) => {
        pendingHandler(state, 'getAllActivitiesLoading');
      })
      .addCase(getAllActivities.fulfilled, (state, { payload: { data } }) => {
        state.allActivities = data.data;
        successHandler(state, 'getAllActivitiesLoading');
      })
      .addCase(getAllActivities.rejected, (state, action) => {
        rejectionHandler(state, action, 'getAllActivitiesLoading');
      });
  },
});

export default inboundActivitySlice.reducer;
