/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { AreaChart, Area, XAxis, CartesianGrid, Tooltip, ResponsiveContainer, YAxis } from 'recharts';
import arrowTopRight from '@/assets/images/icons/arrow-top-right.svg';
import line from '@/assets/images/icons/line.svg';
import infoSign from '@/assets/images/icons/info-sign.svg';
import trendingDown from '@/assets/images/icons/trending-down.svg';
import { formatAmount } from '@/components/table/utils';

const OutboundChart = ({ data, loading: chartDataLoading }) => {
  // const { totalBags, totalCost, totalWeight, loading } = useSelector((state) => state.outbound)
  const [showChart, setShowChart] = useState(true);

  const outboundChartData = data?.outbounds;

  const outboundTotalWeight = data?.outbounds.reduce((acc, cum) => acc + cum.weight, 0);
  const outboundTotalCost = data?.outbounds.reduce((acc, cum) => acc + cum.cost, 0);
  const outboundTotalQuantity = data?.outbounds.reduce((acc, cum) => acc + cum.quantity, 0);

  return (
    <div className="overflow-auto">
      <div className="min-w-[700px] rounded-lg bg-white px-5 pb-2 pt-5 ">
        <div className="mb-5 flex items-center gap-3 text-lg font-medium text-title-color">
          <p>Outbound</p>
          <img src={infoSign} alt="info sign" />
          <div className="grow">
            <img className="w-full" src={line} alt="line" />
          </div>
          <img src={arrowTopRight} alt="arrow top right" />
        </div>
        {chartDataLoading ? (
          <p>Loading...</p>
        ) : (
          <div className="mb-10 flex items-center gap-[120px] overflow-auto">
            <div>
              <span className="text-3xl font-semibold text-[#222222]">{formatAmount(outboundTotalQuantity || 0)}</span>
              <sub className="ml-1 text-base font-semibold tracking-wide text-[#383838]">bags</sub>
            </div>
            <div>
              <span className="ml-1 text-3xl font-semibold tracking-wide text-[#383838]">&#8358;</span>
              <span className="text-3xl font-semibold text-[#222222]">{formatAmount(outboundTotalCost || 0)}</span>
            </div>
            <div>
              <span className="text-3xl font-semibold text-[#222222]">{formatAmount(outboundTotalWeight || 0)}</span>
              <sub className="ml-1 text-base font-semibold tracking-wide text-[#383838]">MT</sub>
            </div>
            <div className="hidden items-center gap-1">
              <span className="flex items-center gap-1 rounded bg-[#F9EBEB] px-[5px] py-[5px] text-xs font-medium text-[#9F2D2D]">
                <img src={trendingDown} alt="trending down" />
                29%
              </span>
              <span className="text-[10.5px]">from June</span>
            </div>
          </div>
        )}
        {showChart && (
          <ResponsiveContainer width="100%" aspect={3.6 / 1}>
            <AreaChart width={730} height={250} data={outboundChartData} margin={{ top: 0, right: 0, left: -20, bottom: 0 }}>
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.6} />
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0.2} />
                </linearGradient>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#C3C3C3" stopOpacity={0.6} />
                  <stop offset="95%" stopColor="#C3C3C3" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorAmt" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#d66baa" stopOpacity={0.6} />
                  <stop offset="95%" stopColor="#d66baa" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis dataKey="date" tick={{ fill: '#76767B', fontSize: 10 }} />
              <YAxis tick={{ fill: '#76767B', fontSize: 10 }} />
              <CartesianGrid vertical={false} />
              <Tooltip isAnimationActive={false} />
              <Area type="monotone" dataKey="weight" stroke="#8884d8" strokeWidth={2} fillOpacity={1} fill="url(#colorUv)" />
              <Area type="monotone" dataKey="cost" stroke="#C3C3C3" strokeWidth={2} fillOpacity={1} fill="url(#colorPv)" />
              <Area type="monotone" dataKey="quantity" stroke="#d66baa" strokeWidth={2} fillOpacity={1} fill="url(#colorAmt)" />
            </AreaChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

export default OutboundChart;
