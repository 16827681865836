import axios from 'axios';
import * as authService from './authService';

export const adminBaseUrl = `${process.env.REACT_APP_API_URL_STAGING}/api/v1/`;

const admin = axios.create({
  baseURL: adminBaseUrl,
});

admin.defaults.headers.post['Content-Type'] = 'application/json';

admin.interceptors.request.use(
  (config) => {
    const token = authService.getToken();
    const customerId = localStorage.getItem('customerId');

    if (customerId) {
      config.headers['x-ims-customerid'] = customerId;
    }

    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  },
);

export default admin;
