/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

const ActionStyle = ({ children, onClick, ...otherProps }) => {
  return (
    <div className="cursor-pointer text-sm text-[#474D66]" onClick={onClick} {...otherProps}>
      {children}
    </div>
  );
};

export default ActionStyle;
