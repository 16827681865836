import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import AppTable from '@/components/table/appTableNew';
import searchIcon from '@/assets/images/icons/search-icon.svg';
import useSalesOrderTableColumns from './salesOrderTableColumns';
import { getAllOrdersData, deleteOrder } from '@/redux/features/orders/ordersSlice';

const SalesOrderTable = ({ pageCount, setPageSize, pageSize, setPage, tableHeading }) => {
  const dispatch = useDispatch();
  const allOrderData = useSelector((state) => state.orders.allOrderData);
  const loading = useSelector((state) => state.orders.loading);

  const [deleted, setDeleted] = useState(false);

  useEffect(() => {
    dispatch(getAllOrdersData('?type=salesOrder'));
  }, [dispatch, deleted]);

  const handleDelete = useCallback(
    (id) => {
      dispatch(deleteOrder(id)).then(() => {
        // Toggle 'deleted' state to trigger re-render
        setDeleted((prev) => !prev);
      });
    },
    [setPage, dispatch],
  );

  const columns = useSalesOrderTableColumns(handleDelete);

  return (
    <>
      <div className="mb-7 mt-12 flex flex-col justify-between lg:flex-row ">
        <h3 className="mb-2 text-2xl font-semibold lg:mb-0">{tableHeading}</h3>
        <div className="flex flex-col gap-3 md:flex-row md:items-center md:gap-4">
          <div className="relative h-[32px] overflow-auto">
            <input className="form-input mb-2 h-full w-[25vw] min-w-[300px] rounded border border-[#C3C3C3] bg-white px-7 text-[13px] placeholder-[#7D7D7D] focus:outline-none md:mb-0" type="search" name="search" id="search" placeholder="Search" />
            <img className="absolute left-[10px] top-[50%] w-[12px] translate-y-[-50%]" src={searchIcon} alt="Search icon" />
          </div>
        </div>
      </div>
      <div className="overflow-hidden text-gray-900">
        <main className="overflow-hidden">
          <div className="w-full">
            <AppTable columns={columns} data={allOrderData} loading={loading} pageCount={pageCount} pageSize={pageSize} setPageSize={setPageSize} setPage={setPage} />
          </div>
        </main>
      </div>
    </>
  );
};

export default SalesOrderTable;
