/* eslint-disable jsx-a11y/label-has-associated-control */
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import FormikControl from '@/components/formik/formikControl';
import plusIcon from '@/assets/images/icons/plus-icon.svg';
import HiddenElement from '@/components/formik/hiddenElement';
import Spinner from '@/components/Spinner';
import { createSupplier } from '@/redux/features/settings/inboundSettings/inboundLogisticsSlice';

const LogisticsForm = ({ fetchData, initialPageSize }) => {
  const dispatch = useDispatch();
  const { createSupplierLoading } = useSelector((state) => state.inboundLogistics);

  const initialValues = {
    name: '',
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
  });

  const onSubmit = (values, submitProps) => {
    dispatch(createSupplier({ ...values }))
      .unwrap()
      .then(() => {
        fetchData({ pageSize: initialPageSize, pageIndex: 0 });
      });
    submitProps.resetForm();
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {(formik) => {
        return (
          <Form className="relative rounded-t-lg bg-white px-8 pb-7 pt-8">
            {createSupplierLoading && <Spinner />}
            <div className="flex items-end gap-7">
              <div className="form-group">
                <FormikControl control="input" type="text" label="Create Supplier" name="name" placeholder="Create Supplier" />
              </div>
              <div>
                <label htmlFor="submit-btn" className="form-control-label pointer-events-none hidden">
                  Button
                </label>
                <button className="submit-button" type="submit" disabled={!formik.isValid || formik.isSubmitting}>
                  <span className="mr-[10px]">
                    <img src={plusIcon} alt="plus icon" />
                  </span>
                  Add Supplier
                </button>
                <HiddenElement />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default LogisticsForm;
