import AddCategories from './addCategories/addCategories';
import AddUnits from './addUnits/addUnits';
import AddItems from './addItems/addItems';
import AddActivity from './addActivity/addActivity';
import Logistics from './logistics/logistics';
import ImageType from '../imageType/imageType';

const OutboundSettingsHome = () => {
  return (
    <>
      <AddUnits />
      <AddCategories />
      <AddItems />
      <AddActivity />
      <Logistics />
      <ImageType />
    </>
  );
};

export default OutboundSettingsHome;
