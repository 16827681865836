import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import instance, { adminBaseUrl } from '@/services/authInstance';
import * as authService from '@/services/authService';
import history from '@/customRoutes/history';

export const validateOrg = createAsyncThunk('user/validateOrg', async (orgKey, { rejectWithValue }) => {
  const columnWhiteBlacklist = '"_id slug prefix_initial name  email created_at"';
  try {
    const { data } = await axios.get(`${adminBaseUrl}organization/verifyOrganizationKey?p_key=${orgKey}&columnWhiteBlacklist=${columnWhiteBlacklist}`);
    localStorage.setItem('customerId', data.data.name);
    return data.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const loginUser = createAsyncThunk('user/loginUser', async (loginDetails, { rejectWithValue }) => {
  try {
    const { data } = await instance.post('user/admin/login', loginDetails);
    authService.setToken(data.data.sessionAuth.accessToken);
    localStorage.setItem('user', JSON.stringify(data.data));
    return data.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const createUser = createAsyncThunk('user/createUser', async (userDetails, { rejectWithValue }) => {
  try {
    const { data } = await instance.post('user/createAdmin', userDetails);
    return data.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const getAllUsers = createAsyncThunk('user/getAllUsers', async (queryParams) => {
  const { data } = await instance.get(`user/getAll${queryParams}`);
  return data;
});

export const getUserRoles = createAsyncThunk('user/getUserRoles', async () => {
  const { data } = await instance.get(`user/role/getAll`);
  return data;
});

export const getUser = createAsyncThunk('user/getUser', async (id) => {
  const { data } = await instance.get(`user/getOne?_id=${id}`);
  return data;
});

export const editUser = createAsyncThunk('user/editUser', async (payload) => {
  const { data } = await instance.patch(`user/update`, payload);
  return data;
});

export const viewPassword = createAsyncThunk('user/viewPassword', async (id) => {
  const { data } = await instance.get(`user/viewPassword?_id=${id}`);
  return data;
});

const initialState = {
  user: null,
  allUsers: [],
  userRoles: [],
  validateOrgLoading: false,
  loginUserLoading: false,
  createUserLoading: false,
  getAllUsersLoading: false,
  getUserRolesLoading: false,
  getUserLoading: false,
  viewPasswordLoading: false,
  editUserLoading: false,
  error: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
      authService.signOut();
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(validateOrg.pending, (state) => {
        state.validateOrgLoading = true;
        state.error = '';
      })
      .addCase(validateOrg.fulfilled, (state) => {
        state.validateOrgLoading = false;
        state.error = '';
        history.push('/login');
      })
      .addCase(validateOrg.rejected, (state, action) => {
        state.error = action?.payload?.message || 'An error occurred';
        state.validateOrgLoading = false;
        toast.error(action?.payload?.message || 'An error occurred');
      })
      .addCase(loginUser.pending, (state) => {
        state.loginUserLoading = true;
        state.error = '';
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loginUserLoading = false;
        state.error = '';
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.error = action.payload.message || 'An error occurred';
        state.loginUserLoading = false;
        toast.error(action.payload.message || 'An error occurred');
      })
      .addCase(createUser.pending, (state) => {
        state.createUserLoading = true;
        state.error = '';
      })
      .addCase(createUser.fulfilled, (state) => {
        toast.success('User created');
        state.createUserLoading = false;
        state.error = '';
        history.push('/admin');
      })
      .addCase(createUser.rejected, (state, action) => {
        state.error = action.payload.message || 'An error occurred';
        state.createUserLoading = false;
        toast.error(action.payload.message || 'An error occurred');
      })
      .addCase(getAllUsers.pending, (state) => {
        state.getAllUsersLoading = true;
        state.error = '';
      })
      .addCase(getAllUsers.fulfilled, (state, { payload: { data } }) => {
        state.allUsers = data.data;
        state.getAllUsersLoading = false;
        state.error = '';
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.error = action.payload.message || 'An error occurred';
        state.getAllUsersLoading = false;
        toast.error(action.payload.message || 'An error occurred');
      })
      .addCase(getUserRoles.pending, (state) => {
        state.getUserRolesLoading = true;
        state.error = '';
      })
      .addCase(getUserRoles.fulfilled, (state, { payload: { data } }) => {
        state.userRoles = data;
        state.getUserRolesLoading = false;
        state.error = '';
      })
      .addCase(getUserRoles.rejected, (state, action) => {
        state.error = action.payload.message || 'An error occurred';
        state.getUserRolesLoading = false;
        toast.error(action.payload.message || 'An error occurred');
      })
      .addCase(editUser.pending, (state) => {
        state.editUserLoading = true;
        state.error = '';
      })
      .addCase(editUser.fulfilled, (state) => {
        toast.success('User updated');
        state.editUserLoading = false;
        state.error = '';
        history.push('/admin');
      })
      .addCase(editUser.rejected, (state, action) => {
        state.error = action.payload.message || 'An error occurred';
        state.editUserLoading = false;
        toast.error(action.payload.message || 'An error occurred');
      })
      .addCase(viewPassword.pending, (state) => {
        state.viewPasswordLoading = true;
        state.error = '';
      })
      .addCase(viewPassword.fulfilled, (state) => {
        state.viewPasswordLoading = false;
        state.error = '';
      })
      .addCase(viewPassword.rejected, (state, action) => {
        state.error = action.payload.message || 'An error occurred';
        state.viewPasswordLoading = false;
      });
  },
});

export const { logout } = userSlice.actions;

export default userSlice.reducer;
