import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Table, { FormatDate, ToggleSwitch } from '@/components/table/appTable';

const AddStoreTable = ({ fetchData, pageCount, setInitialPageSize, initialPageSize }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Store Name',
        accessor: 'store.name',
      },
      {
        Header: 'Store ID',
        accessor: 'store.id',
      },
      {
        Header: 'State',
        accessor: 'store.state',
      },
      {
        Header: 'LGA',
        accessor: 'store.lga',
      },
      {
        Header: 'Date Created',
        accessor: 'created_at',
        Cell: FormatDate,
      },
      {
        Header: 'Status',
        accessor: 'active',
        Cell: ToggleSwitch,
      },
    ],
    [],
  );

  const { warehouses, warehousesLoading } = useSelector((state) => state.warehouse);

  return (
    <div className="overflow-hidden text-gray-900">
      <main className="overflow-hidden">
        <div className="w-full">
          <Table columns={columns} data={warehouses} fetchData={fetchData} loading={warehousesLoading} pageCount={pageCount} pageSize={initialPageSize} setInitialPageSize={setInitialPageSize} />
        </div>
      </main>
    </div>
  );
};

export default AddStoreTable;
