/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unused-vars */
import { useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import format from 'date-fns/format';
import Table, { ActionsHeader, Ellipsis } from '../../components/table/appTable';
import searchIcon from '../../assets/images/icons/search-icon.svg';
import filterIcon from '../../assets/images/icons/filter-icon.svg';
import { getData } from './storeData';

const StoreTable = ({ fetchData, pageCount }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Date & Time',
        accessor: 'created_at',
        Cell: ({ value }) => (
          <div className="flex w-fit flex-col gap-1">
            <span>{format(new Date(value), 'dd MMM, yyyy')}</span>
            <span className="text-right text-xs text-[#7D7D7D]">{format(new Date(value), 'hh:mm aa')}</span>
          </div>
        ),
      },
      {
        Header: 'Category',
        accessor: (row) => (row.boundType === 'outbound' ? row?.outbound?.category?.name : row?.inbound?.category?.name),
        Cell: ({ value }) => (value ? <span>{value}</span> : <span>-</span>),
      },
      {
        Header: 'Type',
        accessor: 'itemType.name',
        Cell: ({ value }) => (value ? <span>{value}</span> : <span>-</span>),
      },
      {
        Header: 'Amount',
        accessor: (row) => (row.boundType === 'outbound' ? row?.outbound?.cost : row?.inbound?.cost),
        Cell: ({ value }) => {
          return value ? <span className="font-bold">&#8358;{value}</span> : <span>-</span>;
        },
      },
      {
        Header: 'Units',
        accessor: (row) => (row.boundType === 'outbound' ? row?.outbound?.totalBags : row?.inbound?.totalBags),
        Cell: ({ row }) => {
          return row.original.boundType === 'inbound' ? <span className="text-green">{+row.value || '-'}</span> : <span className="text-[#9F2D2D]">{-row.value || '-'}</span>;
        },
      },
    ],
    [],
  );

  const { storeData, getStoreDataLoading } = useSelector((state) => state.warehouse);

  return (
    <>
      <div className="mb-7 mt-10 flex flex-col justify-between lg:flex-row ">
        <h3 className="mb-2 text-2xl font-semibold lg:mb-0">Activity Summary</h3>
        <div className="flex flex-col gap-3 md:flex-row md:items-center md:gap-4">
          <div className="relative h-[32px] overflow-auto">
            <input className="form-input mb-2 h-full w-[25vw] min-w-[300px] rounded border border-[#C3C3C3] bg-white px-7 text-[13px] placeholder-[#7D7D7D] focus:outline-none md:mb-0" type="search" name="search" id="search" placeholder="Search" />
            <img className="absolute left-[10px] top-[50%] w-[12px] translate-y-[-50%]" src={searchIcon} alt="Search icon" />
          </div>
          <button type="button" className="h-[35px] w-[90px] rounded-md bg-[#64B548] text-[13px] text-white">
            <span className="flex justify-center gap-[10px]">
              <img className="w-4" src={filterIcon} alt="Filter icon" />
              <span>Filter</span>
            </span>
          </button>
        </div>
      </div>
      <div className="overflow-hidden text-gray-900">
        <main className="overflow-hidden">
          <div className="w-full">
            <Table columns={columns} data={storeData} fetchData={fetchData} loading={getStoreDataLoading} pageCount={pageCount} pageSize={5} />
          </div>
        </main>
      </div>
    </>
  );
};

export default StoreTable;
