import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { motion, AnimatePresence } from 'framer-motion';
import _nav from '../_nav';
import '../scss/_nav.scss';
import logo from '../assets/images/app-logo.png';
import collapseIcon from '../assets/images/icons/collapse-button.svg';
import chevronDown from '../assets/images/icons/chevron-down.svg';
import chevronUp from '../assets/images/icons/chevron-up.svg';
import Backdrop from './backdrop';

const AppSidebar = ({ showSidebar, setShowSidebar, collapseSidebar, setCollapseSidebar }) => {
  const [width, setWidth] = useState(window.innerWidth);

  const breakpoint = 768;

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);

    if (width < breakpoint) {
      setShowSidebar(false);
    } else {
      setShowSidebar(true);
    }

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [width, setShowSidebar]);

  const navLinkStyle = `flex items-center text-white my-1 mx-6 py-2 transition-all border border-transparent`;

  const renderNavLink = (navItem) => {
    return (
      <NavLink key={navItem.name} to={navItem.to} className={({ isActive }) => navLinkStyle + (isActive ? ' active' : '')} data-tip data-for={navItem.name}>
        <div className={`flex w-full items-center justify-center ${collapseSidebar && width >= breakpoint ? 'px-0' : 'px-2'}`}>
          <span className="icon w-4">{navItem.icon}</span>
          <span className="active-icon w-4">{navItem.activeIcon}</span>
          {(!collapseSidebar || width < breakpoint) && (
            <>
              <span className="ml-4 text-[#A6A6A6]">{navItem.name}</span>
              <span className={`chevron ml-auto mr-2 ${/overview/i.test(navItem.name) ? ' opacity-0' : ''}`}>
                <img src={chevronDown} alt="chevron down" />
              </span>
              <span className={`active-chevron ml-auto mr-2 ${/overview/i.test(navItem.name) ? ' opacity-0' : ''}`}>
                <img src={chevronUp} alt="chevron up" />
              </span>
            </>
          )}
        </div>
        {collapseSidebar && width >= breakpoint ? (
          <ReactTooltip id={navItem.name}>
            <span>{navItem.name}</span>
          </ReactTooltip>
        ) : null}
      </NavLink>
    );
  };

  const sidebar = (
    <div className={`h-full min-h-screen bg-white ${collapseSidebar && width >= breakpoint ? 'w-[80px]' : 'w-[240px]'} fixed z-10 flex flex-col transition-[width]`}>
      <div className="relative mx-6 mb-16 mt-6 flex justify-center text-[25px] font-bold text-white">
        {collapseSidebar && width >= breakpoint ? (
          <div className="flex h-[50px] items-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="black" strokeWidth={2} onClick={() => setCollapseSidebar(false)}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </div>
        ) : (
          <div className="flex h-[50px] items-center justify-between">
            <div className="w-[68%]">
              <img src={logo} alt="logo" />
            </div>
            <button type="button" onClick={() => setCollapseSidebar(true)}>
              <img className="cursor-pointer" src={collapseIcon} alt="collapse button" />
            </button>
          </div>
        )}
      </div>
      <nav className="mb-14 flex flex-1 flex-col justify-between text-[14px]">
        <div className="flex flex-col gap-[2px]">
          {_nav
            .slice(0, -1)
            .filter((item) => !Object.prototype.hasOwnProperty.call(item, 'hidden'))
            .map((navItem) => {
              return renderNavLink(navItem);
            })}
        </div>
        <div>{renderNavLink(_nav[_nav.length - 1])}</div>
      </nav>
    </div>
  );

  return (
    <AnimatePresence>
      {showSidebar &&
        (width < breakpoint ? (
          <Backdrop show={showSidebar} setShow={setShowSidebar}>
            <motion.div
              initial={{ x: '-100vw', opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ type: 'tween', duration: 0.3 }}
              exit={{
                x: '-100vw',
                opacity: 0,
                transition: { ease: 'easeInOut', duration: 0.3 },
              }}
            >
              {sidebar}
            </motion.div>
          </Backdrop>
        ) : (
          sidebar
        ))}
    </AnimatePresence>
  );
};

export default AppSidebar;
