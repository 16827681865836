/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import _ from 'lodash';
import FormikControl from '@/components/formik/formikControl';
import Spinner from '@/components/Spinner';
import FormTemplate from '@/components/templates/formTemplate';
import { editInboundItem, getInboundItem } from '../../redux/features/inbound/inboundSlice';
import { getAllSuppliers } from '@/redux/features/settings/inboundSettings/inboundLogisticsSlice';
import { getAllUnits } from '@/redux/features/settings/inboundSettings/inboundUnitsSlice';
import { getAllWarehouses } from '@/redux/features/warehouse/warehouseSlice';
import { getAllItems } from '@/redux/features/settings/inboundSettings/inboundItemSlice';
import { getAllCategories } from '@/redux/features/settings/inboundSettings/inboundCategorySlice';

const EditInboundItem = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { itemLoading } = useSelector((state) => state.inbound);
  const { allWarehousesLoading } = useSelector((state) => state.warehouse);
  const { getUnitsLoading } = useSelector((state) => state.inboundUnits);
  const { getAllItemsLoading } = useSelector((state) => state.inboundItem);
  const { getAllSuppliersLoading } = useSelector((state) => state.inboundLogistics);
  const { getAllCategoriesLoading } = useSelector((state) => state.inboundCategory);
  const [initialValues, setInitialValues] = useState({
    supplierName: '',
    warehouse: '',
    quantity: 0,
    unitOfMeasurement: '',
    boundItem: '',
    category: '',
    driverName: '',
    phoneNo: '',
    plateNo: '',
    cost: 0,
    comment: '',
    weight: 0,
  });
  const [warehouseOptions, setWarehouseOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [itemOptions, setItemOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);

  const validationSchema = Yup.object({
    supplierName: Yup.string().required('Required'),
    warehouse: Yup.string().required('Required'),
    quantity: Yup.number('Not a number').required('Required').integer('Must be an integer'),
    unitOfMeasurement: Yup.string().required('Required'),
    boundItem: Yup.string().required('Required'),
    category: Yup.string().required('Required'),
    driverName: Yup.string().required('Required'),
    phoneNo: Yup.string().required('Required'),
    plateNo: Yup.string().required('Required'),
    cost: Yup.number('Not a number').required('Required'),
    comment: Yup.string().required('Required'),
    weight: Yup.number('Not a number').required('Required'),
  });

  const onSubmit = (values) => {
    dispatch(editInboundItem({ id: params.id, ...values }))
      .unwrap()
      .then(() => {
        navigate('/inbound');
      });
  };

  useEffect(() => {
    // Get warehouses
    dispatch(getAllWarehouses())
      .unwrap()
      .then((result) => {
        const options = [];
        result.data.data.forEach((value) => {
          options.push({
            label: value.store.name,
            value: value._id,
          });
        });
        setWarehouseOptions([{ label: 'Select Warehouse', value: '', isDisabled: true }, ...options]);
      });
    // Get Units
    dispatch(getAllUnits())
      .unwrap()
      .then((result) => {
        // console.log('Units', result.data.data)
        const options = [];
        result.data.data.forEach((value) => {
          options.push({
            label: value.name,
            value: value._id,
          });
        });
        setUnitOptions([{ label: 'Select Unit', value: '', isDisabled: true }, ...options]);
      });
    // Get Suppliers
    dispatch(getAllSuppliers())
      .unwrap()
      .then((result) => {
        const options = [];
        result.data.data.forEach((value) => {
          options.push({
            label: value.name,
            value: value._id,
          });
        });
        setSupplierOptions([{ label: 'Select Supplier', value: '', isDisabled: true }, ...options]);
      });
    // Get Items
    dispatch(getAllItems())
      .unwrap()
      .then((result) => {
        const options = [];
        result.data.data.forEach((value) => {
          options.push({
            label: value.name,
            value: value._id,
          });
        });
        setItemOptions([{ label: 'Select Item', value: '', isDisabled: true }, ...options]);
      });
    // Get Categories
    dispatch(getAllCategories())
      .unwrap()
      .then((result) => {
        const options = [];
        result.data.data.forEach((value) => {
          options.push({
            label: value.name,
            value: value._id,
          });
        });
        setCategoryOptions([{ label: 'Select Categories', value: '', isDisabled: true }, ...options]);
      });
    dispatch(getInboundItem(params.id))
      .unwrap()
      .then(({ data }) => {
        // console.log('Data', data)
        setInitialValues({
          supplierName: data?.supplierName?._id,
          warehouse: data?.warehouse?._id,
          quantity: data?.quantity,
          unitOfMeasurement: data?.unitOfMeasurement?._id,
          boundItem: data?.boundItem?._id,
          category: data?.category?._id,
          driverName: data?.driverName,
          phoneNo: data?.phoneNo,
          plateNo: data?.plateNo,
          cost: data?.cost,
          comment: data?.comment,
          weight: data?.weight,
        });
      });
  }, []);

  const loading = itemLoading || allWarehousesLoading || getUnitsLoading || getAllSuppliersLoading || getAllItemsLoading;

  return (
    <FormTemplate initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} title="Edit Activity" submitBtnText="Save Changes" enableReinitialize>
      {loading && <Spinner />}
      <div>
        <FormikControl control="select" label="Supplier" name="supplierName" options={supplierOptions} />
      </div>
      <div>
        <FormikControl control="select" label="Warehouse" name="warehouse" options={warehouseOptions} />
      </div>
      <div>
        <FormikControl control="input" type="number" label="QTY" name="quantity" placeholder="Quantity" />
      </div>
      <div>
        <FormikControl control="select" label="Unit" name="unitOfMeasurement" options={unitOptions} />
      </div>
      <div>
        <FormikControl control="select" label="Item" name="boundItem" options={itemOptions} />
      </div>
      <div>
        <FormikControl control="select" label="Category" name="category" options={categoryOptions} />
      </div>
      <div>
        <FormikControl control="input" type="number" label="Weight" name="weight" placeholder="0" />
      </div>
      <div>
        <FormikControl control="input" type="number" label="Cost" name="cost" placeholder="N56,000" />
      </div>
      <div>
        <FormikControl control="input" type="text" label="Phone Number" name="phoneNo" placeholder="Phone Number" />
      </div>
      <div>
        <FormikControl control="input" type="text" label="Driver" name="driverName" placeholder="Driver" />
      </div>
      <div>
        <FormikControl control="input" type="text" label="Plate Number" name="plateNo" placeholder="Plate Number" />
      </div>
      <div>
        <FormikControl control="textarea" label="Comment" name="comment" placeholder="The package has to be delivered within 72 hours" />
      </div>
    </FormTemplate>
  );
};

export default EditInboundItem;
