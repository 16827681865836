/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import Spinner from '@/components/Spinner';
import { getOutboundPercentages } from '@/redux/features/outbound/outboundSlice';

// const data = [
//   { name: 'Group A', value: 400 },
//   { name: 'Group B', value: 300 },
//   { name: 'Group C', value: 300 },
// ]

const COLORS = ['#4ECCC6', '#9AADBD', '#c4a421', '#0a1c45'];

const renderActiveShape = (props) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;

  return (
    <g>
      {/* <text x={cx - 40} y={cy - 12} dy={8} textAnchor="middle" fill="#000">
        &#8358;
      </text>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill="#000" fontSize="30" fontWeight="bold">
        2,43
      </text>
      <text x={cx + 45} y={cy + 8} dy={8} textAnchor="middle" fill="#000">
        Bn
      </text> */}
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill="#000" fontSize="20" fontWeight="semi-bold">
        Activities
      </text>
      <Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={fill} />
    </g>
  );
};

const OutboundPie = () => {
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(0);
  const { getOutboundPercentagesLoading } = useSelector((state) => state.outbound);
  const [data_, setData_] = useState([
    { activity: '', percent: 12.5 },
    { activity: '', percent: 12.5 },
    { activity: '', percent: 75 },
  ]);

  useEffect(() => {
    dispatch(getOutboundPercentages())
      .unwrap()
      .then((res) => {
        const percent = res?.data?.map((p) => ({
          percent: p[Object.keys(p)[0]],
          activity: Object.keys(p)[0],
          color: 'green',
        }));
        setData_(percent);
      });
  }, []);

  return (
    <div className="flex flex-col rounded-lg bg-white py-4">
      {getOutboundPercentagesLoading && <Spinner />}
      <ResponsiveContainer width="100%" height="65%" minHeight={250}>
        <PieChart width={800} height={400}>
          <Pie
            data={data_}
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            //   cx={120}
            //   cy={200}
            innerRadius={80}
            outerRadius={100}
            fill="#8884d8"
            paddingAngle={5}
            dataKey="percent"
          >
            {data_.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <div className="flex max-h-24 grow flex-col items-center justify-between overflow-auto px-5 py-2">
        {data_.map((p, index) => {
          const color = COLORS[index % COLORS.length];
          return (
            <div key={index} className="flex w-full items-center justify-between">
              <div className="flex items-center gap-2">
                <div style={{ background: `${color}` }} className="h-4 w-4 rounded" />
                <span className="text-xs text-[#2B2B2B]">{p.activity?.charAt(0).toUpperCase() + p.activity?.slice(1)}</span>
              </div>
              <span className="text-sm font-bold text-[#222222]">{p.percent?.toFixed(1) + '%'}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OutboundPie;
