import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '@/services/instance';
import { pendingHandler, rejectionHandler, successHandler } from '@/shared/requestHandlers';

export const addRegion = createAsyncThunk('regionSettings/addRegion', async (postData) => {
  const { data } = await instance.post('/regions', postData);
  return data;
});

export const editRegion = createAsyncThunk('regionSettings/editRegion', async (patchData) => {
  const { data } = await instance.patch('/regions', patchData);
  return data;
});

export const getRegion = createAsyncThunk('regionSettings/getRegion', async (id) => {
  const { data } = await instance.get(`/regions/getOne?_id=${id}`);
  return data;
});

export const deleteRegion = createAsyncThunk('regionSettings/deleteRegion', async (id) => {
  const { data } = await instance.delete(`/regions?_id=${id}`);
  return data;
});

export const getRegions = createAsyncThunk('regionSettings/getRegions', async (queryParams) => {
  const { data } = await instance.get(`/regions${queryParams}`);
  return data;
});

export const getAllRegions = createAsyncThunk('regionSettings/getAllRegions', async () => {
  const { data } = await instance.get('/regions');
  return data;
});

const initialState = {
  regions: [],
  allRegions: [],
  addRegionLoading: false,
  editRegionLoading: false,
  getRegionsLoading: false,
  getAllRegionsLoading: false,
  deleteRegionLoading: false,
  error: '',
};

const regionSlice = createSlice({
  name: 'regionSettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addRegion.pending, (state) => {
        pendingHandler(state, 'addRegionLoading');
      })
      .addCase(addRegion.fulfilled, (state) => {
        // state.regions.push(data)
        successHandler(state, 'addRegionLoading', 'Region added');
      })
      .addCase(addRegion.rejected, (state, action) => {
        rejectionHandler(state, action, 'addRegionLoading');
      })
      .addCase(getRegion.pending, (state) => {
        pendingHandler(state, 'getItemLoading');
      })
      .addCase(getRegion.fulfilled, (state) => {
        successHandler(state, 'getItemLoading');
      })
      .addCase(getRegion.rejected, (state, action) => {
        rejectionHandler(state, action, 'getItemLoading');
      })
      .addCase(editRegion.pending, (state) => {
        pendingHandler(state, 'editRegionLoading');
      })
      .addCase(editRegion.fulfilled, (state) => {
        successHandler(state, 'editRegionLoading', 'Region updated');
      })
      .addCase(editRegion.rejected, (state, action) => {
        rejectionHandler(state, action, 'editRegionLoading');
      })
      .addCase(deleteRegion.pending, (state) => {
        pendingHandler(state, 'deleteRegionLoading');
      })
      .addCase(deleteRegion.fulfilled, (state) => {
        successHandler(state, 'deleteRegionLoading', 'Region deleted');
      })
      .addCase(deleteRegion.rejected, (state, action) => {
        rejectionHandler(state, action, 'deleteRegionLoading');
      })
      .addCase(getRegions.pending, (state) => {
        pendingHandler(state, 'getRegionsLoading');
      })
      .addCase(getRegions.fulfilled, (state, { payload: { data } }) => {
        state.regions = data.data;
        successHandler(state, 'getRegionsLoading');
      })
      .addCase(getRegions.rejected, (state, action) => {
        rejectionHandler(state, action, 'getRegionsLoading');
      })
      .addCase(getAllRegions.pending, (state) => {
        pendingHandler(state, 'getAllRegionsLoading');
      })
      .addCase(getAllRegions.fulfilled, (state, { payload: { data } }) => {
        state.allRegions = data.data;
        successHandler(state, 'getAllRegionsLoading');
      })
      .addCase(getAllRegions.rejected, (state, action) => {
        rejectionHandler(state, action, 'getAllRegionsLoading');
      });
  },
});

export default regionSlice.reducer;
