/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Table from '@/components/table/appTable';
import searchIcon from '@/assets/images/icons/search-icon.svg';
import filterIcon from '@/assets/images/icons/filter-icon.svg';
import useRequestTableColumns from './requestsTableColumns';

const RequestsTable = ({ fetchData, pageCount, tableHeading, onSearch }) => {
  const { requests, loading } = useSelector((state) => state.procurement);
  const [searchTerm, setSearchTerm] = useState('');
  const columns = useRequestTableColumns();

  const handleChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
    onSearch(value);
  };

  return (
    <>
      <div className="mb-7 mt-12 flex flex-col justify-between lg:flex-row ">
        <h3 className="mb-2 text-2xl font-semibold lg:mb-0">{tableHeading}</h3>
        <div className="flex flex-col gap-3 md:flex-row md:items-center md:gap-4">
          <div className="relative h-[32px] overflow-auto">
            <input
              //   onChange={handleChange}
              className="form-input mb-2 h-full w-[25vw] min-w-[300px] rounded border border-[#C3C3C3] bg-white px-7 text-[13px] placeholder-[#7D7D7D] focus:outline-none md:mb-0"
              type="search"
              name="search"
              id="search"
              // value={searchTerm}
              placeholder="Search"
            />
            <img className="absolute left-[10px] top-[50%] w-[12px] translate-y-[-50%]" src={searchIcon} alt="Search icon" />
          </div>
          <button type="button" className="h-[35px] w-[90px] rounded-md bg-[#64B548] text-[13px] text-white">
            <span className="flex justify-center gap-[10px]">
              <img className="w-4" src={filterIcon} alt="Filter icon" />
              <span>Filter</span>
            </span>
          </button>
        </div>
      </div>
      <div className="overflow-hidden text-gray-900">
        <main className="overflow-hidden">
          <div className="w-full">
            <Table columns={columns} data={requests} fetchData={fetchData} loading={loading} pageCount={pageCount} />
          </div>
        </main>
      </div>
    </>
  );
};

export default RequestsTable;
