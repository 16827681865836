import { useNavigate } from 'react-router-dom';
import ActionStyle from './actionStyle';

const EllipsisPopop = ({ id, onDelete }) => {
  const navigate = useNavigate();

  const handleDelete = () => {
    // setShowPopop(false)
    const response = window.confirm('Confirm delete?');

    if (response) {
      onDelete();
    }
  };

  return (
    <>
      <ActionStyle onClick={() => navigate(id)}>View</ActionStyle>
      <ActionStyle onClick={() => navigate(`edit/${id}`)}>Edit</ActionStyle>
      <ActionStyle onClick={handleDelete}>Delete</ActionStyle>
    </>
  );
};

export default EllipsisPopop;
