import { useSelector } from 'react-redux';

export const roleMapper = {
  RM: 'RM',
  rm: 'RM',
  'Head of Ops': 'Head of Ops',
  headOfOps: 'Head of Ops',
  CFO: 'CFO',
  cfo: 'CFO',
  'Account Manager': 'Account Manager',
  accountManager: 'Account Manager',
};

const usePermissions = () => {
  const permissions = useSelector((state) => state.user.user.role);

  return {
    WRITEREQUEST: permissions.scope.includes('request.write'),
    READREQUEST: permissions.scope.includes('request.read'),
    role: permissions.name,
  };
};

export default usePermissions;

// eslint-disable-next-line no-unused-vars
const reqObj = [
  {
    user: '64583cdadcaac60014b4ef18',
    event: 'approved',
    _id: '648efff57f5e1200145b8153',
    roleId: '644098e51cb72899ef951392',
    role: 'RM',
    comment: 'The request looks good. I am approving now.',
    invoice: 'invoice image url here',
  },
  {
    user: '64583cdadcaac60014b4ef18',
    event: 'approved',
    _id: '648efff57f5e1200145b8154',
    roleId: '6440992a1cb72899ef951395',
    role: 'Head of Ops',
    comment: 'The request looks good. I am approving now.',
    invoice: 'invoice image url here',
  },
  {
    user: '64583cdadcaac60014b4ef18',
    event: 'approved',
    _id: '648efff57f5e1200145b8155',
    roleId: '644099601cb72899ef951398',
    role: 'CFO',
    comment: 'The request looks good. I am approving now.',
    invoice: 'invoice image url here',
  },
  {
    user: '64583cdadcaac60014b4ef18',
    event: 'rejected',
    _id: '648efff57f5e1200145b8156',
    roleId: '6440997e1cb72899ef95139a',
    role: 'Account Manager',
    comment: 'The request looks good. I am approving now.',
    invoice: 'invoice image url here',
  },
];
