const CartIcon = () => {
  return (
    <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4.5" y="4" width="48" height="48" rx="24" fill="#D4F7EA" />
      <rect x="4.5" y="4" width="48" height="48" rx="24" stroke="#E9FBF4" strokeWidth="8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.5 31H28.08L27.585 29.5H36C36.585 29.5 37.08 29.155 37.335 28.66H37.35L40.35 22.66H40.335C40.425 22.465 40.5 22.24 40.5 22C40.5 21.175 39.825 20.5 39 20.5H24.585L23.925 18.52H23.91C23.7 17.935 23.16 17.5 22.5 17.5H18C17.175 17.5 16.5 18.175 16.5 19C16.5 19.825 17.175 20.5 18 20.5H21.42L24.915 31H22.5C20.85 31 19.5 32.35 19.5 34C19.5 35.65 20.85 37 22.5 37C24.15 37 25.5 35.65 25.5 34H34.5C34.5 35.65 35.85 37 37.5 37C39.15 37 40.5 35.65 40.5 34C40.5 32.35 39.15 31 37.5 31ZM25.575 23.5H36.57L35.07 26.5H26.58L25.575 23.5Z"
        fill="#22B97F"
      />
    </svg>
  );
};

export default CartIcon;
