/* eslint-disable no-unused-vars */
import { useMemo } from 'react';
import {
  ActionsHeader,
  Ellipsis,
  RequestStatusPill,
  // RequestTableActions,
} from '@/components/table/appTable';
import { getRowLabel } from '../../shared/roles';

const statuses = ['pending', 'rejected', 'toBePaid', 'completed'];

export default function useRequestHistoryTableColumns() {
  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'boundItem.name',
        Cell: ({ value }) => <span>1324452</span>,
      },
      {
        Header: 'Description',
        accessor: 'unitOfMeasurement.name',
        Cell: ({ value }) => <span>Dry Season gain</span>,
      },
      {
        Header: 'Item',
        accessor: 'category.name',
        Cell: ({ value }) => <span>Maize</span>,
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
        Cell: ({ value }) => <span>150</span>,
      },
      {
        Header: 'Unit',
        accessor: 'supplierName.name',
        Cell: ({ value }) => <span>Kg</span>,
      },
      {
        Header: 'Amount',
        accessor: 'warehouse.store.name',
        Cell: ({ value }) => <span>N56,000</span>,
      },
      {
        Header: 'Comment',
        accessor: 'driverName',
        Cell: ({ value }) => <span className="inline-block max-w-[150px] truncate align-middle">Price is expensive for the amount</span>,
      },
      {
        Header: 'Reviewer',
        accessor: 'phoneNo',
        Cell: ({ value }) => <span>Ademola Adedejo</span>,
      },
      {
        Header: 'Status',
        accessor: 'plateNo',
        Cell: ({ value }) => <RequestStatusPill value={statuses[Math.floor(Math.random() * 4)]} />,
      },
      {
        Header: <ActionsHeader show />,
        accessor: '_id',
        Cell: ({ value }) => <Ellipsis value={value} disabled />,
      },
    ],
    [],
  );

  return columns;
}
