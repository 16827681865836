/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
// build your query
export function buildQueryParams(userQuery) {
  // store query parameters in a temporary variable
  const query = [];
  // loop through user query object
  for (const key in userQuery) {
    // encode the keys and values this is most necessary for search inputs
    query.push(`${encodeURIComponent(key)}=${encodeURIComponent(userQuery[key])}`);
  }
  // construct new URL
  const newParams = query.length ? `?${query.join('&')}` : '';
  return newParams;
}
