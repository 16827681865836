import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormikControl from '@/components/formik/formikControl';
import plusIcon from '@/assets/images/icons/plus-icon.svg';
import HiddenElement from '@/components/formik/hiddenElement';
import { addStore } from '@/redux/features/warehouse/warehouseSlice';
import Spinner from '@/components/Spinner';
import { getAllRegions } from '@/redux/features/settings/regionsAndZonesSettings/regionSlice';

const AddStoreForm = ({ fetchData, initialPageSize }) => {
  const dispatch = useDispatch();
  const { addStoreLoading } = useSelector((state) => state.warehouse);
  const [regionOptions, setRegionOptions] = useState([]);

  const initialValues = {
    name: '',
    id: '',
    address: '',
    state: '',
    lga: '',
    region: '',
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    id: Yup.string().required('Required'),
    address: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    lga: Yup.string().required('Required'),
    region: Yup.string().required('Required'),
  });

  const onSubmit = (values, submitProps) => {
    dispatch(addStore({ store: { ...values } }))
      .unwrap()
      .then(() => {
        fetchData({ pageSize: initialPageSize, pageIndex: 0 });
      });
    submitProps.resetForm();
  };

  useEffect(() => {
    dispatch(getAllRegions())
      .unwrap()
      .then((result) => {
        const options = [];
        result.data.data.forEach((value) => {
          options.push({
            label: value.label,
            value: value._id,
          });
        });
        setRegionOptions([{ label: 'Select Regions', value: '-', isDisabled: true }, ...options]);
      });
  }, []);

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {(formik) => {
        return (
          <Form className="rounded-t-lg bg-white px-8 pb-7 pt-8">
            {addStoreLoading && <Spinner />}
            <div className="flex flex-wrap items-end gap-7">
              <div className="form-group">
                <FormikControl control="input" type="text" label="Store Name" name="name" placeholder="Store Name" />
              </div>
              <div className="form-group">
                <FormikControl control="input" type="text" label="Store ID" name="id" placeholder="Store ID" />
              </div>
              <div className="form-group">
                <FormikControl control="input" type="text" label="Address" name="address" placeholder="Address" />
              </div>
              <div className="form-group">
                <FormikControl control="input" type="text" label="Store State" name="state" placeholder="Store State" />
              </div>
              <div className="form-group">
                <FormikControl control="input" type="text" label="Store LGA" name="lga" placeholder="Store LGA" />
              </div>
              <div className="w-[290px]">
                <FormikControl control="select" label="Region" name="region" options={regionOptions} />
              </div>
              <div>
                <label htmlFor="submit-btn" className="form-control-label pointer-events-none hidden">
                  Button
                </label>
                <button className="submit-button" type="submit" disabled={!formik.isValid || formik.isSubmitting}>
                  <span className="mr-[10px]">
                    <img src={plusIcon} alt="plus icon" />
                  </span>
                  Add Store
                </button>
                <HiddenElement />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddStoreForm;
