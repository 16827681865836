/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
import { Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Toaster } from 'react-hot-toast';
import CustomRouter from './customRoutes/customRouter';
import history from './customRoutes/history';
import DefaultLayout from './layout/defaultLayout';
import ForgotPassword from './views/pages/forgotPassword';
import Login from './views/pages/login';
import OTPVerify from './views/pages/otpVerify';
import Inbound from './views/inbound/inbound';
import Outbound from './views/outbound/outbound';
import Warehouse from './views/warehouse/warehouse';
import Overview from './views/overview/overview';
import Admin from './views/admin/admin';
import PrivateRouter from './routers/privateRouter';
import Register from './views/pages/register';
import NotFound from './views/pages/notFound';
import Summary from './views/warehouse/summary';
import AllStores from './views/warehouse/allStores';
import InboundSettings from './views/settings/inboundSettings/inboundSettings';
import OutboundSettings from './views/settings/outboundSettings/outboundSettings';
import WarehouseSettings from './views/settings/warehouseSettings/warehouseSettings';
import InboundHome from './views/inbound/inboundHome';
import AdminHome from './views/admin/adminHome';
import EditInboundItem from './views/inbound/editInboundItem';
import ViewInboundItem from './views/inbound/viewInboundItem';
import OutboundHome from './views/outbound/outboundHome';
import EditOutboundItem from './views/outbound/editOutboundItem';
import ViewOutboundItem from './views/outbound/viewOutboundItem';
import EditUnit from './views/settings/inboundSettings/addUnits/editUnit';
import InboundSettingsHome from './views/settings/inboundSettings/inboundSettingsHome';
import EditCategory from './views/settings/inboundSettings/addCategories/editCategory';
import EditItem from './views/settings/inboundSettings/addItems/editItem';
import EditActivity from './views/settings/inboundSettings/addActivity/editActivity';
import EditLogistics from './views/settings/inboundSettings/logistics/editLogistics';
import OutboundSettingsHome from './views/settings/outboundSettings/outboundSettingsHome';
import EditOutboundUnit from './views/settings/outboundSettings/addUnits/editOutboundUnit';
import EditOutboundCategory from './views/settings/outboundSettings/addCategories/editOutboundCategory';
import EditOutboundSettingsItem from './views/settings/outboundSettings/addItems/editOutboundSettingsItem';
import EditOutboundActivity from './views/settings/outboundSettings/addActivity/editOutboundActivity';
import EditOutboundLogistics from './views/settings/outboundSettings/logistics/editOutboundLogistics';
import AllStoresHome from './views/warehouse/allStoresHome';
import EditStore from './views/warehouse/editStore';
import WarehouseHome from './views/warehouse/warehouseHome';
import ViewStore from './views/warehouse/viewStore';
import Procurement from './views/procurement/procurement';
import ProcurementHome from './views/procurement/procurementHome';
import NewRequest from './views/procurement/newRequest';
import RequestDetails from './views/procurement/requestDetails';
import ResetPassword from './views/pages/resetPassword';
import OrgId from './views/pages/orgId';
import EditImageType from './views/settings/imageType/editImageType';
import RegionsAndZones from './views/settings/regionsAndZones';
import Settings from './views/settings';
import RegionAndZonesHome from './views/settings/regionsAndZones/regionAndZonesHome';
import UserForm from './views/admin/userForm';
import StoreCount from './views/storeCount';
import StoreCountHome from './views/storeCount/home';
import StoreCountDetails from './views/storeCount/storeCountDetails';
import OrderRecords from './views/orderRecords';
import OrderRecordsHome from './views/orderRecords/OrderRecordsHome';
import PurchaseOrderForm from './views/orderRecords/PurchaseOrderForm';
import SalesOrderForm from './views/orderRecords/SalesOrderForm';
import TransshipmentForm from './views/orderRecords/TransshipmentForm';
import ViewPurchaseOrder from './views/orderRecords/ViewPurchaseOrder';
import ViewSalesOrder from './views/orderRecords/ViewSalesOrder';
import ViewTransshipment from './views/orderRecords/ViewTransshipment';
import Finance from './views/finance/finance';
import FinanceHome from './views/finance/financeHome';
import ViewFinanceDetails from './views/finance/viewFinanceDetails';
import GrnImage from './views/inbound/grnImage';
import WaybillImage from './views/inbound/waybillImage';
// import AuditLogs from './views/settings/auditLog/logs';
import AuditLog from './views/settings/auditLog/auditLog';
import AuditLogHome from './views/settings/auditLog/auditLogHome';

const App = () => {
  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          // Define default options
          className: '',
          duration: 5000,

          // Default options for specific types
          success: {
            className: 'shadow-xl',
            theme: {
              primary: '#48A928',
            },
            style: {
              background: '#333',
              color: '#fff',
            },
          },
          error: {
            className: 'shadow-xl',
            theme: {
              primary: 'red',
            },
            style: {
              background: '#333',
              color: '#fff',
            },
          },
        }}
      />
      <ToastContainer position="top-center" autoClose={1500} hideProgressBar newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
      <CustomRouter history={history}>
        <Routes>
          <Route path="login" element={<Login />} />
          <Route path="orgid" element={<OrgId />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="register" element={<Register />} />
          <Route path="otp-verify" element={<OTPVerify />} />
          <Route
            path="/"
            element={
              <PrivateRouter>
                <DefaultLayout />
              </PrivateRouter>
            }
          >
            <Route index element={<Overview />} />
            <Route path="inbound" element={<Inbound />}>
              <Route index element={<InboundHome />} />
              <Route path="edit/:id" element={<EditInboundItem />} />
              <Route path=":id" element={<ViewInboundItem />} />
              <Route path="grn" element={<GrnImage />} />
              <Route path="waybill" element={<WaybillImage />} />
            </Route>
            <Route path="outbound" element={<Outbound />}>
              <Route index element={<OutboundHome />} />
              <Route path="edit/:id" element={<EditOutboundItem />} />
              <Route path=":id" element={<ViewOutboundItem />} />
            </Route>
            <Route path="store-count" element={<StoreCount />}>
              <Route index element={<StoreCountHome />} />
              <Route path=":id" element={<StoreCountDetails />} />
            </Route>
            <Route path="warehouse" element={<Warehouse />}>
              <Route index element={<Navigate to="home" replace />} />
              <Route path="home" element={<WarehouseHome />}>
                <Route index element={<Summary />} />
                <Route path="all-stores" element={<AllStores />}>
                  <Route index element={<AllStoresHome />} />
                </Route>
              </Route>
              <Route path="all-stores/edit/:id" element={<EditStore />} />
              <Route path="all-stores/:id" element={<ViewStore />} />
            </Route>
            <Route path="procurement" element={<Procurement />}>
              <Route index element={<ProcurementHome />} />
              <Route path="new" element={<NewRequest />} />
              <Route path=":id" element={<RequestDetails />} />
            </Route>
            <Route path="finance" element={<Finance />}>
              <Route index element={<FinanceHome />} />
              <Route path="purchase-order/:id/:orderId" element={<ViewFinanceDetails />} />
              <Route path="pending-order/:id/:orderId" element={<ViewFinanceDetails />} />
              <Route path="completed-order/:id/:orderId" element={<ViewFinanceDetails />} />
              {/* I am routing back to this view because it was previously created, I may remove it later. */}
              <Route path="inbound-order/:id" element={<ViewInboundItem />} />
            </Route>
            <Route path="order-records" element={<OrderRecords />}>
              <Route index element={<OrderRecordsHome />} />
              <Route path="create-purchase-order" element={<PurchaseOrderForm />} />
              <Route path="create-sales-order" element={<SalesOrderForm />} />
              <Route path="create-transshipment" element={<TransshipmentForm />} />
              <Route path="purchase-order/:id" element={<ViewPurchaseOrder />} />
              <Route path="sales-order/:id" element={<ViewSalesOrder />} />
              <Route path="transshipment/:id" element={<ViewTransshipment />} />
              <Route path="purchase-order/:id/edit" element={<PurchaseOrderForm />} />
              <Route path="sales-order/:id/edit" element={<SalesOrderForm />} />
              <Route path="transshipment/:id/edit" element={<TransshipmentForm />} />
            </Route>
            <Route path="settings" element={<Settings />}>
              <Route index element={<Navigate to="inbound" replace />} />
              <Route path="inbound" element={<InboundSettings />}>
                <Route index element={<InboundSettingsHome />} />
                <Route path="edit/unit/:id" element={<EditUnit />} />
                <Route path="edit/category/:id" element={<EditCategory />} />
                <Route path="edit/item/:id" element={<EditItem />} />
                <Route path="edit/activity/:id" element={<EditActivity />} />
                <Route path="edit/logistics/:id" element={<EditLogistics />} />
              </Route>
              <Route path="outbound" element={<OutboundSettings />}>
                <Route index element={<OutboundSettingsHome />} />
                <Route path="edit/unit/:id" element={<EditOutboundUnit />} />
                <Route path="edit/category/:id" element={<EditOutboundCategory />} />
                <Route path="edit/item/:id" element={<EditOutboundSettingsItem />} />
                <Route path="edit/activity/:id" element={<EditOutboundActivity />} />
                <Route path="edit/logistics/:id" element={<EditOutboundLogistics />} />
              </Route>
              <Route path="warehouse" element={<WarehouseSettings />} />
              <Route path="regions-zones" element={<RegionsAndZones />}>
                <Route index element={<RegionAndZonesHome />} />
              </Route>
              <Route path="edit/image-type/:id" element={<EditImageType />} />
              <Route path="audit-log" element={<AuditLog />}>
                <Route index element={<AuditLogHome />} />
              </Route>
            </Route>
            <Route path="admin" element={<Admin />}>
              <Route index element={<AdminHome />} />
              <Route path="user/create" element={<UserForm />} />
              <Route path="user/edit/:id" element={<UserForm />} />
            </Route>
          </Route>
          <Route path="404" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </CustomRouter>
    </>
  );
};

export default App;
