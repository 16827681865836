import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ViewItemTemplate from '@/components/templates/viewItemTemplate';
import Spinner from '@/components/Spinner';
import { StoreCountStatusPill } from '@/components/table/appTable';
import loader from '@/assets/images/loader.gif';
import avatar from '@/assets/images/avatar.svg';
import { createStoreCountComment, getStoreCount } from '@/redux/features/storeCount/storeCountSlice';
import Clip from '@/components/svgs/Clip';
import { formatAmount } from '@/lib/utils';

const StoreCountDetails = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [comment, setComment] = useState('');
  const { storeCount, getStoreCountLoading, createCommentLoading } = useSelector((state) => state.storeCount);
  const loading = getStoreCountLoading;

  useEffect(() => {
    dispatch(getStoreCount(params.id));
  }, []);

  const submitComment = () => {
    dispatch(
      createStoreCountComment({
        id: params.id,
        text: comment,
      }),
    )
      .unwrap()
      .then(() => {
        setComment('');
        dispatch(getStoreCount(params.id));
      })
      .catch(() => {});
  };

  return (
    <>
      <ViewItemTemplate title="Store Count Details" headerCta={<div />} footer={null} fullWidth>
        {loading && <Spinner />}
        <div className="grid grid-cols-2 gap-8 lg:grid-cols-3">
          <div>
            <div className="text-sm font-semibold text-[#A6A6A6]">Warehouse</div>
            <div className="font-semibold text-[#2B2B2B]">{storeCount?.store?.store?.name || '--'}</div>
          </div>
          <div>
            <div className="text-sm font-semibold text-[#A6A6A6]">Region</div>
            <div className="font-semibold text-[#2B2B2B]">{storeCount?.user?.region?.value || '--'}</div>
          </div>
          <div>
            <div className="text-sm font-semibold text-[#A6A6A6]">Auditor</div>
            <div className="font-semibold text-[#2B2B2B]">{storeCount?.user ? `${storeCount.user?.first_name} ${storeCount.user?.last_name}` : '--'}</div>
          </div>
          <div>
            <div className="text-sm font-semibold text-[#A6A6A6]">Physical Count</div>
            <div className="font-semibold text-[#2B2B2B]">{storeCount?.physicalCountPosition ?? '--'}</div>
          </div>
          <div>
            <div className="text-sm font-semibold text-[#A6A6A6]">Physical Count Variance</div>
            <div className="font-semibold capitalize text-[#2B2B2B]">{storeCount?.variance ?? '--'}</div>
          </div>
          <div>
            <div className="text-sm font-semibold text-[#A6A6A6]">Physical Resolution Status</div>
            {storeCount?.status ? (
              <div className="font-semibold text-[#2B2B2B]">
                <StoreCountStatusPill value={storeCount?.status} />
              </div>
            ) : (
              '--'
            )}
          </div>
          <div>
            <div className="text-sm font-semibold text-[#A6A6A6]">IML Average Bag Weight(MT)</div>
            <div className="font-semibold text-[#2B2B2B]">{storeCount?.imlAvgBagWeight ? formatAmount(storeCount?.imlAvgBagWeight) : '--'}</div>
          </div>
          <div>
            <div className="text-sm font-semibold text-[#A6A6A6]">Risk Average Bag Weight(MT)</div>
            <div className="font-semibold text-[#2B2B2B]">{storeCount?.riskAvgBagWeight ? formatAmount(storeCount?.riskAvgBagWeight) : '--'}</div>
          </div>
          <div>
            <div className="text-sm font-semibold text-[#A6A6A6]">Average Weight Resolution</div>
            <div className="font-semibold text-[#2B2B2B]">{storeCount?.avgWeightResolution ?? '--'}</div>
          </div>
          <div>
            <div className="text-sm font-semibold text-[#A6A6A6]">Weight Resolution Status</div>
            <div className="flex items-center justify-between">
              <div className="font-semibold text-[#2B2B2B]">{/* <StoreCountStatusPill value="pending" /> */}--</div>
              <a className="flex items-center gap-2 text-sm font-medium text-[#646464] underline" href={storeCount?.image || ''} target="_blank" rel="noopener noreferrer">
                <Clip /> View image
              </a>
            </div>
          </div>
          <div>
            <div className="text-sm font-semibold text-[#A6A6A6]">Co-ordinates</div>
            {storeCount?.coordinates ? (
              <div className="font-semibold text-[#2B2B2B]">
                {formatAmount(storeCount?.coordinates[0])} LAT {formatAmount(storeCount?.coordinates[1])} LON
              </div>
            ) : (
              '--'
            )}
          </div>
        </div>
      </ViewItemTemplate>
      <div className="mt-7">
        <p className="mb-2 text-sm font-medium text-[#222]">Comment</p>
        <textarea value={comment} onChange={(e) => setComment(e.target.value)} className="form-control-textarea mb-2" rows="5" placeholder="Enter comment" />
        <div className="flex justify-end">
          <button onClick={submitComment} disabled={!comment.length || createCommentLoading} type="button" className="w-[150px] rounded border border-transparent bg-[#64B548] py-3 text-xs text-white disabled:cursor-not-allowed disabled:opacity-80">
            {createCommentLoading ? <img className="mx-auto w-4" src={loader} alt="loader" /> : 'Comment'}
          </button>
        </div>
      </div>
      <div className="mt-6">
        <p className="mb-6 text-2xl font-semibold text-[#222]">Comment History</p>
        <div className="flex flex-col gap-4 rounded border border-[#e6e8f0] bg-white p-6">
          {storeCount?.comments?.length
            ? // eslint-disable-next-line no-shadow
              storeCount?.comments?.map((comment, index) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={index} className="flex items-start justify-between text-[#2b2b2b]">
                    <div className="flex items-center gap-2">
                      {/* <div className="flex h-10 w-10 items-center justify-center rounded-full bg-slate-200 text-sm">
                    {storeCount?.user?.first_name[0]}
                    {storeCount?.user?.last_name[0]}
                  </div> */}
                      <img className="mr-2 h-10 w-10 rounded-full" src={avatar} alt="avatar" />
                      <div className="flex flex-col justify-between gap-1">
                        <p className="text-sm font-medium">
                          {comment?.user?.first_name || '--'} &nbsp;
                          {comment?.user?.last_name || '--'}
                        </p>
                        <p className="text-xs">{comment.text}</p>
                      </div>
                    </div>
                    <p className="text-xs">{format(new Date(comment.created_at), 'MMMM d, yyyy')}</p>
                  </div>
                );
              })
            : 'No comments'}
        </div>
      </div>
    </>
  );
};

export default StoreCountDetails;
