/* eslint-disable no-unused-vars */
import React, { useRef, useEffect, useState } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { CSVLink } from 'react-csv';
import { XMarkIcon } from '@heroicons/react/24/solid';
import downloadIcon from '@/assets/images/icons/downloadIcon.svg';
import { exportAllStoresData, getAllWarehouses, getWarehouseSummaryCharts } from '@/redux/features/warehouse/warehouseSlice';
import CustomDateRangePicker from '@/components/form/customDateRangePicker';
import { buildQueryParams } from '@/shared/buildQueryParams';

import CustomSelect from '@/components/form/customSelect';

// Initial Query Params object
const userDateQuery = {};
const WarehouseHome = () => {
  const [showWarehouseSelect, setShowWarehouseSelect] = useState(true);
  const [storeOptions, setStoreOptions] = useState([]);
  const [selectedStore, setSelectedStore] = useState(storeOptions[0]);
  // date range filter
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  // Query params
  const [userQuery, setUserQuery] = useState({});

  const dispatch = useDispatch();
  const { allWarehousesLoading, allWarehouses } = useSelector((state) => state.warehouse);
  const { allStoresData, exportAllStoresDataLoading } = useSelector((state) => state.warehouse);
  const csvLinkRef = useRef(null);

  useEffect(() => {
    dispatch(exportAllStoresData());
    dispatch(getAllWarehouses());
  }, [dispatch]);

  useEffect(() => {
    const options = [];
    allWarehouses.forEach((value) => {
      options.push({
        label: value.store.name,
        value: value._id,
      });
    });
    setStoreOptions([{ value: '', label: 'Select Store', isDisabled: true }, ...options]);
  }, [allWarehouses]);

  useEffect(() => {
    dispatch(getWarehouseSummaryCharts(userQuery));
  }, [dispatch, userQuery]);

  // Fetch when you touch the date parameters
  useEffect(() => {
    if (startDate && endDate) {
      userDateQuery.startDate = new Date(startDate).toISOString();
      userDateQuery.endDate = new Date(endDate).toISOString();
      dispatch(exportAllStoresData(buildQueryParams(userDateQuery)));
    }
  }, [startDate, endDate, dispatch]);

  const handleDateChange = (update) => {
    setDateRange(update);
  };
  const headers = allStoresData?.data.headers
    .filter((h) => h.label !== '_id' && h.label !== 'created_at')
    .map((h) => ({
      key: h.value,
      label: h.label[0].toUpperCase() + h.label.slice(1),
    }));
  console.log({ allStoresData });

  const data = allStoresData?.data.data.map((d) => {
    return {
      ...d,
      bound: d.inbound ? 'Inbound' : 'Outbound',
    };
  });

  console.log({ data });

  const handleStoreChange = (option) => {
    setSelectedStore(option);
    setUserQuery({ warehouse: option.value });
  };

  const clearFilters = () => {
    setSelectedStore(storeOptions[0]);
    setUserQuery({});
  };

  const clearDateFilters = () => {
    setDateRange([null, null]);
    dispatch(exportAllStoresData());
  };

  const showClearFilters = selectedStore?.value;

  return (
    <>
      <div className="mb-8 flex flex-col justify-between gap-3 lg:flex lg:flex-row lg:gap-0">
        <h1 className="text-2xl font-semibold text-title-color">Warehouse</h1>
        <div className="filters flex flex-col gap-4 md:flex-row md:items-center">
          <div>
            <CustomDateRangePicker selectsRange startDate={startDate} endDate={endDate} onChange={handleDateChange} dateRange={dateRange} />
          </div>

          <button type="button" onClick={() => csvLinkRef.current.link.click()} disabled={!allStoresData} className="flex h-[38px] w-fit items-center rounded bg-[#64B548] px-4 text-sm text-white disabled:cursor-not-allowed disabled:opacity-80">
            <span className="mr-[10px]">
              <img src={downloadIcon} alt="plus icon" />
            </span>
            Export
          </button>
          {/* Clear Filters */}
          {/* need a filter to clear date */}
          <button type="button" onClick={clearDateFilters} className="flex h-[38px] w-fit items-center gap-2 rounded text-sm text-[#646464]">
            <span className="inline-block">Clear Date Filters</span>
            <XMarkIcon className="h-5 w-5 text-red-500" />
          </button>
          {/* Hidden CSV Link */}
          {allStoresData && <CSVLink data={data} headers={headers} filename="All Stores Data" ref={csvLinkRef} />}
        </div>
      </div>
      <div className="mb-9 flex items-center justify-between">
        <div>
          <NavLink to="" end onClick={() => setShowWarehouseSelect(true)} className={({ isActive }) => `mr-2 rounded px-5 py-[10px] text-sm ${isActive ? 'border-none bg-[#64B548] text-white' : 'text-[#646464 border border-[#c3c3c3] bg-white'}`}>
            Summary
          </NavLink>
          <NavLink
            to="all-stores"
            onClick={() => setShowWarehouseSelect(false)}
            className={({ isActive }) => `mr-2 rounded border px-5 py-[10px] text-sm ${isActive ? 'border-transparent bg-[#64B548] text-white' : 'text-[#646464 border-[#c3c3c3] bg-white'}`}
          >
            All stores
          </NavLink>
        </div>

        {showWarehouseSelect && (
          <div className="flex gap-4">
            <CustomSelect className="text-#646464 min-w-40" value={selectedStore} onChange={handleStoreChange} options={storeOptions} isLoading={allWarehousesLoading} />
            {showClearFilters && (
              <button type="button" onClick={clearFilters} className="flex h-[38px] w-fit items-center gap-2 rounded text-sm text-[#646464]">
                <span className="inline-block">Clear Filters</span>
                <XMarkIcon className="h-5 w-5 text-red-500" />
              </button>
            )}
          </div>
        )}
      </div>
      <Outlet />
    </>
  );
};

export default WarehouseHome;
