/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
import { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { CSVLink } from 'react-csv';
import _ from 'lodash';
import InboundChart from './inboundChart';
import InboundTable from './inboundTable';
import CustomSelect from '@/components/form/customSelect';
import CustomDateRangePicker from '@/components/form/customDateRangePicker';
import { createBulkInbound, exportInboundData, getInboundData, getInboundPercentages } from '../../redux/features/inbound/inboundSlice';
import { buildQueryParams } from '@/shared/buildQueryParams';
import Spinner from '../../components/Spinner';
import { getAllActivities } from '@/redux/features/settings/inboundSettings/inboundActivitySlice';
import InboundPie from './inBoundPie';
import downloadIcon from '@/assets/images/icons/downloadIcon.svg';
import { getOverviewChartsData, getOverviewChartsDataFiltered } from '@/redux/features/charts/overviewChartsSlice';
import { getAllWarehouses } from '@/redux/features/warehouse/warehouseSlice';
import { getAllSuppliers } from '@/redux/features/settings/inboundSettings/inboundLogisticsSlice';
import { getAllItems } from '@/redux/features/settings/inboundSettings/inboundItemSlice';
import { getAllUnits } from '@/redux/features/settings/inboundSettings/inboundUnitsSlice';
import { validateSupplier, validationFormat, validationFormatNested } from '../../shared/importValidations/inbound';
import ErrorAlert from '@/shared/components/errorAlert';
import { getAllCategories } from '@/redux/features/settings/inboundSettings/inboundCategorySlice';
import { getAllRegions } from '@/redux/features/settings/regionsAndZonesSettings/regionSlice';

// const activityOptions = [{ value: 'activity', label: 'All Activities' }]
const warehouseOptions = [{ value: 'warehouse', label: 'All Warehouses' }];

// Initial Query Params object
let userQuery = { skip: 0, limit: 5 };

const InboundHome = () => {
  const dispatch = useDispatch();
  // const currentDateMinus7 = new Date()
  // currentDateMinus7.setDate(new Date().getDate() - 7)
  const { data: chartsData, loading: chartsDataLoading } = useSelector((state) => state.overviewCharts);
  const { getAllActivitiesLoading, allActivities } = useSelector((state) => state.inboundActivity);
  const { exportInboundDataLoading, createBulkInboundLoading } = useSelector((state) => state.inbound);
  const { allWarehousesLoading, allWarehouses } = useSelector((state) => state.warehouse);
  const { getAllItemsLoading, allItems } = useSelector((state) => state.inboundItem);
  const { getAllSuppliersLoading, allSuppliers } = useSelector((state) => state.inboundLogistics);
  const { getAllUnitsLoading, allUnits } = useSelector((state) => state.inboundUnits);
  const { getAllCategoriesLoading, allCategories } = useSelector((state) => state.inboundCategory);
  const { getAllRegionsLoading, allRegions } = useSelector((state) => state.region);

  const [activityOptions, setActivityOptions] = useState([]);
  const [selectedActivityOption, setSelectedActivityOption] = useState(activityOptions[0]);
  const [selectedWarehouseOption, setSelectedWarehouseOption] = useState(warehouseOptions[0]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [tableHeading, setTableHeading] = useState('All Activities');

  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = useRef(0);
  const [initialPageSize, setInitialPageSize] = useState(5);
  const csvLinkRef = useRef(null);

  const [exportedData, setExportedData] = useState([]);
  // const [file, setFile] = useState();
  const [array, setArray] = useState([]);
  const fileReader = new FileReader();
  const [fileError, setFileError] = useState([]);
  const [showErrors, setShowErrors] = useState(false);
  // const [allRowsValid, setAllRowsValid] = useState(true)

  // FetchData function for Paginated React Table
  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      userQuery.limit = pageSize;
      userQuery.skip = pageIndex * pageSize;
      const fetchId = ++fetchIdRef.current;
      if (fetchId === fetchIdRef.current) {
        dispatch(getInboundData(buildQueryParams(userQuery)))
          .unwrap()
          .then((result) => {
            setPageCount(Math.ceil(result.data.totalDocumentCount / pageSize));
          });
      }
    },
    [dispatch],
  );

  const handleOnChange = (e) => {
    // setAllRowsValid(true)
    const file = e.target.files[0];

    const csvFileToArray = (string) => {
      const csvHeader = string.slice(0, string.indexOf('\n')).split(',');
      const csvRows = string.slice(string.indexOf('\n') + 1).split('\n');

      // eslint-disable-next-line no-shadow
      let array = csvRows.map((i) => {
        const values = i.split(',');
        const obj = csvHeader.reduce((object, header, index) => {
          object[header] = values[index];
          return object;
        }, {});
        return obj;
      });

      array = array.slice(0, -1).map((row) => ({
        warehouse: row.Warehouse,
        boundItem: row.Item,
        category: row.Category,
        description: row['Description/Activity'],
        supplierName: row['Supplier Name'],
        weight: +row.Weight,
        unitOfMeasurement: row['Unit of Measurement'],
        totalBags: +row['Total Bags'],
        destination: row.Destination,
        moisture: +row.Moisture,
        purity: +row.Purity,
        thriveRep: row['Thrive Rep'],
        broughtBy: row['Brought By'],
        driverName: row['Driver Name'],
        phoneNo: row.Phone,
        plateNo: row['Plate Number'],
        quantity: +row.Quantity,
        cost: +row.Cost,
        region: row.Region,
        comment: row['Comment\r'],
      }));

      // let valid = false
      const errors = [];

      array.forEach((row, index) => {
        const keys = Object.keys(row);

        // Category Validation
        const categoryColumnIndex = keys.findIndex((key) => key === 'category');
        const isCategoryValid = validationFormat(row.category, allCategories, 'name', '_id', `${String.fromCharCode(categoryColumnIndex + 65)}${index + 2}`);

        if (isCategoryValid.exists) {
          row.category = isCategoryValid._id;
        } else {
          errors.push(isCategoryValid.error);
        }

        // Supplier Name Validation
        const supplierColumnIndex = keys.findIndex((key) => key === 'supplierName');
        const isSupplierValid = validationFormat(row.supplierName, allSuppliers, 'name', '_id', `${String.fromCharCode(supplierColumnIndex + 65)}${index + 2}`);

        if (isSupplierValid.exists) {
          row.supplierName = isSupplierValid._id;
        } else {
          errors.push(isSupplierValid.error);
        }

        // Warehouse Name Validation
        const warehouseColumnIndex = keys.findIndex((key) => key === 'warehouse');
        const isWarehouseValid = validationFormatNested(row.warehouse, allWarehouses, 'store', 'name', '_id', `${String.fromCharCode(warehouseColumnIndex + 65)}${index + 2}`);

        if (isWarehouseValid.exists) {
          row.warehouse = isWarehouseValid._id;
        } else {
          errors.push(isWarehouseValid.error);
        }

        // Item Name Validation
        const itemColumnIndex = keys.findIndex((key) => key === 'boundItem');
        const isItemValid = validationFormat(row.boundItem, allItems, 'name', '_id', `${String.fromCharCode(itemColumnIndex + 65)}${index + 2}`);

        if (isItemValid.exists) {
          row.boundItem = isItemValid._id;
        } else {
          errors.push(isItemValid.error);
        }

        // Description/Activity Name Validation
        const descColumnIndex = keys.findIndex((key) => key === 'description');
        const isDescValid = validationFormat(row.description, allActivities, 'name', '_id', `${String.fromCharCode(descColumnIndex + 65)}${index + 2}`);

        if (isDescValid.exists) {
          row.description = isDescValid._id;
        } else {
          errors.push(isDescValid.error);
        }

        // Unit Name Validation
        const descUnitIndex = keys.findIndex((key) => key === 'unitOfMeasurement');
        const isUnitValid = validationFormat(row.unitOfMeasurement, allUnits, 'name', '_id', `${String.fromCharCode(descUnitIndex + 65)}${index + 2}`);

        if (isUnitValid.exists) {
          row.unitOfMeasurement = isUnitValid._id;
        } else {
          errors.push(isUnitValid.error);
        }

        // Region Validation
        const regionColumnIndex = keys.findIndex((key) => key === 'region');
        const isRegionValid = validationFormat(row.region, allRegions, 'label', '_id', `${String.fromCharCode(regionColumnIndex + 65)}${index + 2}`);

        if (isRegionValid.exists) {
          row.region = isRegionValid._id;
        } else {
          errors.push(isRegionValid.error);
        }
      });

      // console.log('File Error', errors)
      // console.log('All is valid', errors.length ? false : true)

      // CHECK IF ALL VALID
      if (!errors.length) {
        // eslint-disable-next-line no-alert
        alert('All rows are valid');
        setFileError([]);
        setShowErrors(false);
        dispatch(createBulkInbound(array))
          .unwrap()
          .then(() => {
            fetchData({ pageSize: initialPageSize, pageIndex: 0 });
          });
      } else {
        // eslint-disable-next-line no-alert
        alert('There are invalid rows in your data');
        setFileError(errors);
        setShowErrors(true);
      }

      setArray(array);
    };

    if (file) {
      // eslint-disable-next-line func-names
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArray(text);
      };

      fileReader.readAsText(file);
    }
  };

  // CSV Heders
  const headers = [
    { label: 'Warehouse', key: 'warehouse' },
    { label: 'Store ID', key: 'storeId' },
    { label: 'Item', key: 'item' },
    { label: 'Category', key: 'category' },
    { label: 'Description/Activity', key: 'description' },
    { label: 'Supplier Name', key: 'supplier' },
    { label: 'Weight', key: 'weight' },
    { label: 'Unit of Measurement', key: 'unit' },
    { label: 'Total Bags', key: 'bags' },
    { label: 'Destination', key: 'destination' },
    { label: 'Moisture', key: 'moisture' },
    { label: 'Purity', key: 'purity' },
    { label: 'Thrive Rep', key: 'rep' },
    { label: 'Brought By', key: 'brought_by' },
    { label: 'Driver Name', key: 'driver' },
    { label: 'Phone', key: 'phone' },
    { label: 'Plate Number', key: 'plate_number' },
    { label: 'Quantity', key: 'quantity' },
    { label: 'Cost', key: 'cost' },
    { label: 'Region', key: 'region' },
    { label: 'Comment', key: 'comment' },
  ];

  const handleSearch = (term) => {
    if (term) {
      userQuery.search = term;
    } else {
      delete userQuery.search;
    }
    fetchData({ pageSize: initialPageSize, pageIndex: 0 });
  };

  useEffect(() => {
    dispatch(getAllCategories());
    dispatch(getAllWarehouses());
    dispatch(getAllSuppliers());
    dispatch(getAllItems());
    dispatch(getAllActivities());
    dispatch(getAllUnits());
    dispatch(getAllRegions());
  }, [dispatch]);

  useEffect(() => {
    // Get Charts Data
    dispatch(getOverviewChartsData());
    // Get All Activities
    dispatch(getAllActivities())
      .unwrap()
      .then((result) => {
        const options = [];
        result.data.data.forEach((value) => {
          options.push({
            label: value.name,
            value: value._id,
          });
        });
        setActivityOptions([{ label: 'Select Activity', value: '', isDisabled: true }, ...options]);
      });
  }, [dispatch]);

  useEffect(() => {
    // Fetch data when both the start date and end date are not null
    if (startDate && endDate) {
      userQuery.startDate = new Date(startDate).toISOString();
      userQuery.endDate = new Date(endDate).toISOString();
      fetchData({ pageSize: initialPageSize, pageIndex: 0 });
      dispatch(getOverviewChartsDataFiltered(buildQueryParams(_.omit(userQuery, ['skip', 'limit']))));
    }
  }, [startDate, endDate, fetchData, initialPageSize, dispatch]);

  const handleActivityChange = (option) => {
    setSelectedActivityOption(option);
    userQuery.description = option.value;
    fetchData({ pageSize: initialPageSize, pageIndex: 0 });
    dispatch(getOverviewChartsDataFiltered(buildQueryParams(_.omit(userQuery, ['skip', 'limit']))));
    setTableHeading(option.label);
  };

  const clearFilters = () => {
    setDateRange([null, null]);
    setSelectedActivityOption(activityOptions[0]);
    setTableHeading('All Activities');
    userQuery = { skip: 0, limit: 5 };
    fetchData({ pageSize: 5, pageIndex: 0 });
  };

  const exportData = () => {
    const query = { ...userQuery };
    delete query.skip;
    delete query.limit;
    // console.log('Query, query')
    dispatch(exportInboundData(buildQueryParams(query)))
      .unwrap()
      .then((result) => {
        setExportedData(
          result.data.data.map((res) => ({
            warehouse: res?.warehouse?.store?.name,
            item: res?.boundItem?.name,
            category: res?.category?.name,
            description: res?.description?.name,
            supplier: res?.supplierName?.name,
            weight: res?.weight,
            unit: res?.unitOfMeasurement?.name,
            bags: res?.totalBags,
            destination: res?.destination?.name,
            moisture: res?.moisture,
            purity: res?.purity,
            rep: res?.thriveRep,
            brought_by: res?.broughtBy,
            driver: res?.driverName,
            phone: res?.phoneNo,
            plate_number: res?.plateNo,
            quantity: res?.quantity,
            cost: res?.cost,
            comment: res?.comment,
            region: res?.region?.label,
            storeId: res?.warehouse?.store?.id,
          })),
        );
      });
  };

  useEffect(() => {
    if (exportedData.length) csvLinkRef.current.link.click();
  }, [exportedData]);

  const loading = getAllActivitiesLoading || chartsDataLoading || allWarehousesLoading || getAllItemsLoading || getAllSuppliersLoading || getAllUnitsLoading || getAllCategoriesLoading || getAllRegionsLoading;

  return (
    <>
      {showErrors && (
        <div className="fixed left-[50%] top-12 z-[1000000] -translate-x-[50%]">
          <ErrorAlert errors={fileError} onClose={() => setShowErrors(false)} />
        </div>
      )}
      <div className="relative">
        {loading && <Spinner />}
        {exportInboundDataLoading && <Spinner message="Exporting..." />}
        {createBulkInboundLoading && <Spinner message="Importing..." />}
        <div className="mb-8 flex flex-col justify-between gap-3 lg:flex lg:flex-row lg:gap-0">
          <h1 className="text-2xl font-semibold text-title-color">Inbound</h1>
          <div className="filters flex flex-col gap-4 md:flex-row md:items-center">
            <div>
              <CustomDateRangePicker
                // eslint-disable-next-line react/jsx-boolean-value
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setDateRange(update);
                }}
                dateRange={dateRange}
              />
            </div>
            <CustomSelect value={selectedActivityOption} onChange={handleActivityChange} options={activityOptions} />
            {/* <CustomSelect
            value={selectedWarehouseOption}
            onChange={(option) => setSelectedWarehouseOption(option)}
            options={warehouseOptions}
          /> */}
            <button type="button" onClick={exportData} className="flex h-[38px] w-fit items-center rounded bg-[#64B548] px-4 text-sm text-white">
              <span className="mr-[10px]">
                <img src={downloadIcon} alt="plus icon" />
              </span>
              Export
            </button>
            {/* Hidden CSV Download Link */}
            <CSVLink data={exportedData} headers={headers} filename="inboundData.csv" ref={csvLinkRef} />
            <label htmlFor="csvFileInput" className="flex h-[38px] w-fit cursor-pointer items-center rounded bg-[#64B548] px-4 text-sm text-white">
              <span className="mr-[10px]">
                <img src={downloadIcon} alt="plus icon" />
              </span>
              Import CSV
              <input className="sr-only" type="file" id="csvFileInput" accept=".csv" onChange={handleOnChange} />
            </label>

            <button type="button" onClick={clearFilters} className="flex h-[38px] w-fit items-center gap-2 rounded text-sm text-[#646464]">
              <span className="inline-block">Clear Filters</span>
              <XMarkIcon className="h-5 w-5 text-red-500" />
            </button>
          </div>
        </div>
        <div className="grid grid-cols-1 items-center gap-5 lg:grid-cols-4">
          <div className="col-span-1 lg:col-span-3">
            <InboundChart data={chartsData} loading={chartsDataLoading} />
          </div>
          <InboundPie />
        </div>
        <InboundTable fetchData={fetchData} pageCount={pageCount} tableHeading={tableHeading} onSearch={handleSearch} setInitialPageSize={setInitialPageSize} initialPageSize={initialPageSize} />
      </div>
    </>
  );
};

export default InboundHome;
