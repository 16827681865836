import { useLocation } from 'react-router-dom';
import ViewItemTemplate from '@/components/templates/viewItemTemplate';

const GrnImage = () => {
  const location = useLocation();
  const { imageUrl } = location.state || {};

  return (
    <ViewItemTemplate title="GRN" headerCta={<div />}>
      <div className="flex h-80 flex-col items-center justify-center">{imageUrl ? <img src={imageUrl} alt="Waybill" className="h-full w-[100%] " /> : <p>No image available</p>}</div>
    </ViewItemTemplate>
  );
};

export default GrnImage;
