import { format } from 'date-fns';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ActionsHeader, ActionsPopover, StatusPill } from '@/components/table/appTable';

export default function useAdminTableColumns() {
  const navigate = useNavigate();
  const columns = useMemo(
    () => [
      //   {
      //     Header: 'ID',
      //     accessor: 'id',
      //   },
      {
        Header: 'Name',
        accessor: 'first_name',
        Cell: ({ row }) => (
          <span className={row.original.isActive === true ? 'font-semibold text-green' : 'text-gray-400'}>
            {row.original.first_name} {row.original.last_name}
          </span>
        ),
      },
      {
        Header: 'Email Address',
        accessor: 'email',
      },
      {
        Header: 'Phone',
        accessor: 'phone_number',
      },
      {
        Header: 'Role',
        accessor: 'role',
        Cell: ({ value }) => <StatusPill value={value?.name} colorScheme={Math.floor(Math.random() * 4)} />,
      },
      {
        Header: 'Gender',
        accessor: 'gender',
        Cell: ({ value }) => <span className="text-[#429A24]">{value[0].toUpperCase() + value.slice(1)}</span>,
      },
      {
        Header: 'Region',
        accessor: 'region.label',
        Cell: ({ value }) => <span className="text-[#A6A6A6]">{value || '--'}</span>,
      },
      {
        Header: 'Date Created',
        accessor: 'created_at',
        Cell: ({ value }) => (
          <div className="flex w-fit flex-col gap-1">
            <span>{format(new Date(value), 'dd MMM, yyyy')}</span>
            <span className="text-right text-xs text-[#7D7D7D]">{format(new Date(value), 'hh:mm aa')}</span>
          </div>
        ),
      },
      {
        Header: 'Status',
        accessor: 'isActive',
        Cell: ({ row }) => <div>{row.original.isActive === true ? <span className="font-semibold text-green">Active</span> : <span className="font-semibold text-[red]">Deactivated</span>}</div>,
      },
      {
        Header: ActionsHeader,
        accessor: '_id',
        Cell: ({ value }) => (
          <ActionsPopover
            actions={[
              {
                text: 'Edit',
                onClickAction: () => navigate(`user/edit/${value}`),
              },
            ]}
          />
        ),
      },
    ],
    [],
  );

  return columns;
}
