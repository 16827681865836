/* eslint-disable no-unused-vars */
import React from 'react';
import { useSelector } from 'react-redux';
import Table from '@/components/table/appTable';
import useAdminTableColumns from './adminTableColumns';

const AdminTable = ({ fetchData, pageCount }) => {
  const columns = useAdminTableColumns();

  const { allUsers, getAllUsersLoading } = useSelector((state) => state.user);

  return (
    <>
      <div className="mb-7 mt-4 flex flex-col justify-between lg:flex-row ">
        <h3 className="mb-2 text-2xl font-semibold lg:mb-0">All Users</h3>
      </div>
      <div className="overflow-hidden text-gray-900">
        <main className="overflow-hidden">
          <div className="w-full">
            <Table columns={columns} data={allUsers} fetchData={fetchData} loading={getAllUsersLoading} pageCount={pageCount} />
          </div>
        </main>
      </div>
    </>
  );
};

export default AdminTable;
