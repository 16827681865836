import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '@/services/instance';
import { pendingHandler, rejectionHandler, successHandler } from '@/shared/requestHandlers';

export const addZone = createAsyncThunk('zoneSettings/addZone', async (postData) => {
  const { data } = await instance.post('/zones', postData);
  return data;
});

export const editZone = createAsyncThunk('zoneSettings/editZone', async (patchData) => {
  const { data } = await instance.patch('/zones', patchData);
  return data;
});

export const getZone = createAsyncThunk('zoneSettings/getZone', async (id) => {
  const { data } = await instance.get(`/zones/getOne?_id=${id}`);
  return data;
});

export const deleteZone = createAsyncThunk('zoneSettings/deleteZone', async (id) => {
  const { data } = await instance.delete(`/zones?_id=${id}`);
  return data;
});

export const getZones = createAsyncThunk('zoneSettings/getZones', async (queryParams) => {
  const { data } = await instance.get(`/zones${queryParams}`);
  return data;
});

export const getAllZones = createAsyncThunk('zoneSettings/getAllZones', async () => {
  const { data } = await instance.get('/zones');
  return data;
});

const initialState = {
  zones: [],
  allZones: [],
  addZoneLoading: false,
  editZoneLoading: false,
  getZonesLoading: false,
  getAllZonesLoading: false,
  deleteZoneLoading: false,
  error: '',
};

const zoneSlice = createSlice({
  name: 'zoneSettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addZone.pending, (state) => {
        pendingHandler(state, 'addZoneLoading');
      })
      .addCase(addZone.fulfilled, (state) => {
        // state.zones.push(data)
        successHandler(state, 'addZoneLoading', 'Zone added');
      })
      .addCase(addZone.rejected, (state, action) => {
        rejectionHandler(state, action, 'addZoneLoading');
      })
      .addCase(getZone.pending, (state) => {
        pendingHandler(state, 'getItemLoading');
      })
      .addCase(getZone.fulfilled, (state) => {
        successHandler(state, 'getItemLoading');
      })
      .addCase(getZone.rejected, (state, action) => {
        rejectionHandler(state, action, 'getItemLoading');
      })
      .addCase(editZone.pending, (state) => {
        pendingHandler(state, 'editZoneLoading');
      })
      .addCase(editZone.fulfilled, (state) => {
        successHandler(state, 'editZoneLoading', 'Zone updated');
      })
      .addCase(editZone.rejected, (state, action) => {
        rejectionHandler(state, action, 'editZoneLoading');
      })
      .addCase(deleteZone.pending, (state) => {
        pendingHandler(state, 'deleteZoneLoading');
      })
      .addCase(deleteZone.fulfilled, (state) => {
        successHandler(state, 'deleteZoneLoading', 'Zone deleted');
      })
      .addCase(deleteZone.rejected, (state, action) => {
        rejectionHandler(state, action, 'deleteZoneLoading');
      })
      .addCase(getZones.pending, (state) => {
        pendingHandler(state, 'getZonesLoading');
      })
      .addCase(getZones.fulfilled, (state, { payload: { data } }) => {
        state.zones = data.data;
        successHandler(state, 'getZonesLoading');
      })
      .addCase(getZones.rejected, (state, action) => {
        rejectionHandler(state, action, 'getZonesLoading');
      })
      .addCase(getAllZones.pending, (state) => {
        pendingHandler(state, 'getAllZonesLoading');
      })
      .addCase(getAllZones.fulfilled, (state, { payload: { data } }) => {
        state.allZones = data.data;
        successHandler(state, 'getAllZonesLoading');
      })
      .addCase(getAllZones.rejected, (state, action) => {
        rejectionHandler(state, action, 'getAllZonesLoading');
      });
  },
});

export default zoneSlice.reducer;
