// import spinner from '../assets/images/spinner.gif';
import spinner from '@/assets/images/spinner.gif';

const Spinner = ({ message }) => {
  return (
    <div className="pointer-events-none fixed left-0 top-0 z-20 flex h-full w-full flex-col items-center justify-center">
      <img className="h-28 w-28" src={spinner} alt="spinner" />
      {message && <div className="-translate-y-5 text-[#878787]">{message}</div>}
    </div>
  );
};

export default Spinner;
