import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '@/services/instance';
import { pendingHandler, rejectionHandler, successHandler } from '@/shared/requestHandlers';

export const createSupplier = createAsyncThunk('logisticsSettings/createSupplier', async (postData) => {
  const { data } = await instance.post('/suppliers', postData);
  return data;
});

export const editSupplier = createAsyncThunk('logisticsSettings/editSupplier', async (patchData) => {
  const { data } = await instance.patch('/suppliers', patchData);
  return data;
});

export const deleteSupplier = createAsyncThunk('logisticsSettings/deleteSupplier', async (id) => {
  const { data } = await instance.delete(`/suppliers?_id=${id}`);
  return data;
});

export const getSupplier = createAsyncThunk('logisticsSettings/getSupplier', async (id) => {
  const { data } = await instance.get(`/suppliers/getOne?_id=${id}`);
  return data;
});

export const getSuppliers = createAsyncThunk('logisticsSettings/getSuppliers', async (queryParams) => {
  const { data } = await instance.get(`/suppliers${queryParams}`);
  return data;
});

export const getAllSuppliers = createAsyncThunk('logisticsSettings/getAllSuppliers', async () => {
  const { data } = await instance.get(`/suppliers`);
  return data;
});

const initialState = {
  suppliers: [],
  allSuppliers: [],
  createSupplierLoading: false,
  getSuppliersLoading: false,
  getSupplierLoading: false,
  editSupplierLoading: false,
  deleteSupplierLoading: false,
  getAllSuppliersLoading: false,
  error: '',
};

const inboundLogisticsSlice = createSlice({
  name: 'logisticsSettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createSupplier.pending, (state) => {
        pendingHandler(state, 'createSupplierLoading');
      })
      .addCase(createSupplier.fulfilled, (state) => {
        // state.suppliers.push(data)
        successHandler(state, 'createSupplierLoading', 'Supplier created');
      })
      .addCase(createSupplier.rejected, (state, action) => {
        rejectionHandler(state, action, 'createSupplierLoading');
      })
      .addCase(deleteSupplier.pending, (state) => {
        pendingHandler(state, 'deleteSupplierLoading');
      })
      .addCase(deleteSupplier.fulfilled, (state) => {
        successHandler(state, 'deleteSupplierLoading', 'Supplier deleted');
      })
      .addCase(deleteSupplier.rejected, (state, action) => {
        rejectionHandler(state, action, 'deleteSupplierLoading');
      })
      .addCase(getSuppliers.pending, (state) => {
        pendingHandler(state, 'getSuppliersLoading');
      })
      .addCase(getSuppliers.fulfilled, (state, { payload: { data } }) => {
        state.suppliers = data.data;
        successHandler(state, 'getSuppliersLoading');
      })
      .addCase(getSuppliers.rejected, (state, action) => {
        rejectionHandler(state, action, 'getSuppliersLoading');
      })
      .addCase(getSupplier.pending, (state) => {
        pendingHandler(state, 'getSupplierLoading');
      })
      .addCase(getSupplier.fulfilled, (state) => {
        successHandler(state, 'getSupplierLoading');
      })
      .addCase(getSupplier.rejected, (state, action) => {
        rejectionHandler(state, action, 'getSupplierLoading');
      })
      .addCase(editSupplier.pending, (state) => {
        pendingHandler(state, 'editSupplierLoading');
      })
      .addCase(editSupplier.fulfilled, (state) => {
        successHandler(state, 'editSupplierLoading', 'Supplier updated');
      })
      .addCase(editSupplier.rejected, (state, action) => {
        rejectionHandler(state, action, 'editSupplierLoading');
      })
      .addCase(getAllSuppliers.pending, (state) => {
        pendingHandler(state, 'getAllSuppliersLoading');
      })
      .addCase(getAllSuppliers.fulfilled, (state, { payload: { data } }) => {
        state.allSuppliers = data.data;
        successHandler(state, 'getAllSuppliersLoading');
      })
      .addCase(getAllSuppliers.rejected, (state, action) => {
        rejectionHandler(state, action, 'getAllSuppliersLoading');
      });
  },
});

export default inboundLogisticsSlice.reducer;
