/* eslint-disable react/no-array-index-key */
import { useSelector } from 'react-redux';
import MetricCard from '@/components/metricCard/metricCard';

const COLORS = ['green', 'purple', 'yellow'];

const Summary = () => {
  const { warehouseSummaryChartsData, warehouseSummaryChartsLoading: loading } = useSelector((state) => state.warehouse);

  const data = warehouseSummaryChartsData ? Object.entries(warehouseSummaryChartsData) : [];

  return (
    <>
      {loading && (
        <div className="mb-5 grid justify-between gap-8 md:grid-cols-2 lg:grid-cols-3">
          {Array(9)
            .fill(null)
            .map((_, index) => (
              <div key={index} className="h-[200px] animate-pulse rounded-md bg-gray-300" />
            ))}
        </div>
      )}
      {data.length ? (
        <div className="mb-5 grid justify-between gap-8 md:grid-cols-2 lg:grid-cols-3">
          {data?.map((d, index) => {
            const color = COLORS[index % COLORS.length];
            const _data = d[1]?.details || [];
            const total = d[1]?.grossWeight || 0;
            const totalBags = d[1]?.grossBags || 0;
            const unit = d[1]?.unit;
            return <MetricCard key={index} title={d[0]} gradient={color} leftUnit="Kg" rightUnit={unit} leftValue={total} rightValue={totalBags} data={_data} />;
          })}
        </div>
      ) : (
        <div>No records</div>
      )}
    </>
  );
};

export default Summary;
