import React from 'react';
import { useSelector } from 'react-redux';
import Table from '@/components/table/appTable';
import useAuditLogTableColumns from './logTableColumns';

const AuditLogsTable = ({ fetchData, pageCount }) => {
  const columns = useAuditLogTableColumns();
  const { auditLogs, getAuditLogsTypeLoading } = useSelector((state) => state.auditLogs);
  return (
    <>
      <div className="mb-7 mt-4 flex flex-col justify-between lg:flex-row ">
        <h3 className="mb-2 text-2xl font-semibold lg:mb-0">Audit Logs</h3>
      </div>
      <div className="overflow-hidden text-gray-900">
        <main>
          <div>
            <Table columns={columns} data={auditLogs} fetchData={fetchData} loading={getAuditLogsTypeLoading} pageCount={pageCount} />
          </div>
        </main>
      </div>
    </>
  );
};

export default AuditLogsTable;
