/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import AuthPageLayout from '@/layout/authPageLayout';
import { loginUser } from '@/redux/features/user/userSlice';
import AuthInput from './shared/authInput';
import * as authService from '@/services/authService';
import Button from './shared/authButton';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  // const user = useSelector((state) => state.user);
  const [userDetails, setUserDetails] = useState({
    username: '',
    password: '',
  });
  // const [showPassword, setShowPassword] = useState(false);
  // const [disableButton, setDisableButton] = useState(true);

  const redirectPath = location.state.path || '/';

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({ ...userDetails, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      loginUser({
        phone_number: userDetails.username,
        password: userDetails.password,
      }),
    );
  };

  if (authService.getToken()) {
    return <Navigate to={redirectPath} replace />;
  }

  return (
    <AuthPageLayout
      title="Forgot Password"
      subTitle="Please enter the email address associated
    with your account"
    >
      <form autoComplete="off" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="username" className="mb-2 text-base text-[#383838]">
            Email Address/Phone Number
          </label>
          <AuthInput type="text" name="username" id="username" onChange={handleChange} value={userDetails.username} required placeholder="Enter email address/phone number" />
        </div>
        <Button type="button" onClick={() => navigate('/otp-verify')}>
          Confirm Email
        </Button>
      </form>
    </AuthPageLayout>
  );
};

export default ForgotPassword;
