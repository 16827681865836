import { Popover, PopoverContent, PopoverTrigger } from '../../popover';
import ellipsis from '@/assets/images/icons/ellipsis.svg';

const EllipsisTrigger = () => {
  return (
    <button type="button">
      <img src={ellipsis} alt="ellipsis" />
    </button>
  );
};

const ActionsContainer = ({ disabled, children, trigger = <EllipsisTrigger />, side = 'top', sideOffset = -100, alignOffset = -20, align = 'end' }) => {
  return (
    <Popover>
      <PopoverTrigger className={`${disabled ? 'pointer-events-none' : ''}`}>{trigger}</PopoverTrigger>
      {!disabled && (
        <PopoverContent side={side} sideOffset={sideOffset} alignOffset={alignOffset} align={align} extraClassNames="w-[210px] py-5 pl-4">
          <div className="flex flex-col gap-5 text-left">
            <div className="text-[10.5px] text-[#696F8C]">Actions</div>
            {children}
          </div>
        </PopoverContent>
      )}
    </Popover>
  );
};

export default ActionsContainer;
