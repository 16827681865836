import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import instance from '@/services/instance';
import { pendingHandler, rejectionHandler, successHandler } from '@/shared/requestHandlers';
import history from '@/customRoutes/history';

export const getAMetricCardData = createAsyncThunk('orders/getmetricCardData', async () => {
  const { data } = await instance.get(`/orders`);
  return data;
});

export const getAllOrdersData = createAsyncThunk('orders/getAllOrdersData', async (queryParams) => {
  const { data } = await instance.get(`/orders${queryParams}`);
  return data;
});

export const getOneOrder = createAsyncThunk('orders/getOneOrder', async (id) => {
  const { data } = await instance.get(`/orders/getOne?_id=${id}`);
  return data;
});

export const createOrder = createAsyncThunk('orders/createOrder', async (payload) => {
  const { data } = await instance.post('/orders', payload);
  return data;
});

export const editOrder = createAsyncThunk('orders/editOrder', async (payload) => {
  const { data } = await instance.patch(`/orders`, payload);
  return data;
});

export const deleteOrder = createAsyncThunk('orders/deleteOrder', async (id) => {
  const { data } = await instance.delete(`/orders/${id}`);
  return data;
});

export const partPayment = createAsyncThunk('orders/partPayment', async (payload) => {
  const { data } = await instance.patch(`/inBound/payments`, payload);
  return data;
});

export const fullPayment = createAsyncThunk('orders/fullPayment', async (payload) => {
  const { data } = await instance.patch(`/inBound/payments`, payload);
  return data;
});

const initialState = {
  allOrderData: [],
  orderItem: null,
  totalCreated: 0,
  totalPending: 0,
  totalCompleted: 0,
  metricCardData: [],
  pageCount: 0,
  loading: false,
  createOrderLoading: false,
  editOrderLoading: false,
  paymentLoading: false,
  error: '',
};

const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAMetricCardData.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(getAMetricCardData.fulfilled, (state, { payload: { data } }) => {
        state.metricCardData = data;
        state.loading = false;
        state.error = '';
      })
      .addCase(getAMetricCardData.rejected, (state, action) => {
        state.error = action.error.message || 'An error occurred';
        state.loading = false;
        toast.error(action.error.message || 'An error occurred');
      })

      .addCase(getAllOrdersData.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(getAllOrdersData.fulfilled, (state, { payload: { data } }) => {
        state.allOrderData = data.data;
        state.totalCreated = data.totalDocumentCount;
        state.totalPending = data.totalPending;
        state.totalCompleted = data.totalCompleted;
        state.loading = false;
        state.error = '';
      })
      .addCase(getAllOrdersData.rejected, (state, action) => {
        state.error = action.error.message || 'An error occurred';
        state.loading = false;
        toast.error(action.error.message || 'An error occurred');
      })

      .addCase(getOneOrder.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(getOneOrder.fulfilled, (state, { payload: { data } }) => {
        state.orderItem = data;
        state.loading = false;
        state.error = '';
      })
      .addCase(getOneOrder.rejected, (state, action) => {
        state.error = action.error.message || 'An error occurred';
        state.loading = false;
        toast.error(action.error.message || 'An error occurred');
      })

      .addCase(createOrder.pending, (state) => {
        state.createOrderLoading = true;
        state.error = '';
      })
      .addCase(createOrder.fulfilled, (state) => {
        // state.createOrder = false;
        // state.error = '';
        // toast.success('Order created Successfully');
        successHandler(state, 'createOrderLoading', 'Order created');
        history.push('/order-records');
      })
      .addCase(createOrder.rejected, (state, action) => {
        state.error = action.error.message || 'An error occurred';
        state.createOrderLoading = false;
        toast.error(action.error.message || 'An error occurred');
      })

      .addCase(editOrder.pending, (state) => {
        state.editOrderLoading = true;
        state.error = '';
      })
      .addCase(editOrder.fulfilled, (state) => {
        state.editOrderLoading = false;
        state.error = '';
        toast.success('Order updated');
        history.push('/order-records');
      })
      .addCase(editOrder.rejected, (state, action) => {
        state.error = action.error.message || 'An error occurred';
        state.editOrderLoading = false;
        toast.error(action.error.message || 'An error occurred');
      })

      .addCase(deleteOrder.pending, (state) => {
        pendingHandler(state, 'deleteItemLoading');
      })
      .addCase(deleteOrder.fulfilled, (state) => {
        successHandler(state, 'deleteItemLoading', 'order deleted');
      })
      .addCase(deleteOrder.rejected, (state, action) => {
        rejectionHandler(state, action, 'deleteItemLoading');
      })

      .addCase(partPayment.pending, (state) => {
        state.paymentLoading = true;
        state.error = '';
      })
      .addCase(partPayment.fulfilled, (state) => {
        state.paymentLoading = false;
        state.error = '';
        toast.success('Part Payment Successful');
        history.push('/finance');
      })
      .addCase(partPayment.rejected, (state, action) => {
        state.error = action.error.message || 'An error occurred';
        state.paymentLoading = false;
        toast.error(action.error.message || 'Payment failed');
      })

      .addCase(fullPayment.pending, (state) => {
        state.paymentLoading = true;
        state.error = '';
      })
      .addCase(fullPayment.fulfilled, (state) => {
        state.paymentLoading = false;
        state.error = '';
        toast.success('Full Payment Successful');
        history.push('/finance');
      })
      .addCase(fullPayment.rejected, (state, action) => {
        state.error = action.error.message || 'An error occurred';
        state.paymentLoading = false;
        toast.error(action.error.message || 'Payment failed');
      });
  },
});

export default orderSlice.reducer;
