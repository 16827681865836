/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { XMarkIcon } from '@heroicons/react/24/solid';
import _ from 'lodash';
import CustomSelect from '@/components/form/customSelect';
import StoreCountTable from './storeCountTable';
import MetricCard from '@/components/metricCard/metricCard';
import { getAllItems } from '@/redux/features/settings/settingsSlice';
import { getAllWarehouses } from '@/redux/features/warehouse/warehouseSlice';
import { getInventoryDetails, getStoreCounts } from '@/redux/features/storeCount/storeCountSlice';

const StoreCountHome = () => {
  const dispatch = useDispatch();
  const { getAllItemsLoading, allItems } = useSelector((state) => state.settings);
  const { allWarehousesLoading, allWarehouses } = useSelector((state) => state.warehouse);
  const { inventoryDetailsLoading, inventoryDetails } = useSelector((state) => state.storeCount);
  const [produceOptions, setProduceOptions] = useState([]);
  const [storeOptions, setStoreOptions] = useState([]);
  const [selectedProduce, setSelectedProduce] = useState(produceOptions[0]);
  const [selectedStore, setSelectedStore] = useState(storeOptions[0]);

  // Table states
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [pageCount, setPageCount] = useState(0);

  // Query params
  const [userQuery, setUserQuery] = useState({});
  const [inventoryDetailsQuery, setInventoryDetailsQuery] = useState({});

  useEffect(() => {
    dispatch(getAllItems());
    dispatch(getAllWarehouses());
  }, [dispatch]);

  useEffect(() => {
    const options = [];
    allItems.forEach((value) => {
      options.push({
        label: value.name,
        value: JSON.stringify([value.name, value._id]),
      });
    });
    setProduceOptions([{ label: 'Select Produce', value: '', isDisabled: true }, ...options]);
  }, [allItems]);

  useEffect(() => {
    const options = [];
    allWarehouses.forEach((value) => {
      options.push({
        label: value.store.name,
        value: JSON.stringify([value.store.name, value._id]),
      });
    });
    setStoreOptions([{ value: '', label: 'Select Store', isDisabled: true }, ...options]);
  }, [allWarehouses]);

  // Update userQuery when page size and current page change
  useEffect(() => {
    setUserQuery((prevState) => ({ ...prevState, skip: page * pageSize, limit: pageSize }));
  }, [page, pageSize]);

  // Fetch data when user query changes
  useEffect(() => {
    // Only call the endpoints to fetch table records when pagination params are present
    if (!_.isEmpty(userQuery)) {
      dispatch(getStoreCounts(userQuery))
        .unwrap()
        .then((result) => {
          setPageCount(Math.ceil(result.data.totalDocumentCount / userQuery.limit));
        });
    }
  }, [userQuery, dispatch]);

  // Fetch inventory details data
  useEffect(() => {
    dispatch(getInventoryDetails(inventoryDetailsQuery));
  }, [inventoryDetailsQuery, dispatch]);

  const handleProduceChange = (option) => {
    setSelectedProduce(option);
    setUserQuery((prevState) => ({ ...prevState, commodityName: JSON.parse(option.value)[0] }));
    setInventoryDetailsQuery((prevState) => ({ ...prevState, boundItem: JSON.parse(option.value)[1] }));
  };

  const handleStoreChange = (option) => {
    setSelectedStore(option);
    setUserQuery((prevState) => ({ ...prevState, storeName: JSON.parse(option.value)[0] }));
    setInventoryDetailsQuery((prevState) => ({ ...prevState, warehouse: JSON.parse(option.value)[1] }));
  };

  const clearFilters = () => {
    setSelectedProduce(produceOptions[0]);
    setSelectedStore(storeOptions[0]);

    setUserQuery({});
    setInventoryDetailsQuery({});
  };

  const showClearFilters = selectedProduce?.value || selectedStore?.value;

  return (
    <div>
      <div className="mb-8 flex flex-col justify-between gap-3 lg:flex lg:flex-row lg:gap-0">
        <h1 className="text-2xl font-semibold text-title-color">Store Count</h1>
        <div className="filters flex flex-col gap-4 md:flex-row md:items-center">
          <CustomSelect value={selectedProduce} onChange={handleProduceChange} options={produceOptions} isLoading={allWarehousesLoading} className="w-40" />
          <CustomSelect value={selectedStore} onChange={handleStoreChange} options={storeOptions} isLoading={getAllItemsLoading} className="w-40" />
          {showClearFilters && (
            <button type="button" onClick={clearFilters} className="flex h-[38px] w-fit items-center gap-2 rounded text-sm text-[#646464]">
              <span className="inline-block">Clear Filters</span>
              <XMarkIcon className="h-5 w-5 text-red-500" />
            </button>
          )}
        </div>
      </div>
      <div className="mb-7 grid grid-cols-1 justify-between gap-8 md:grid-cols-2 lg:grid-cols-3">
        <MetricCard title="Inbound" leftUnit="" leftValue={inventoryDetails?.inbounds ?? '--'} />
        <MetricCard title="Outbound" leftUnit="" leftValue={inventoryDetails?.outbounds ?? '--'} />
        <MetricCard title="Inventory Position" leftUnit="" leftValue={inventoryDetails?.inventoryPosition ?? '--'} />
      </div>
      <StoreCountTable pageCount={pageCount} setPageSize={setPageSize} pageSize={pageSize} setPage={setPage} />
    </div>
  );
};

export default StoreCountHome;
