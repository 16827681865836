/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ViewItemTemplate from '@/components/templates/viewItemTemplate';
import Spinner from '@/components/Spinner';
import paperClip from '@/assets/images/icons/paperclip.svg';
import notifIcon from '@/assets/images/icons/notifications-icon.svg';
import { RequestStatusPill } from '@/components/table/appTable';
import RequestHistoryTable from './requestHistoryTable';
import { getInboundData } from '@/redux/features/inbound/inboundSlice';
import { buildQueryParams } from '@/shared/buildQueryParams';

// Initial Query Params object
const userQuery = { skip: 0, limit: 5 };

const RequestDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { requests } = useSelector((state) => state.procurement);
  const loading = false;
  const dispatch = useDispatch();
  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = useRef(0);
  const [request, setRequest] = useState(null);

  // FetchData function for Paginated React Table
  const fetchData = useCallback(({ pageSize, pageIndex }) => {
    userQuery.limit = pageSize;
    userQuery.skip = pageIndex * pageSize;

    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current) {
      dispatch(getInboundData(buildQueryParams(userQuery)))
        .unwrap()
        .then((result) => {
          setPageCount(Math.ceil(result.data.totalDocumentCount / pageSize));
        });
    }
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-shadow
    const request = requests.find((request) => request.id === id);
    if (request) {
      setRequest(request);
    } else {
      navigate('/404');
    }
  }, []);

  // const AttachInvoice = () => (
  //   <button className="flex h-fit cursor-pointer items-center gap-3 rounded border border-[#C3C3C3] px-3 py-[2px] text-sm text-[#646464]">
  //     <img src={paperClip} alt="paperclip" />
  //     <span>Attach invoice</span>
  //   </button>
  // )

  return (
    <>
      <ViewItemTemplate title="Request Details" headerCta={<div />} footer={<Footer />}>
        {loading && <Spinner />}
        <div className="grid grid-cols-2 gap-8 lg:grid-cols-3">
          <div>
            <div className="text-[11px] font-bold text-[#A6A6A6]">ID</div>
            <div className="text-sm font-semibold text-[#2B2B2B]">{request?.id ?? '-'}</div>
          </div>
          <div>
            <div className="text-[11px] font-bold text-[#A6A6A6]">Description</div>
            <div className="text-sm font-semibold text-[#2B2B2B]">{request?.description ?? '-'}</div>
          </div>
          <div>
            <div className="text-[11px] font-bold text-[#A6A6A6]">Item</div>
            <div className="text-sm font-semibold text-[#2B2B2B]">{request?.item ?? '-'}</div>
          </div>
          <div>
            <div className="text-[11px] font-bold text-[#A6A6A6]">QTY</div>
            <div className="text-sm font-semibold text-[#2B2B2B]">{request?.quantity ?? '-'}</div>
          </div>
          <div>
            <div className="text-[11px] font-bold text-[#A6A6A6]">Unit</div>
            <div className="text-sm font-semibold text-[#2B2B2B]">{request?.unit?.name ?? '-'}</div>
          </div>
          <div>
            <div className="text-[11px] font-bold text-[#A6A6A6]">Amount</div>
            <div className="text-sm font-semibold text-[#2B2B2B]">₦{request?.amount ?? '-'}</div>
          </div>
          <div>
            <div className="text-[11px] font-bold text-[#A6A6A6]">Requester</div>
            <div className="text-sm font-semibold text-[#2B2B2B]">{request?.requestedBy?.first_name + ' ' + request?.requestedBy?.last_name}</div>
          </div>
          <div>
            <div className="text-[11px] font-bold text-[#A6A6A6]">Status</div>
            <div className="text-sm font-semibold text-[#2B2B2B]">
              <RequestStatusPill value={request?.status} />
            </div>
          </div>
          <div />
          <div>
            <div className="text-[11px] font-bold text-[#A6A6A6]">Comment</div>
            <div className="text-sm font-semibold text-[#2B2B2B]">{request?.comment ?? '-'}</div>
          </div>
        </div>
      </ViewItemTemplate>
      <RequestHistoryTable fetchData={fetchData} pageCount={pageCount} tableHeading="Request History" />
    </>
  );
};

const Footer = () => {
  return (
    <div className="mt-7 flex items-center justify-between">
      <div className="flex items-center gap-3">
        <button type="button" className="h-fit rounded-[3px] border border-transparent bg-[#64B548] px-4 py-[5px] text-[11px] font-semibold text-white">
          Approve
        </button>
        <button type="button" className="h-fit rounded-[3px] border border-[#BF3636] bg-white px-4 py-[5px] text-[11px] font-semibold text-[#BF3636]">
          Reject
        </button>
      </div>
      <button type="button" className="flex h-fit cursor-pointer items-center gap-3 rounded bg-[#FFB833] px-3 py-1 text-sm text-white">
        <img src={notifIcon} alt="notifications icon" />
        <span>Nudge Reviewer</span>
      </button>
    </div>
  );
};
export default RequestDetails;
