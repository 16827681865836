import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '@/services/instance';
import { pendingHandler, rejectionHandler, successHandler } from '@/shared/requestHandlers';

export const addItem = createAsyncThunk('itemSettings/outbound/addItem', async (postData) => {
  const { data } = await instance.post('/itemTypes', postData);
  return data;
});

export const editItem = createAsyncThunk('itemSettings/outbound/editItem', async (patchData) => {
  const { data } = await instance.patch('/itemTypes', patchData);
  return data;
});

export const getItem = createAsyncThunk('itemSettings/outbound/getItem', async (id) => {
  const { data } = await instance.get(`/itemTypes/getOne?_id=${id}`);
  return data;
});

export const deleteItem = createAsyncThunk('itemSettings/outbound/deleteItem', async (id) => {
  const { data } = await instance.delete(`/itemTypes?_id=${id}`);
  return data;
});

export const getItems = createAsyncThunk('categorySettings/getItems', async (queryParams) => {
  const { data } = await instance.get(`/itemTypes${queryParams}`);
  return data;
});

export const getAllItems = createAsyncThunk('categorySettings/getAllItems', async () => {
  const { data } = await instance.get('/itemTypes?bound=outbound');
  return data;
});

const initialState = {
  items: [],
  allItems: [],
  addItemLoading: false,
  editItemLoading: false,
  deleteItemLoading: false,
  getItemLoading: false,
  getItemsLoading: false,
  getAllItemsLoading: false,
  error: '',
};

const outboundItemSlice = createSlice({
  name: 'categorySettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addItem.pending, (state) => {
        pendingHandler(state, 'addItemLoading');
      })
      .addCase(addItem.fulfilled, (state) => {
        // state.items.unshift(data)
        successHandler(state, 'addItemLoading', 'Item added');
      })
      .addCase(addItem.rejected, (state, action) => {
        rejectionHandler(state, action, 'addItemLoading');
      })
      .addCase(getItem.pending, (state) => {
        pendingHandler(state, 'getItemLoading');
      })
      .addCase(getItem.fulfilled, (state) => {
        successHandler(state, 'getItemLoading');
      })
      .addCase(getItem.rejected, (state, action) => {
        rejectionHandler(state, action, 'getItemLoading');
      })
      .addCase(editItem.pending, (state) => {
        pendingHandler(state, 'editItemLoading');
      })
      .addCase(editItem.fulfilled, (state) => {
        successHandler(state, 'editItemLoading', 'Item updated');
      })
      .addCase(editItem.rejected, (state, action) => {
        rejectionHandler(state, action, 'editItemLoading');
      })
      .addCase(deleteItem.pending, (state) => {
        pendingHandler(state, 'deleteItemLoading');
      })
      .addCase(deleteItem.fulfilled, (state) => {
        successHandler(state, 'deleteItemLoading', 'Item deleted');
      })
      .addCase(deleteItem.rejected, (state, action) => {
        rejectionHandler(state, action, 'deleteItemLoading');
      })
      .addCase(getItems.pending, (state) => {
        pendingHandler(state, 'getItemsLoading');
      })
      .addCase(getItems.fulfilled, (state, { payload: { data } }) => {
        state.items = data.data;
        successHandler(state, 'getItemsLoading');
      })
      .addCase(getItems.rejected, (state, action) => {
        rejectionHandler(state, action, 'getItemsLoading');
      })
      .addCase(getAllItems.pending, (state) => {
        pendingHandler(state, 'getAllItemsLoading');
      })
      .addCase(getAllItems.fulfilled, (state, { payload: { data } }) => {
        state.allItems = data.data;
        successHandler(state, 'getAllItemsLoading');
      })
      .addCase(getAllItems.rejected, (state, action) => {
        rejectionHandler(state, action, 'getAllItemsLoading');
      });
  },
});

export default outboundItemSlice.reducer;
