import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import _ from 'lodash';
import FormikControl from '@/components/formik/formikControl';
import plusIcon from '@/assets/images/icons/plus-icon.svg';
import HiddenElement from '@/components/formik/hiddenElement';
import Spinner from '@/components/Spinner';
import { addZone } from '@/redux/features/settings/regionsAndZonesSettings/zoneSlice';

const AddZoneForm = ({ fetchData, initialPageSize }) => {
  const dispatch = useDispatch();
  const { zones, addZoneLoading } = useSelector((state) => state.zone);

  const zoneId = useMemo(() => {
    const regionNumbers = zones.map((region) => region.value.replace(/[a-z]/gi, ''));
    if (regionNumbers.length > 0) {
      return `z${Number(_.max(regionNumbers)) + 1}`;
    }
    return 'z1';
  }, [zones]);

  const initialValues = {
    label: '',
    value: zoneId,
  };

  const validationSchema = Yup.object({
    label: Yup.string().required('Required'),
    value: Yup.string().required('Required'),
  });

  const onSubmit = (values, submitProps) => {
    dispatch(addZone({ ...values }))
      .unwrap()
      .then(() => {
        fetchData({ pageSize: initialPageSize, pageIndex: 0 });
      });
    submitProps.resetForm();
  };

  const loading = addZoneLoading;

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
      {(formik) => {
        return (
          <Form className="relative rounded-t-lg bg-white px-8 pb-7 pt-8">
            {loading && <Spinner />}
            <div className="flex gap-7">
              <div className="form-group">
                <FormikControl control="input" type="text" label="Name" name="label" placeholder="Zone name" />
              </div>
              <div className="form-group">
                <FormikControl control="input" type="text" label="ID" name="value" placeholder="Zone value" disabled />
              </div>
              <div>
                <label htmlFor="submit-btn" className="form-control-label pointer-events-none opacity-0">
                  Button
                </label>
                <button className="submit-button whitespace-nowrap" type="submit" disabled={!formik.isValid || formik.isSubmitting}>
                  <span className="mr-[10px]">
                    <img src={plusIcon} alt="plus icon" />
                  </span>
                  Add Zone
                </button>
                <HiddenElement />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddZoneForm;
