/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Loader2 } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import CartIcon from '@/components/svgs/CartIcon';
import WarningIcon from '@/components/svgs/WarningIcon'; // Assuming you have a WarningIcon component
import { getOneOrder, fullPayment } from '@/redux/features/orders/ordersSlice';
import Spinner from '@/components/Spinner';

const FullPaymentDialog = ({ GRN }) => {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;
  const { orderItem, paymentLoading } = useSelector((state) => state.orders);

  const loading = paymentLoading;

  // Get full price
  useEffect(() => {
    dispatch(getOneOrder(id));
  }, [dispatch, id]);

  const handleFullPayment = () => {
    dispatch(fullPayment({ id, amount: orderItem?.amount }));
    setOpen(false);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {loading && <Spinner />}
      <DialogTrigger asChild>
        <Button className="rounded-[3px] text-[11px]">Make Full Payment</Button>
      </DialogTrigger>
      {GRN ? (
        <DialogContent className="sm:max-w-[512px]">
          <DialogHeader>
            <div className="mb-3">
              <CartIcon />
            </div>
            <DialogTitle className="text-[#101828]">{`You are about to make a payment of ₦${orderItem?.amount}`}</DialogTitle>
            <DialogDescription className="text-[#667085]">Please confirm.</DialogDescription>
          </DialogHeader>
          <DialogFooter className="mt-4 flex">
            <DialogClose asChild>
              <Button variant="outline" className="h-11 flex-1 text-xs" type="submit">
                Cancel
              </Button>
            </DialogClose>
            <Button className="h-11 flex-1 text-xs" type="submit" onClick={handleFullPayment}>
              {loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
              Confirm
            </Button>
          </DialogFooter>
        </DialogContent>
      ) : (
        <DialogContent>
          <DialogHeader className="mb-3 flex items-center justify-center">
            <div className="mb-3 ">
              <WarningIcon />
            </div>
            <DialogTitle className="text-[#101828]">Error</DialogTitle>
            <DialogDescription className="text-[#667085]">No GRN attached</DialogDescription>
          </DialogHeader>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default FullPaymentDialog;
