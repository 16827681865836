/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import plusIcon from '@/assets/images/icons/plus-icon.svg';
import MetricCard from '@/components/metricCard/metricCard';
// import { getInboundData } from '../../redux/features/inbound/inboundSlice'
import { buildQueryParams } from '@/shared/buildQueryParams';
import RequestsTable from './requestsTable';
import { getRequests } from '@/redux/features/procurement/procurementSlice';
import { Button } from '@/components/ui/button';

// Initial Query Params object
const userQuery = { skip: 0, limit: 5 };

const ProcurementHome = () => {
  const { totalRequests, totalPending, totalCompleted, totalToBePaid, totalCancelled } = useSelector((state) => state.procurement);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = useRef(0);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabs, setTabs] = useState([
    {
      title: 'All Request',
      active: true,
      status: '',
    },
    {
      title: 'Pending',
      active: false,
      status: 'pending',
    },
    {
      title: 'Completed',
      active: false,
      status: 'completed',
    },
    {
      title: 'Rejected',
      active: false,
      status: 'cancelled',
    },
    {
      title: 'To Be Paid',
      active: false,
      status: 'toBePaid',
    },
  ]);

  const fetchData = useCallback(({ pageSize, pageIndex }) => {
    userQuery.limit = pageSize;
    userQuery.skip = pageIndex * pageSize;

    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current) {
      dispatch(getRequests(buildQueryParams(userQuery)))
        .unwrap()
        .then((result) => {
          setPageCount(Math.ceil(result.data.totalDocumentCount / pageSize));
        });
    }
  }, []);
  const setActiveTab = (selectedTab, index) => {
    setActiveTabIndex(index);
    setTabs(
      tabs.map((tab) => {
        tab.active = tab.title === selectedTab.title;
        return tab;
      }),
    );
    if (index === 0) {
      delete userQuery.status;
    } else {
      userQuery.status = selectedTab.status;
    }

    fetchData({ pageSize: 5, pageIndex: 0 });
  };

  // FetchData function for Paginated React Table

  return (
    <>
      {/* <button>Click</button> */}
      <div className="mb-8 flex flex-col justify-between gap-3 lg:flex lg:flex-row lg:gap-0">
        <h1 className="text-2xl font-semibold text-title-color">Procurement</h1>
        <button type="button" onClick={() => navigate('new')} className="flex w-fit items-center whitespace-nowrap rounded bg-[#64B548] px-9 py-3 text-[13px] font-medium text-white disabled:cursor-not-allowed disabled:opacity-80">
          <span className="mr-[10px]">
            <img className="h-3 w-3" src={plusIcon} alt="plus icon" />
          </span>
          New Request
        </button>
      </div>
      <div className="mb-5 grid grid-cols-1 justify-between gap-8 md:grid-cols-2 lg:grid-cols-3">
        <MetricCard title="Total Request" leftUnit="" leftValue={totalRequests} />
        <MetricCard title="Total Pending" leftUnit="" leftValue={totalPending} />
        <MetricCard title="Total Completed" leftUnit="" leftValue={totalCompleted} />
        <MetricCard title="Total Cancelled" leftUnit="" leftValue={totalToBePaid} />
        <MetricCard title="Total To Be Paid" leftUnit="" leftValue={totalCancelled} />
      </div>
      <div className="mt-6 flex gap-4 overflow-auto">
        {tabs.map((tab, index) => (
          <Button key={uuid()} variant={tab.active ? 'default' : 'outline'} className="rounded" size="sm" onClick={() => setActiveTab(tab, index)}>
            {tab.title}
          </Button>
        ))}
      </div>
      <RequestsTable fetchData={fetchData} pageCount={pageCount} tableHeading={tabs[activeTabIndex].title} />
    </>
  );
};

export default ProcurementHome;
