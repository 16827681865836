/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import { AreaChart, Area, XAxis, CartesianGrid, Tooltip, ResponsiveContainer, YAxis } from 'recharts';

import arrowTopRight from '@/assets/images/icons/arrow-top-right.svg';
import infoSign from '@/assets/images/icons/info-sign.svg';
import trendingUp from '@/assets/images/icons/trending-up.svg';

const MetricCard = ({ title, gradient, leftUnit, rightUnit, leftValue, rightValue, data = null }) => {
  const strokeColorMatcher = { green: '#9CD326', purple: '#784BD1', yellow: '#FFCB00' };

  const XAxisDataKey = (title) => {
    switch (title) {
      case 'Inbound':
      case 'Outbound':
      case 'Total Stock':
      case 'Total Balance':
      case 'Opening Balance':
      case 'Closing Balance':
        return 'date';
      default:
        return 'date';
    }
  };

  const YAxisDataKey = (title) => {
    switch (title) {
      case 'Inbound':
      case 'Outbound':
        return 'weight';
      case 'Total Stock':
        return 'totalStock';
      case 'Total Balance':
        return 'totalBalance';
      case 'Opening Balance':
        return 'openingBalance';
      case 'Closing Balance':
        return 'closingBalance';
      default:
        return 'weight';
    }
  };

  return (
    <div className="rounded-md bg-white px-5 pb-1 pt-5">
      <div className="mb-5 flex items-center gap-3 text-lg font-medium text-title-color">
        <p className="whitespace-nowrap">{title}</p>
        <img src={infoSign} alt="info sign" />
        <div className="grow border-[0.4px] border-[#7D7D7D]">{/* <img className="w-full" src={line} alt="line" /> */}</div>
        <img src={arrowTopRight} alt="arrow top right" />
      </div>
      {/* {data ? <p>Data</p> : null} */}
      <div className="mb-7 flex items-center justify-between">
        <div>
          {leftUnit === 'naira' && <span className="ml-1  font-semibold tracking-wide text-[#383838] xl:text-2xl">&#8358;</span>}
          <span className="lg:text-md font-semibold text-[#222222] xl:text-2xl">{leftValue?.toLocaleString()}</span>
          {leftUnit !== 'naira' && <sub className="ml-1 text-base font-semibold tracking-wide text-[#383838]">{leftUnit}</sub>}
        </div>
        <div>
          {rightUnit === 'naira' && <sup className="ml-1 mr-1 text-[17px] font-semibold tracking-wide text-[#383838]">&#8358;</sup>}
          <span className="lg:text-md font-semibold text-[#222222] xl:text-2xl">{rightValue?.toLocaleString()}</span>
          {rightUnit !== 'naira' && <sub className="ml-1 text-base font-semibold tracking-wide text-[#383838]">{rightUnit}</sub>}
        </div>
        <div className="hidden items-center gap-1">
          <span className="flex items-center gap-1 rounded bg-[#E9FBF4] px-[5px] py-[5px] text-xs font-medium text-[#20AC76]">
            <img src={trendingUp} alt="trending up" />
            50%
          </span>
          <span className="text-[10.5px]">from June</span>
        </div>
      </div>
      {data ? (
        data.length ? (
          <ResponsiveContainer width="100%" aspect={2.5 / 1}>
            <AreaChart width={730} height={250} data={data} margin={{ top: 0, right: 0, left: -20, bottom: 0 }}>
              <defs>
                <linearGradient id="greenUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#9CD326" stopOpacity={0.3} />
                  <stop offset="95%" stopColor="#9CD326" stopOpacity={0.07} />
                </linearGradient>
                <linearGradient id="purpleUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#784BD1" stopOpacity={0.3} />
                  <stop offset="95%" stopColor="#784BD1" stopOpacity={0.07} />
                </linearGradient>
                <linearGradient id="yellowUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#FFCB00" stopOpacity={0.3} />
                  <stop offset="95%" stopColor="#FFCB00" stopOpacity={0.07} />
                </linearGradient>
              </defs>
              <XAxis dataKey={XAxisDataKey(title)} tick={{ fill: '#76767B', fontSize: 10 }} />
              <YAxis tick={{ fill: '#76767B', fontSize: 10 }} />
              <CartesianGrid vertical={false} />
              <Tooltip isAnimationActive={false} />
              <Area type="monotone" dataKey={YAxisDataKey(title)} stroke={strokeColorMatcher[gradient]} strokeWidth={2} fillOpacity={1} fill={`url(#${gradient}Uv)`} />
            </AreaChart>
          </ResponsiveContainer>
        ) : (
          <div className="flex h-20 w-full items-center justify-center">No records</div>
        )
      ) : null}
    </div>
  );
};

export default MetricCard;
