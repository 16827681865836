import { format } from 'date-fns';
import { useMemo } from 'react';
// import { StatusPill } from '@/components/table/appTable';

export default function useAuditLogTableColumns() {
  const columns = useMemo(
    () => [
      {
        Header: 'Action',
        accessor: 'action',
        Cell: ({ value }) => (
          <div className="flex w-fit flex-col gap-1">
            <span className="text-right text-xs capitalize text-[#7D7D7D]">{value}</span>
          </div>
        ),
      },
      {
        Header: 'Article',
        accessor: 'article',
        Cell: ({ value }) => (
          <div className="flex w-fit flex-col gap-1">
            <span className="text-right text-xs text-[#7D7D7D]">{value}</span>
          </div>
        ),
      },
      {
        Header: 'Item',
        accessor: 'item',
        Cell: ({ value }) => (
          <div className="flex w-fit flex-col gap-1">
            <span className="text-right text-xs capitalize text-[#7D7D7D]">{value}</span>
          </div>
        ),
      },
      {
        Header: 'User',
        accessor: 'userName',
        Cell: ({ value }) => (
          <div className="flex w-fit flex-col gap-1">
            <span className="text-right text-xs text-[#7D7D7D]">{value}</span>
          </div>
        ),
      },
      {
        Header: 'User Email',
        accessor: 'user',
        Cell: ({ value }) => (
          <div className="flex w-fit flex-col gap-1">
            <span className="text-right text-xs text-[#7D7D7D]">{value.email}</span>
          </div>
        ),
      },
      {
        Header: 'Date Created',
        accessor: 'date',
        Cell: ({ value }) => (
          <div className="flex w-fit flex-col gap-1">
            <span>{format(new Date(value), 'dd MMM, yyyy')}</span>
            <span className="text-right text-xs text-[#7D7D7D]">{format(new Date(value), 'hh:mm aa')}</span>
          </div>
        ),
      },
    ],
    [],
  );

  return columns;
}
