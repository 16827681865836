/* eslint-disable no-unused-vars */
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import toast from 'react-hot-toast';
import FormikControl from '@/components/formik/formikControl';
import Spinner from '@/components/Spinner';
import FormTemplate from '@/components/templates/formTemplate';
import { createUser, editUser, getUser, getUserRoles, viewPassword } from '@/redux/features/user/userSlice';
import { roles } from '@/shared/roles';
import { getAllRegions } from '@/redux/features/settings/regionsAndZonesSettings/regionSlice';
import Eye from '../pages/icons/eye';
import EyeSlash from '../pages/icons/eyeSlash';

const UserForm = () => {
  const [isSelectFocused, setIsSelectFocused] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  const { createUserLoading, getUserRolesLoading, viewPasswordLoading } = useSelector((state) => state.user);

  const genderOptions = [
    { label: 'Select Gender', value: '', isDisabled: true },
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
  ];

  const roleOptions = [{ label: 'Select Role', value: '', isDisabled: true }, ...roles];
  const [showPassword, setShowPassword] = useState(false);
  const [viewPasswordError, setViewPasswordError] = useState(null);
  const [roles_, setRoles_] = useState([]);
  const [regions, setRegions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([
    { label: 'Set Status', value: '', isDisabled: true },
    { label: isSelectFocused ? 'Activate' : 'Activated', value: true },
    { label: isSelectFocused ? 'Deactivate' : 'Deactivated', value: false },
  ]);
  const [initialValues, setInitialValues] = useState({
    first_name: '',
    last_name: '',
    middle_name: '',
    email: '',
    phone_number: '',
    password: '',
    gender: '',
    role: '',
    region: '',
    isActive: true,
  });

  const validationSchema = Yup.object({
    first_name: Yup.string().required('Required'),
    last_name: Yup.string().required('Required'),
    // middle_name: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email format').required('Required'),
    phone_number: Yup.string().required('Required'),
    password: params.id ? Yup.string() : Yup.string().required('Required'),
    gender: Yup.string().required('Required'),
    role: Yup.string().required('Required'),
    region: !params.id ? Yup.string().required('Required') : Yup.string(),
  });

  useEffect(() => {
    // Get user roles
    dispatch(getUserRoles())
      .unwrap()
      .then((result) => {
        const options = [];
        result.data.forEach((value) => {
          options.push({
            label: value.name,
            value: value._id,
          });
        });
        setRoles_([{ label: 'Select Role', value: '', isDisabled: true }, ...options]);
      });
    // Get all regions
    dispatch(getAllRegions())
      .unwrap()
      .then((result) => {
        const options = [];
        result.data.data.forEach((value) => {
          options.push({
            label: value.label,
            value: value._id,
          });
        });
        setRegions([{ label: 'Select Region', value: '', isDisabled: true }, ...options]);
      });

    // activate & deactivate
    setStatusOptions(statusOptions);
  }, []);

  useEffect(() => {
    if (params.id) {
      dispatch(getUser(params.id))
        .unwrap()
        .then(({ data }) => {
          setInitialValues({
            first_name: data?.first_name,
            last_name: data?.last_name,
            middle_name: data?.middle_name || '',
            email: data?.email,
            phone_number: data?.phone_number,
            gender: data?.gender,
            role: data?.role?._id,
            isActive: data.isActive,
          });
          // Set the users password
          dispatch(viewPassword(params.id))
            .unwrap()
            .then((res) => {
              if (res?.data?.password) {
                setInitialValues((prevValues) => ({
                  ...prevValues,
                  password: res.data.password,
                }));
              } else {
                setViewPasswordError(res.message);
              }
            });
        });
    }
  }, []);

  const onSubmit = (values, submitProps) => {
    if (params.id) {
      dispatch(editUser({ id: params.id, ..._.omit(values, ['region', 'password']) }))
        .unwrap()
        .then(() => {
          submitProps.resetForm();
        })
        .catch(() => {
          submitProps.setSubmitting(false);
        });
    } else {
      dispatch(createUser(values))
        .unwrap()
        .then(() => {
          submitProps.resetForm();
        })
        .catch(() => {
          submitProps.setSubmitting(false);
        });
    }
  };

  const loading = createUserLoading || getUserRolesLoading || viewPasswordLoading;

  return (
    <FormTemplate initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} title={`${params.id ? 'Edit User' : 'New User'}`} submitBtnText={`${params.id ? 'Edit User' : 'Save User'}`} enableReinitialize>
      {loading && <Spinner />}
      <div>
        <FormikControl control="input" type="text" label="First Name" name="first_name" placeholder="First Name" />
      </div>
      <div>
        <FormikControl control="input" type="text" label="Last Name" name="last_name" placeholder="Last Name" />
      </div>
      <div>
        <FormikControl control="input" type="text" label="Middle Name" name="middle_name" placeholder="Middle Name" />
      </div>
      <div>
        <FormikControl control="input" type="email" label="Email Address" name="email" placeholder="Email Address" />
      </div>
      <div>
        <FormikControl control="input" type="text" label="Phone Number" name="phone_number" placeholder="Phone Number" />
      </div>
      <div>
        <FormikControl
          control="input"
          type={`${showPassword ? 'text' : 'password'}`}
          label="Password"
          name="password"
          placeholder="Password"
          disabled={!!params.id}
          toggleViewPasswordIcon={
            <button
              type="button"
              onClick={() => {
                if (viewPasswordError) {
                  toast.error(viewPasswordError);
                } else {
                  setShowPassword(!showPassword);
                }
              }}
            >
              {showPassword ? <Eye /> : <EyeSlash />}
            </button>
          }
        />
      </div>

      <div>
        <FormikControl control="select" label="Gender" name="gender" options={genderOptions} />
      </div>
      <div>
        <FormikControl control="select" label="Role" name="role" options={roles_} />
      </div>
      {!params.id && (
        <div>
          <FormikControl control="select" label="Region" name="region" options={regions} />
        </div>
      )}
      {params.id && (
        // <div>
        //   <FormikControl control="select" label="Status" name="isActive" options={statusOptions} onFocus={() => setIsSelectFocused(true)} onBlur={() => setIsSelectFocused(false)} />
        // </div>

        <FormikControl
          control="select"
          // label="Status"
          name="isActive"
          options={statusOptions}
          onFocus={() => {
            setIsSelectFocused(true);
            setStatusOptions([
              { label: 'Activate', value: true },
              { label: 'Deactivate', value: false },
            ]);
          }}
          onBlur={() => {
            setIsSelectFocused(false);
            setStatusOptions([
              { label: 'Activated', value: true },
              { label: 'Deactivate', value: false },
            ]);
          }}
        />
      )}
    </FormTemplate>
  );
};

export default UserForm;
