import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import FormikControl from '@/components/formik/formikControl';
import plusIcon from '@/assets/images/icons/plus-icon.svg';
import HiddenElement from '@/components/formik/hiddenElement';
import Spinner from '@/components/Spinner';
import { addItem } from '@/redux/features/settings/outboundSettings/outboundItemSlice';
import { getAllUnits } from '@/redux/features/settings/outboundSettings/outboundUnitSlice';
import { getAllCategories } from '@/redux/features/settings/outboundSettings/outboundCategorySlice';

const AddItemsForm = ({ fetchData, initialPageSize }) => {
  const dispatch = useDispatch();
  const { getAllUnitsLoading, getAllCategoriesLoading, addItemLoading } = useSelector((state) => state.outboundItem);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);

  const initialValues = {
    name: '',
    category: '',
    unit: '',
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    category: Yup.string().required('Required'),
    unit: Yup.string().required('Required'),
  });

  const onSubmit = (values, submitProps) => {
    dispatch(addItem({ ...values, bound: 'outbound' }))
      .unwrap()
      .then(() => {
        fetchData({ pageSize: initialPageSize, pageIndex: 0 });
      });
    submitProps.resetForm();
  };

  useEffect(() => {
    dispatch(getAllUnits())
      .unwrap()
      .then((result) => {
        const options = [];
        result.data.data.forEach((value) => {
          options.push({
            label: value.name,
            value: value._id,
          });
        });
        setUnitOptions([{ label: 'Select Units', value: '', isDisabled: true }, ...options]);
      });
    dispatch(getAllCategories())
      .unwrap()
      .then((result) => {
        const options = [];
        result.data.data.forEach((value) => {
          options.push({
            label: value.name,
            value: value._id,
          });
        });
        setCategoryOptions([{ label: 'Select Categories', value: '', isDisabled: true }, ...options]);
      });
  }, []);

  const loading = getAllUnitsLoading || getAllCategoriesLoading || addItemLoading;

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {(formik) => {
        return (
          <Form className="relative rounded-t-lg bg-white px-8 pb-7 pt-8">
            {loading && <Spinner />}
            <div className="flex items-end gap-7">
              <div className="form-group">
                <FormikControl control="input" type="text" label="Add Items" name="name" placeholder="Category Name" />
              </div>
              <div className="w-[290px]">
                <FormikControl control="select" label="Category" name="category" options={categoryOptions} />
              </div>
              <div className="w-[290px]">
                <FormikControl control="select" label="Units" name="unit" options={unitOptions} />
              </div>
              <div>
                <label htmlFor="submit-btn" className="form-control-label pointer-events-none opacity-0">
                  Button
                </label>
                <button className="submit-button" type="submit" disabled={!formik.isValid || formik.isSubmitting}>
                  <span className="mr-[10px]">
                    <img src={plusIcon} alt="plus icon" />
                  </span>
                  Add Item
                </button>
                <HiddenElement />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddItemsForm;
