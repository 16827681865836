import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '@/services/instance';
import { pendingHandler, rejectionHandler, successHandler } from '@/shared/requestHandlers';

export const createReceiver = createAsyncThunk('logisticsSettings/createReceiver', async (postData) => {
  const { data } = await instance.post('/receivers', postData);
  return data;
});

export const editReceiver = createAsyncThunk('logisticsSettings/editReceiver', async (patchData) => {
  const { data } = await instance.patch('/receivers', patchData);
  return data;
});

export const deleteReceiver = createAsyncThunk('logisticsSettings/deleteReceiver', async (id) => {
  const { data } = await instance.delete(`/receivers?_id=${id}`);
  return data;
});

export const getReceiver = createAsyncThunk('logisticsSettings/getReceiver', async (id) => {
  const { data } = await instance.get(`/receivers/getOne?_id=${id}`);
  return data;
});

export const getReceivers = createAsyncThunk('outboundSettings/getReceivers', async (queryParams) => {
  const { data } = await instance.get(`/receivers${queryParams}`);
  return data;
});

export const getAllReceivers = createAsyncThunk('outboundSettings/getAllReceivers', async () => {
  const { data } = await instance.get('/receivers');
  return data;
});

const initialState = {
  receivers: [],
  allReceivers: [],
  createReceiverLoading: false,
  getReceiversLoading: false,
  getReceiverLoading: false,
  editReceiverLoading: false,
  deleteReceiverLoading: false,
  getAllReceiversLoading: false,
  error: '',
};

const outboundLogisticsSlice = createSlice({
  name: 'logisticsSettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createReceiver.pending, (state) => {
        pendingHandler(state, 'createReceiverLoading');
      })
      .addCase(createReceiver.fulfilled, (state, { payload: { data } }) => {
        state.receivers.push(data);
        successHandler(state, 'createReceiverLoading', 'Receiver created');
      })
      .addCase(createReceiver.rejected, (state, action) => {
        rejectionHandler(state, action, 'createReceiverLoading');
      })
      .addCase(getReceivers.pending, (state) => {
        pendingHandler(state, 'getReceiversLoading');
      })
      .addCase(getReceivers.fulfilled, (state, { payload: { data } }) => {
        state.receivers = data.data;
        successHandler(state, 'getReceiversLoading');
      })
      .addCase(getReceivers.rejected, (state, action) => {
        rejectionHandler(state, action, 'getReceiversLoading');
      })
      .addCase(getAllReceivers.pending, (state) => {
        pendingHandler(state, 'getAllReceiversLoading');
      })
      .addCase(getAllReceivers.fulfilled, (state, { payload: { data } }) => {
        state.allReceivers = data.data;
        successHandler(state, 'getAllReceiversLoading');
      })
      .addCase(getAllReceivers.rejected, (state, action) => {
        rejectionHandler(state, action, 'getAllReceiversLoading');
      })
      .addCase(deleteReceiver.pending, (state) => {
        pendingHandler(state, 'deleteReceiverLoading');
      })
      .addCase(deleteReceiver.fulfilled, (state) => {
        successHandler(state, 'deleteReceiverLoading', 'Receiver deleted');
      })
      .addCase(deleteReceiver.rejected, (state, action) => {
        rejectionHandler(state, action, 'deleteReceiverLoading');
      })
      .addCase(getReceiver.pending, (state) => {
        pendingHandler(state, 'getReceiverLoading');
      })
      .addCase(getReceiver.fulfilled, (state) => {
        successHandler(state, 'getReceiverLoading');
      })
      .addCase(getReceiver.rejected, (state, action) => {
        rejectionHandler(state, action, 'getReceiverLoading');
      })
      .addCase(editReceiver.pending, (state) => {
        pendingHandler(state, 'editReceiverLoading');
      })
      .addCase(editReceiver.fulfilled, (state) => {
        successHandler(state, 'editReceiverLoading', 'Receiver updated');
      })
      .addCase(editReceiver.rejected, (state, action) => {
        rejectionHandler(state, action, 'editReceiverLoading');
      });
  },
});

export default outboundLogisticsSlice.reducer;
