/* eslint-disable no-unused-vars */
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ActionsHeader, Ellipsis, RequestStatusPill, ActionsPopover } from '@/components/table/appTable';
import { Badge } from '@/components/ui/badge';

const statuses = ['pending', 'rejected', 'toBePaid', 'completed'];

export default function useCompletedOrdersTable() {
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'orderId',
        Cell: ({ value }) => (!value ? <span>-</span> : <span className="inline-block max-w-[60px] truncate align-middle">{value}</span>),
      },
      {
        Header: 'Product Name',
        accessor: 'product.name',
        Cell: ({ value }) => (!value ? <span>-</span> : <span>{value}</span>),
      },
      {
        Header: 'Product Type',
        accessor: 'product.category.name',
        Cell: ({ value }) => (!value ? <span>-</span> : <span>{value}</span>),
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
        Cell: ({ value }) => (!value ? <span>-</span> : <span>{value}</span>),
      },
      {
        Header: 'Total Weight (Kg)',
        accessor: 'weight',
        Cell: ({ value }) => (!value ? <span>-</span> : <span>{value}</span>),
      },
      {
        Header: 'Order Type',
        accessor: 'type',
        Cell: ({ value }) => <Badge variant="secondary">{value}</Badge>,
      },

      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => <Badge variant={value === 'completed' ? 'primary' : 'destructive'}>{value === 'completed' ? 'Completed' : 'Pending'}</Badge>,
      },
      {
        Header: ActionsHeader,
        accessor: '_id',
        Cell: ({ value, row }) => (
          <ActionsPopover
            actions={[
              {
                text: 'View',
                onClickAction: () => navigate(`completed-order/${value}/${row.original.orderId}`),
              },
            ]}
          />
        ),
      },
    ],
    [navigate],
  );

  return columns;
}
