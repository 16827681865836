/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import AuthPageLayout from '@/layout/authPageLayout';
import { loginUser } from '@/redux/features/user/userSlice';
import AuthInput from './shared/authInput';
import * as authService from '@/services/authService';
import Eye from './icons/eye';
import EyeSlash from './icons/eyeSlash';
import Button from './shared/authButton';
import loader from '@/assets/images/loader.gif';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const [userDetails, setUserDetails] = useState({
    username: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState(false);

  const redirectPath = location.state?.path || '/';

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({ ...userDetails, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      loginUser({
        phone_number: userDetails.username,
        password: userDetails.password,
      }),
    );
  };

  if (authService.getToken()) {
    return <Navigate to={redirectPath} replace />;
  }

  if (localStorage.getItem('customerId') === null) {
    return <Navigate to="/orgid" replace />;
  }

  const disabledButtonCondition = user.loginUserLoading || !userDetails.username || !userDetails.password;

  return (
    <AuthPageLayout title="Welcome back" subTitle="Please enter your account details">
      <form autoComplete="off" onSubmit={handleSubmit}>
        <div className="mt-5">
          <label htmlFor="username" className="mb-2 text-base text-[#383838]">
            Email Address/Phone Number
          </label>
          <AuthInput type="text" name="username" id="username" onChange={handleChange} value={userDetails.username} required placeholder="Enter email address/phone number" />
        </div>
        <div className="mt-5">
          <label htmlFor="password" className="mb-2 text-base text-[#383838]">
            Password
          </label>
          <div className="relative">
            <AuthInput type={`${showPassword ? 'text' : 'password'}`} name="password" id="password" placeholder="Enter password" onChange={handleChange} value={userDetails.password} required />
            <button type="button" onClick={() => setShowPassword(!showPassword)} className="absolute right-6 top-1/2 translate-y-[-50%] cursor-pointer">
              {showPassword ? <Eye /> : <EyeSlash />}
            </button>
          </div>
        </div>
        <div className="mt-6 flex justify-end text-light-green">
          <button type="button" onClick={() => navigate('/forgot-password')} className="cursor-pointer">
            Forgot password
          </button>
        </div>
        <Button type="submit" btnDisabled={disabledButtonCondition}>
          {user.loginUserLoading ? <img className="mx-auto w-7" src={loader} alt="loader" /> : 'LOGIN'}
        </Button>
      </form>
    </AuthPageLayout>
  );
};

export default Login;
