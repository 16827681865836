import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import _ from 'lodash';
import FormikControl from '@/components/formik/formikControl';
import Spinner from '@/components/Spinner';
import FormTemplate from '@/components/templates/formTemplate';
import { editStore, getStoreManagers, getWarehouse } from '@/redux/features/warehouse/warehouseSlice';
import { getAllRegions } from '@/redux/features/settings/regionsAndZonesSettings/regionSlice';

const EditStore = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { editStoreLoading, getWarehouseLoading } = useSelector((state) => state.warehouse);
  const [initialValues, setInitialValues] = useState({
    name: '',
    id: '',
    address: '',
    state: '',
    lga: '',
    manager: '',
    region: '',
  });
  const [storeManagerOptions, setStoreManagerOptions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);

  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    id: Yup.string().required('Required'),
    address: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    lga: Yup.string().required('Required'),
    manager: Yup.string(),
    region: Yup.string(),
  });

  const onSubmit = (values) => {
    dispatch(
      editStore({
        _id: params.id,
        store: { ..._.omit(values, ['manager']) },
        manager: values.manager,
      }),
    )
      .unwrap()
      .then(() => {
        navigate(-1);
      });
  };

  useEffect(() => {
    dispatch(getStoreManagers())
      .unwrap()
      .then((result) => {
        // console.log(result.data)
        const options = [];
        result.data.data.forEach((value) => {
          options.push({
            label: value.first_name + ' ' + value.last_name,
            value: value._id,
          });
        });
        setStoreManagerOptions([{ label: 'Select Store Manager', value: '', isDisabled: true }, ...options]);
      });
  }, []);

  useEffect(() => {
    dispatch(getWarehouse(params.id))
      .unwrap()
      .then((response) => {
        setInitialValues({
          name: response?.data?.store?.name,
          id: response?.data?.store?.id,
          address: response?.data?.store?.address,
          state: response?.data?.store?.state,
          lga: response?.data?.store?.lga,
          manager: response?.data?.manager?._id,
          region: response?.data?.store?.region,
        });
      });
  }, []);

  useEffect(() => {
    dispatch(getAllRegions())
      .unwrap()
      .then((result) => {
        const options = [];
        result.data.data.forEach((value) => {
          options.push({
            label: value.label,
            value: value._id,
          });
        });
        setRegionOptions([{ label: 'Select Regions', value: '-', isDisabled: true }, ...options]);
      });
  }, []);

  const loading = editStoreLoading || getWarehouseLoading;

  return (
    <>
      <h1 className="mb-8 text-2xl font-semibold text-title-color">{initialValues.name}</h1>
      <FormTemplate initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} title="Edit Store" submitBtnText="Save Changes" enableReinitialize>
        {loading && <Spinner />}
        <div>
          <FormikControl control="input" type="text" label="Store Name" name="name" placeholder="Store Name" />
        </div>
        <div>
          <FormikControl control="input" type="text" label="Store ID" name="id" placeholder="Store ID" />
        </div>
        <div>
          <FormikControl control="input" type="text" label="Address" name="address" placeholder="Address" />
        </div>
        <div>
          <FormikControl control="input" type="text" label="Store State" name="state" placeholder="Store State" />
        </div>
        <div>
          <FormikControl control="input" type="text" label="Store LGA" name="lga" placeholder="Store LGA" />
        </div>
        <div>
          <FormikControl control="select" label="Store Manager" name="manager" options={storeManagerOptions} />
        </div>
        <div>
          <FormikControl control="select" label="Region" name="region" options={regionOptions} />
        </div>
      </FormTemplate>
    </>
  );
};

export default EditStore;
