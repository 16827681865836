/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useRef, useState } from 'react';
import AdminTable from './adminTable';
import plusIcon from '@/assets/images/icons/plus-icon.svg';
import { getAllUsers } from '@/redux/features/user/userSlice';
import { buildQueryParams } from '@/shared/buildQueryParams';

// Initial Query Params object
const userQuery = { skip: 0, limit: 5 };

const AdminHome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getAllUsersLoading, allUsers } = useSelector((state) => state.user);

  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = useRef(0);

  // FetchData function for Paginated React Table
  const fetchData = useCallback(({ pageSize, pageIndex }) => {
    userQuery.limit = pageSize;
    userQuery.skip = pageIndex * pageSize;

    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current) {
      dispatch(getAllUsers(buildQueryParams(userQuery)))
        .unwrap()
        .then((result) => {
          setPageCount(Math.ceil(result.data.totalDocuments / pageSize));
        });
    }
  }, []);

  return (
    <>
      <div className="flex justify-between">
        <h1 className="mb-8 text-2xl font-semibold text-title-color">Admin</h1>
        <button type="button" onClick={() => navigate('user/create')} className="flex h-12 items-center gap-[10px] rounded bg-[#64B548] px-4 text-[13px] font-medium text-white hover:opacity-90">
          <img src={plusIcon} alt="plus icon" />
          <span>Create User</span>
        </button>
      </div>
      <AdminTable fetchData={fetchData} pageCount={pageCount} />
    </>
  );
};

export default AdminHome;
