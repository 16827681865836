/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { CheckBadgeIcon } from '@heroicons/react/24/solid';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { addEvents } from '@/redux/features/procurement/procurementSlice';
import loader from '@/assets/images/loader.gif';

const ApproveRequest = ({ id, setShow }) => {
  const dispatch = useDispatch();
  const { addEventsLoading } = useSelector((state) => state.procurement);
  const [comment, setComment] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      addEvents({
        id,
        payload: {
          event: 'approved',
          invoice: '',
          comment,
        },
      }),
    )
      .unwrap()
      .then((result) => {
        if (result.status === 'success') {
          toast.success('Request approved');
          setShow(false);
        }
      });
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ type: 'tween', duration: 0.2 }}
      exit={{
        opacity: 0,
        scale: 0,
        transition: { ease: 'easeInOut', duration: 0.2 },
      }}
      className="w-[90%] max-w-[550px] rounded-xl bg-white px-6 py-6"
    >
      <div>
        <CheckBadgeIcon className="h-10 w-10 text-green" aria-hidden="true" />
      </div>
      <h3 className="mb-[6px] mt-4 text-lg font-semibold text-[#101828]">You are about to approve this request!</h3>
      <p className="mb-5 text-sm text-[#667085]">Please enter reason for approval.</p>
      <form onSubmit={handleSubmit}>
        <label className="mb-2 block text-sm font-medium text-[#222222]" htmlFor="comment">
          Comment
        </label>
        <textarea value={comment} onChange={(e) => setComment(e.target.value)} className="form-control-textarea mb-7" name="comment" id="comment" rows="5" placeholder="Enter comment" />
        <div className="flex items-center justify-between gap-3">
          <button className="w-full rounded border border-[#C3C3C3] py-3 text-xs text-[#646464]" type="button" onClick={() => setShow(false)}>
            Cancel
          </button>
          <button disabled={!comment.length || addEventsLoading} type="submit" className="w-full rounded border border-transparent bg-[#64B548] py-3 text-xs text-white disabled:cursor-not-allowed disabled:opacity-80">
            {addEventsLoading ? <img className="mx-auto w-4" src={loader} alt="loader" /> : 'Confirm'}
          </button>
        </div>
      </form>
    </motion.div>
  );
};

export default ApproveRequest;
