/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-useless-fragment */
import { useRef } from 'react';

const Backdrop = ({ children, show, setShow, handleClose, classNames }) => {
  const bgRef = useRef();

  const closeModal = (e) => {
    if (e.target === bgRef.current) {
      setShow(false);
    }
    if (handleClose) {
      handleClose();
    }
  };

  return (
    <>
      {show && (
        <div ref={bgRef} onClick={closeModal} className={`fixed left-0 top-0 z-10 h-screen w-screen bg-black bg-opacity-[0.3] ${classNames}`}>
          {children}
        </div>
      )}
    </>
  );
};

export default Backdrop;
