import { forwardRef } from 'react';
import * as PopoverPrimitive from '@radix-ui/react-popover';

export const Popover = PopoverPrimitive.Root;
export const PopoverTrigger = PopoverPrimitive.Trigger;

export const PopoverContent = forwardRef(({ children, extraClassNames, ...props }, forwardedRef) => (
  <PopoverPrimitive.Portal>
    <PopoverPrimitive.Content className={`PopoverContent rounded bg-white shadow-lg ${extraClassNames}`} sideOffset={5} {...props} ref={forwardedRef}>
      {children}
      {/* <PopoverPrimitive.Arrow /> */}
    </PopoverPrimitive.Content>
  </PopoverPrimitive.Portal>
));
