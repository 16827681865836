import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { pendingHandler, rejectionHandler, successHandler } from '@/shared/requestHandlers';
import instance from '@/services/instance';

export const getAllUnits = createAsyncThunk('settings/getAllUnits', async () => {
  const { data } = await instance.get('/units');
  return data;
});

export const getAllItems = createAsyncThunk('settings/getAllItems', async () => {
  const { data } = await instance.get('/itemTypes');
  return data;
});

const initialState = {
  allUnits: [],
  getAllUnitsLoading: false,
  allItems: [],
  getAllItemsLoading: false,
  error: '',
};

const settingssSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllUnits.pending, (state) => {
        pendingHandler(state, 'getAllUnitsLoading');
      })
      .addCase(getAllUnits.fulfilled, (state, { payload: { data } }) => {
        state.allUnits = data.data;
        successHandler(state, 'getAllUnitsLoading');
      })
      .addCase(getAllUnits.rejected, (state, action) => {
        rejectionHandler(state, action, 'getAllUnitsLoading');
      })
      .addCase(getAllItems.pending, (state) => {
        pendingHandler(state, 'getAllItemsLoading');
      })
      .addCase(getAllItems.fulfilled, (state, { payload: { data } }) => {
        state.allItems = data.data;
        successHandler(state, 'getAllItemsLoading');
      })
      .addCase(getAllItems.rejected, (state, action) => {
        rejectionHandler(state, action, 'getAllItemsLoading');
      });
  },
});

export default settingssSlice.reducer;
