/* eslint-disable no-shadow */
// export const validateSupplier = (input, suppliers) => {
//   const supplier = suppliers.find((supplier) => supplier.name === input)
//   return supplier
//     ? { exists: true, error: null, id: supplier._id }
//     : { exists: false, error: `Supplier "${input}" does not exist` }
// }

export const validationFormat = (input, arrayToCheck, keyToCheck, idKey, entityText) => {
  const entity = arrayToCheck.find((entity) => entity[keyToCheck] === input);
  return entity ? { exists: true, error: null, _id: entity[idKey] } : { exists: false, error: `Cell ${entityText}: "${input}" does not exist` };
};

export const validationFormatNested = (input, arrayToCheck, key1ToCheck, key2ToCheck, idKey, entityText) => {
  const entity = arrayToCheck.find((entity) => entity[key1ToCheck][key2ToCheck] === input);
  return entity ? { exists: true, error: null, _id: entity[idKey] } : { exists: false, error: `Cell ${entityText}: "${input}" does not exist` };
};

export const validateSupplier = (row, allSuppliers, supplierColumnIndex, rowIndex) => {
  const isSupplierValid = validationFormat(row.supplierName, allSuppliers, 'name', '_id', `${String.fromCharCode(supplierColumnIndex + 65)}${rowIndex + 1}`);
  return isSupplierValid;
};

// {
//     "Warehouse": "Maruru",
//     "Item": "Sorghum - 100kg",
//     "Description/Activity": "Harvest",
//     "Supplier Name": "Mubi Store 7(TAG00012) - Comm",
//     "Weight": "50",
//     "Unit of Measurement": "Kilogram",
//     "Total Bags": "2000",
//     "Destination": "Uruguay",
//     "Moisture": "50",
//     "Purity": "100",
//     "Thrive Rep": "Kay",
//     "Brought By": "Cletus",
//     "Driver Name": "Tunde Jinadu",
//     "Phone": "0813665921",
//     "Plate Number": "GH564NND",
//     "Quantity": "20000",
//     "Cost": "150000",
//     "Comment\r": "Cool Stuff\r"
//   },
