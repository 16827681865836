/* eslint-disable no-unused-vars */
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import FormikControl from '@/components/formik/formikControl';
import Spinner from '@/components/Spinner';
import FormTemplate from '@/components/templates/formTemplate';
import { createOrder, getOneOrder, editOrder } from '@/redux/features/orders/ordersSlice';
import { getAllItems } from '@/redux/features/settings/inboundSettings/inboundItemSlice';
import { getAllWarehouses } from '@/redux/features/warehouse/warehouseSlice';
import { StatesInNigeria } from './statesInNigeria';

const SalesOrderForm = () => {
  const [storeOptions, setStoreoptions] = useState([]);
  const [productName, setProductName] = useState([]);
  const [states, setStates] = useState([]);

  const dispatch = useDispatch();
  const params = useParams();
  const { orderItem, itemLoading, createOrderLoading, editOrderLoading } = useSelector((state) => state.orders);
  const { allWarehousesLoading } = useSelector((state) => state.warehouse);
  const { getAllItemsLoading } = useSelector((state) => state.inboundItem);

  // fettch values and populate dropdown
  useEffect(() => {
    dispatch(getAllWarehouses())
      .unwrap()
      .then((result) => {
        const options = [];
        result.data.data.forEach((value) => {
          options.push({
            label: value.store.name,
            value: value._id,
          });
        });
        setStoreoptions([{ label: 'Select Store', value: '', isDisabled: true }, ...options]);
      });

    dispatch(getAllItems())
      .unwrap()
      .then((result) => {
        const options = [];
        result.data.data.forEach((value) => {
          options.push({
            label: value.name,
            value: value._id,
          });
        });
        setProductName([{ label: 'Select Product', value: '', isDisabled: true }, ...options]);
      });

    setStates(StatesInNigeria);
  }, []);

  const [initialValues, setInitialValues] = useState({
    store: '',
    product: '',
    state: '',
    quantity: '',
    weight: '',
    amount: '',
    type: 'salesOrder',
  });

  useEffect(() => {
    if (params.id) {
      dispatch(getOneOrder(params.id));
    }
  }, [dispatch, params.id]);

  useEffect(() => {
    if (params.id) {
      setInitialValues({
        store: orderItem?.store._id,
        product: orderItem?.product._id,
        state: orderItem?.state,
        quantity: orderItem.quantity,
        weight: orderItem.weight,
        amount: orderItem.amount,
        id: params.id,
      });
    }
  }, [params.id]);

  const validationSchema = Yup.object({
    store: Yup.string().required('Required'),
    product: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    quantity: Yup.number('Not a number').positive('Quantity must be greater than zero').required('Required').integer('Must be an integer'),
    weight: Yup.number('Not a number').positive('Weight must be greater than zero').required('Required').integer('Must be an integer'),
    amount: Yup.number('Not a number').positive('Amount must be greater than zero').required('Required').integer('Must be an integer'),
  });

  const onSubmit = (values, submitProps) => {
    if (params.id) {
      dispatch(editOrder(values));
    } else {
      dispatch(createOrder(values));
    }
    submitProps.resetForm();
    setStates('');
  };

  const loading = itemLoading || allWarehousesLoading || getAllItemsLoading || createOrderLoading || editOrderLoading;

  return (
    <FormTemplate
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      title={`${params.id ? 'Edit Sales Order' : 'Create Sales Order'}`}
      submitBtnText={`${params.id ? 'Edit Order' : 'Create Order'}`}
      enableReinitialize
    >
      {loading && <Spinner />}
      <div>
        <FormikControl control="select" type="text" label="Store" name="store" placeholder="" options={storeOptions} />
      </div>
      <div>
        <FormikControl control="select" type="text" label="Product Name" name="product" placeholder="" options={productName} />
      </div>
      <div>
        <FormikControl control="select" type="text" label="State of Delivery" name="state" placeholder="Select State " options={states} />
      </div>
      <div>
        <FormikControl control="input" type="number" label="Quantity" name="quantity" placeholder="50" />
      </div>
      <div>
        <FormikControl control="input" type="number" label="Weight(Kg)" name="weight" placeholder="450" />
      </div>
      <div>
        <FormikControl control="input" type="number" label="Amount" name="amount" placeholder="500" />
      </div>
    </FormTemplate>
  );
};

export default SalesOrderForm;
