/* eslint-disable no-unused-vars */
import React from 'react';
import { Loader2 } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import WarningIcon from '@/components/svgs/WarningIcon';
import { Button } from '@/components/ui/button';
import { partPayment } from '@/redux/features/orders/ordersSlice';
import Spinner from '@/components/Spinner';

const PartPaymentDialog = ({ GRN }) => {
  const dispatch = useDispatch();
  const [amount, setAmount] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  // orderItem
  const { paymentLoading } = useSelector((state) => state.orders);
  const params = useParams();
  const { id } = params;

  const loading = paymentLoading;

  const handlePartPayment = () => {
    dispatch(partPayment({ id, amount }));

    setOpen(false);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {loading && <Spinner />}
      <DialogTrigger asChild>
        <Button variant="outline" className="rounded-[3px] text-[11px]">
          Make Part Payment
        </Button>
      </DialogTrigger>
      {GRN ? (
        <DialogContent className="sm:max-w-[512px]">
          <DialogHeader>
            <div className="mb-3">
              <WarningIcon />
            </div>
            <DialogTitle className="text-[#101828]">You are about to make a part-payment</DialogTitle>
            <DialogDescription className="text-[#667085]">Please enter amount you want to pay.</DialogDescription>
          </DialogHeader>
          <div className="pb-4">
            <div className="items-center gap-4">
              <Label htmlFor="name">Amount</Label>
              <Input id="name" defaultValue="Pedro Duarte" type="number" placeHolder="Enter amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
            </div>
          </div>
          <DialogFooter className="flex">
            <DialogClose asChild>
              <Button variant="outline" className="h-11 flex-1 text-xs" type="submit">
                Cancel
              </Button>
            </DialogClose>
            <Button className="h-11 flex-1 text-xs" type="submit" onClick={handlePartPayment}>
              {loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
              Confirm
            </Button>
          </DialogFooter>
        </DialogContent>
      ) : (
        <Dialog open={open} onOpenChange={setOpen}>
          <DialogContent>
            <DialogHeader className="mb-3 flex items-center justify-center">
              <div className="mb-3">
                <WarningIcon />
              </div>
              <DialogTitle className="text-[#101828]">Error</DialogTitle>
              <DialogDescription className="text-[#667085]">No GRN attached</DialogDescription>
            </DialogHeader>
          </DialogContent>
        </Dialog>
      )}
    </Dialog>
  );
};

export default PartPaymentDialog;
