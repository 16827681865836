/* eslint-disable jsx-a11y/control-has-associated-label */
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import notifIcon from '../assets/images/icons/notif-icon.svg';
import chevronDown from '../assets/images/icons/chevron-down.svg';
// import searchIcon from '../assets/images/icons/search-icon.svg'
import signOut from '../assets/images/icons/sign-out.svg';
import avatar from '../assets/images/avatar.svg';
// import { useNavigate } from 'react-router-dom';
import { logout } from '../redux/features/user/userSlice';

const AppHeader = ({ showSidebar, setShowSidebar }) => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);
  const [showLogout, setShowLogout] = useState(false);
  const ref = useRef(null);

  const hideOnClickOutside = (e) => {
    if (!ref.current) return;
    const cur = ref.current;
    const node = e.target;
    if (cur.contains(node)) return;
    setShowLogout(false);
  };

  useEffect(() => {
    setUserInfo(JSON.parse(localStorage.getItem('user')));

    document.addEventListener('click', hideOnClickOutside);

    return () => document.removeEventListener('click', hideOnClickOutside);
  }, []);

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div className="sticky top-0 z-[5] flex h-[70px] items-center justify-end bg-white px-6 lg:px-14">
      <button type="button" className="absolute left-[20px] cursor-pointer md:hidden" onClick={() => setShowSidebar(!showSidebar)}>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2} onClick={() => setShowSidebar(!showSidebar)}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </button>
      {/* <div className="relative hidden sm:block ml-8 md:ml-0">
        <input
          className="bg-white focus:outline-none text-[13px] h-[32px] w-[25vw] min-w-[300px] px-7 rounded form-input border border-[#C3C3C3] placeholder-[#7D7D7D]"
          type="search"
          name="search"
          id="search"
          placeholder="Search"
        />
        <img
          className="absolute left-[10px] top-[50%] translate-y-[-50%] w-[12px]"
          src={searchIcon}
          alt="Search icon"
        />
      </div> */}
      <div className="relative flex items-center">
        <div className="flex items-center gap-4">
          <div className="h-fit w-fit rounded-md bg-green px-3 py-[0.5px] text-sm font-medium text-white">{userInfo?.role?.name}</div>
          <div className="relative mr-[44px] w-fit">
            <img className="w-[18px]" src={notifIcon} alt="Notification Icon" />
            {/* <img
							className='absolute top-[-3px] right-[-5px]'
							src={notifSign}
							alt='Notification Sign'
						/> */}
          </div>
        </div>
        <div className="flex items-center">
          <img className="mr-2 h-10 w-10 rounded-full" src={avatar} alt="avatar" />
          {/* <p className="text-xs text-[#1F384C]">{userInfo && userInfo?.message.split(' ')[2]}</p> */}
          <div ref={ref} className="relative">
            <button type="button" onClick={() => setShowLogout(!showLogout)}>
              <img className="ml-2 w-[10px] cursor-pointer" src={chevronDown} alt="chevron down" />
            </button>
            <AnimatePresence>
              {showLogout && (
                <motion.div
                  initial={{ opacity: 0, scale: 0 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ type: 'tween', duration: 0.2 }}
                  exit={{
                    opacity: 0,
                    scale: 0,
                    transition: { ease: 'easeInOut', duration: 0.2 },
                  }}
                  className="absolute right-0 top-[600%] z-10 flex w-[250px] flex-col gap-4 rounded-md bg-white pb-5 pt-3 text-left shadow-lg"
                >
                  <div className="flex flex-col gap-1 px-6 text-[#696F8C]">
                    <span className="font-medium">{`${userInfo.first_name} ${userInfo.last_name}`}</span>
                    <span className="text-[12px]">{userInfo.email}</span>
                  </div>
                  <hr />
                  <button type="button" onClick={handleLogout} className="flex cursor-pointer items-center px-6 text-sm text-[#474D66]">
                    <img className="mr-2" src={signOut} alt="sign out icon" /> Sign out
                  </button>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppHeader;
