import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Backdrop from '../backdrop';
import RejectRequest from '../modals/rejectRequest';
import ApproveRequest from '../modals/approveRequest';
import UploadProof from '../modals/uploadProof';

const RequestsEllipsisPopop = ({ id, canAct, requestEventsComplete, role }) => {
  const navigate = useNavigate();
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [openUploadProofModal, setOpenUploadProofModal] = useState(false);

  const rejectRequest = () => {
    setOpenRejectModal(true);
  };

  const approveRequest = () => {
    setOpenApproveModal(true);
  };

  return (
    <>
      <Backdrop classNames="flex items-center justify-center" show={openRejectModal} setShow={setOpenRejectModal}>
        <RejectRequest id={id} setShow={setOpenRejectModal} />
      </Backdrop>
      <Backdrop classNames="flex items-center justify-center" show={openApproveModal} setShow={setOpenApproveModal}>
        <ApproveRequest id={id} setShow={setOpenApproveModal} />
      </Backdrop>
      <Backdrop classNames="flex items-center justify-center" show={openUploadProofModal} setShow={setOpenApproveModal}>
        <UploadProof id={id} setShow={setOpenUploadProofModal} />
      </Backdrop>
      <div className="flex flex-col gap-5 text-left">
        <div className="text-[10.5px] text-[#696F8C]">Actions</div>
        <button type="button" onClick={() => navigate(id)} className="cursor-pointer text-sm text-[#474D66]">
          View
        </button>
        {/* Only the account manager sees this to be able to upload proof of payment */}
        {role === 'Account Manager' && canAct && (
          <button type="button" onClick={() => null} className="cursor-pointer text-sm text-[#474D66]">
            Update
          </button>
        )}
        {/* {role !== 'RM' && ( */}
        <button type="button" onClick={canAct && !requestEventsComplete ? approveRequest : null} className={`${!canAct ? 'cursor-not-allowed' : 'cursor-pointer'} text-sm text-[#64B548]`}>
          Approve
        </button>
        <button type="button" onClick={canAct && !requestEventsComplete ? rejectRequest : null} className={`${!canAct ? 'cursor-not-allowed' : 'cursor-pointer'} text-sm text-[#BF3636]`}>
          Reject
        </button>
        {/* )} */}
      </div>
    </>
  );
};

export default RequestsEllipsisPopop;
