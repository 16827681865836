import toast from 'react-hot-toast';

const customId = 'success';

export const rejectionHandler = (state, action, key) => {
  state.error = action.error.message || 'An error occurred';
  state[key] = false;
  toast.error(action.error.message || 'An error occurred');
};

export const pendingHandler = (state, key) => {
  state[key] = true;
  state.error = '';
};

export const successHandler = (state, key, message) => {
  state[key] = false;
  state.error = '';
  if (message)
    toast.success(message, {
      toastId: customId,
    });
};
