import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '@/services/instance';
import authInstance from '@/services/authInstance';
import { pendingHandler, rejectionHandler, successHandler } from '@/shared/requestHandlers';

export const getWarehouses = createAsyncThunk('warehouse/getWarehouses', async (queryParams) => {
  const { data } = await instance.get(`admin/warehouse${queryParams}`);
  return data;
});

export const getWarehouse = createAsyncThunk('warehouse/getWarehouse', async (id) => {
  const { data } = await instance.get(`admin/warehouse/getOne?_id=${id}`);
  return data;
});

export const getAllWarehouses = createAsyncThunk('warehouse/getAllWarehouses', async () => {
  const { data } = await instance.get('admin/warehouse/');
  return data;
});

export const addStore = createAsyncThunk('warehouse/addStore', async (postData) => {
  const { data } = await instance.post('admin/warehouse/createWarehouse', postData);
  return data;
});

export const editStore = createAsyncThunk('warehouse/editStore', async (postData) => {
  const { data } = await instance.patch('admin/warehouse/update', postData);
  return data;
});

export const deleteStore = createAsyncThunk('warehouse/deleteStore', async (id) => {
  const { data } = await instance.delete(`admin/warehouse?_id=${id}`);
  return data;
});

export const getStoreManagers = createAsyncThunk('warehouse/getStoreManagers', async () => {
  const { data } = await authInstance.get('/getAll?role=storeManager');
  return data;
});

export const getStoreData = createAsyncThunk('warehouse/getStoreData', async (queryParams) => {
  const { data } = await instance.get(`/bounds${queryParams}`);
  return data;
});

export const exportStoreData = createAsyncThunk('warehouse/exportStoreData', async (queryParams) => {
  const { data } = await instance.get(`/bounds${queryParams}`);
  return data;
});

export const exportAllStoresData = createAsyncThunk('warehouse/exportAllStoresData', async (queryParams = {}) => {
  // Check if queryParams is an empty object
  const isQueryParamsEmpty = Object.keys(queryParams).length === 0 && queryParams.constructor === Object;

  const url = isQueryParamsEmpty ? `/bounds/all` : `/bounds/all?${new URLSearchParams(queryParams).toString()}`;

  const { data } = await instance.get(url);

  return data;
});

export const getWarehouseSummaryCharts = createAsyncThunk('warehouse/getWarehouseSummaryCharts', async (params) => {
  const { data } = await instance.get('/itemTypes/summary2', { params });
  return data;
});

const initialState = {
  warehouses: [],
  allWarehouses: [],
  store: [],
  storeData: [],
  warehouseSummaryChartsData: null,
  allStoresData: null,
  warehousesLoading: false,
  allWarehousesLoading: false,
  addStoreLoading: false,
  editStoreLoading: false,
  getStoreManagersLoading: false,
  getWarehouseLoading: false,
  getStoreDataLoading: false,
  exportStoreDataLoading: false,
  exportAllStoresDataLoading: false,
  deleteStoreLoading: false,
  warehouseSummaryChartsLoading: false,
  error: '',
};

const warehouseSlice = createSlice({
  name: 'warehouse',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWarehouses.pending, (state) => {
        pendingHandler(state, 'warehousesLoading');
      })
      .addCase(getWarehouses.fulfilled, (state, { payload: { data } }) => {
        state.warehouses = data.data;
        successHandler(state, 'warehousesLoading');
      })
      .addCase(getWarehouses.rejected, (state, action) => {
        rejectionHandler(state, action, 'warehousesLoading');
      })
      .addCase(getAllWarehouses.pending, (state) => {
        pendingHandler(state, 'allWarehousesLoading');
      })
      .addCase(getAllWarehouses.fulfilled, (state, { payload: { data } }) => {
        state.allWarehouses = data.data;
        successHandler(state, 'allWarehousesLoading');
      })
      .addCase(getAllWarehouses.rejected, (state, action) => {
        rejectionHandler(state, action, 'allWarehousesLoading');
      })
      .addCase(addStore.pending, (state) => {
        pendingHandler(state, 'addStoreLoading');
      })
      .addCase(addStore.fulfilled, (state, { payload: { data } }) => {
        state.store = data.data;
        successHandler(state, 'addStoreLoading');
      })
      .addCase(addStore.rejected, (state, action) => {
        rejectionHandler(state, action, 'addStoreLoading');
      })
      .addCase(editStore.pending, (state) => {
        pendingHandler(state, 'editStoreLoading');
      })
      .addCase(editStore.fulfilled, (state) => {
        successHandler(state, 'editStoreLoading');
      })
      .addCase(editStore.rejected, (state, action) => {
        rejectionHandler(state, action, 'editStoreLoading');
      })
      .addCase(deleteStore.pending, (state) => {
        pendingHandler(state, 'deleteStoreLoading');
      })
      .addCase(deleteStore.fulfilled, (state) => {
        successHandler(state, 'deleteStoreLoading', 'Store deleted');
      })
      .addCase(deleteStore.rejected, (state, action) => {
        rejectionHandler(state, action, 'deleteStoreLoading');
      })
      .addCase(getStoreManagers.pending, (state) => {
        pendingHandler(state, 'getStoreManagersLoading');
      })
      .addCase(getStoreManagers.fulfilled, (state) => {
        successHandler(state, 'getStoreManagersLoading');
      })
      .addCase(getStoreManagers.rejected, (state, action) => {
        rejectionHandler(state, action, 'getStoreManagersLoading');
      })
      .addCase(getWarehouse.pending, (state) => {
        pendingHandler(state, 'getWarehouseLoading');
      })
      .addCase(getWarehouse.fulfilled, (state) => {
        successHandler(state, 'getWarehouseLoading');
      })
      .addCase(getWarehouse.rejected, (state, action) => {
        rejectionHandler(state, action, 'getWarehouseLoading');
      })
      .addCase(getStoreData.pending, (state) => {
        pendingHandler(state, 'getStoreDataLoading');
      })
      .addCase(getStoreData.fulfilled, (state, { payload: { data } }) => {
        state.storeData = data.data;
        successHandler(state, 'getStoreDataLoading');
      })
      .addCase(getStoreData.rejected, (state, action) => {
        rejectionHandler(state, action, 'getStoreDataLoading');
      })
      .addCase(exportStoreData.pending, (state) => {
        pendingHandler(state, 'exportStoreDataLoading');
      })
      .addCase(exportStoreData.fulfilled, (state) => {
        successHandler(state, 'exportStoreDataLoading');
      })
      .addCase(exportStoreData.rejected, (state, action) => {
        rejectionHandler(state, action, 'exportStoreDataLoading');
      })
      .addCase(exportAllStoresData.pending, (state) => {
        pendingHandler(state, 'exportAllStoresDataLoading');
      })
      .addCase(exportAllStoresData.fulfilled, (state, { payload }) => {
        state.allStoresData = payload;
        successHandler(state, 'exportAllStoresDataLoading');
      })
      .addCase(exportAllStoresData.rejected, (state, action) => {
        rejectionHandler(state, action, 'exportAllStoresDataLoading');
      })
      .addCase(getWarehouseSummaryCharts.pending, (state) => {
        pendingHandler(state, 'warehouseSummaryChartsLoading');
      })
      .addCase(getWarehouseSummaryCharts.fulfilled, (state, { payload: { data } }) => {
        state.warehouseSummaryChartsData = data;
        successHandler(state, 'warehouseSummaryChartsLoading');
      })
      .addCase(getWarehouseSummaryCharts.rejected, (state, action) => {
        rejectionHandler(state, action, 'warehouseSummaryChartsLoading');
      });
  },
});

export default warehouseSlice.reducer;
