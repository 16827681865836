import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="flex h-screen items-center justify-center">
      <div className="bp-10 text-center">
        <p className="mb-8 text-9xl font-bold">404</p>
        <p className="mb-7 text-3xl font-bold">NOT FOUND</p>
        <Link className="ronded border border-black px-6 py-2 text-sm" to="/">
          HOMEPAGE
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
