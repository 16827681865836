import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '@/services/instance';
import { pendingHandler, rejectionHandler, successHandler } from '@/shared/requestHandlers';

export const addCategory = createAsyncThunk('categorySettings/outbound/addCategory', async (postData) => {
  const { data } = await instance.post('/categories', postData);
  return data;
});

export const editCategory = createAsyncThunk('categorySettings/outbound/editCategory', async (patchData) => {
  const { data } = await instance.patch('/categories', patchData);
  return data;
});

export const deleteCategory = createAsyncThunk('categorySettings/outbound/deleteCategory', async (id) => {
  const { data } = await instance.delete(`/categories?_id=${id}`);
  return data;
});

export const getCategory = createAsyncThunk('categorySettings/outbound/getCategory', async (id) => {
  const { data } = await instance.get(`/categories/getOne?_id=${id}`);
  return data;
});

export const getCategories = createAsyncThunk('categorySettings/outbound/getCategories', async (queryParams) => {
  let result;
  if (queryParams) {
    result = await instance.get(`/categories${queryParams}&bound=outbound`);
  } else {
    result = await instance.get('/categories?bound=outbound');
  }
  return result.data;
});

export const getAllCategories = createAsyncThunk('categorySettings/outbound/getAllCategories', async () => {
  const { data } = await instance.get('/categories?bound=outbound');
  return data;
});

const initialState = {
  categories: [],
  allCategories: [],
  addCategoryLoading: false,
  editCategoryLoading: false,
  deleteCategoryLoading: false,
  getCategoryLoading: false,
  getCategoriesLoading: false,
  getAllCategoriesLoading: false,
  error: '',
};

const outboundCategorySlice = createSlice({
  name: 'categorySettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addCategory.pending, (state) => {
        pendingHandler(state, 'addCategoryLoading');
      })
      .addCase(addCategory.fulfilled, (state, { payload: { data } }) => {
        state.categories.push(data);
        successHandler(state, 'addCategoryLoading', 'Category added');
      })
      .addCase(addCategory.rejected, (state, action) => {
        rejectionHandler(state, action, 'addCategoryLoading');
      })
      .addCase(editCategory.pending, (state) => {
        pendingHandler(state, 'editCategoryLoading');
      })
      .addCase(editCategory.fulfilled, (state) => {
        successHandler(state, 'editCategoryLoading', 'Category edited');
      })
      .addCase(editCategory.rejected, (state, action) => {
        rejectionHandler(state, action, 'editCategoryLoading');
      })
      .addCase(deleteCategory.pending, (state) => {
        pendingHandler(state, 'deleteCategoryLoading');
      })
      .addCase(deleteCategory.fulfilled, (state) => {
        successHandler(state, 'deleteCategoryLoading', 'Category deleted');
      })
      .addCase(deleteCategory.rejected, (state, action) => {
        rejectionHandler(state, action, 'deleteCategoryLoading');
      })
      .addCase(getCategory.pending, (state) => {
        pendingHandler(state, 'getCategoryLoading');
      })
      .addCase(getCategory.fulfilled, (state) => {
        successHandler(state, 'getCategoryLoading');
      })
      .addCase(getCategory.rejected, (state, action) => {
        rejectionHandler(state, action, 'getCategoryLoading');
      })
      .addCase(getCategories.pending, (state) => {
        pendingHandler(state, 'getCategoriesLoading');
      })
      .addCase(getCategories.fulfilled, (state, { payload: { data } }) => {
        state.categories = data.data;
        successHandler(state, 'getCategoriesLoading');
      })
      .addCase(getCategories.rejected, (state, action) => {
        rejectionHandler(state, action, 'getCategoriesLoading');
      })
      .addCase(getAllCategories.pending, (state) => {
        pendingHandler(state, 'getAllCategoriesLoading');
      })
      .addCase(getAllCategories.fulfilled, (state, { payload: { data } }) => {
        state.allCategories = data.data;
        successHandler(state, 'getAllCategoriesLoading');
      })
      .addCase(getAllCategories.rejected, (state, action) => {
        rejectionHandler(state, action, 'getAllCategoriesLoading');
      });
  },
});

export default outboundCategorySlice.reducer;
