import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '@/services/instance';
import { pendingHandler, rejectionHandler, successHandler } from '@/shared/requestHandlers';
import history from '@/customRoutes/history';

export const getRequests = createAsyncThunk('procurement/getRequests', async (queryParams) => {
  const { data } = await instance.get(`/requests${queryParams}`);
  return data;
});

export const createRequest = createAsyncThunk('procurement/createRequest', async (payload) => {
  const { data } = await instance.post('/requests', payload);
  return data;
});

export const addEvents = createAsyncThunk('procurement/addEvents', async (requestDetails) => {
  const { data } = await instance.post(`/requests/events/${requestDetails.id}`, requestDetails.payload);
  return data;
});

const initialState = {
  requests: [],
  totalRequests: 0,
  totalPending: 0,
  totalCompleted: 0,
  totalToBePaid: 0,
  totalCancelled: 0,
  loading: false,
  createRequestLoading: false,
  addEventsLoading: false,
  error: '',
};

const procurementSlice = createSlice({
  name: 'procurement',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRequests.pending, (state) => {
        pendingHandler(state, 'loading');
      })
      .addCase(getRequests.fulfilled, (state, { payload: { data } }) => {
        state.requests = data.data.map((item) => ({ ...item, id: item._id }));
        state.totalRequests = data.totalDocumentCount;
        state.totalPending = data.totalPending;
        state.totalCompleted = data.totalCompleted;
        state.totalToBePaid = data.totalToBePaid;
        state.totalCancelled = data.totalCancelled;
        successHandler(state, 'loading');
      })
      .addCase(getRequests.rejected, (state, action) => {
        rejectionHandler(state, action, 'loading');
      })
      .addCase(createRequest.pending, (state) => {
        pendingHandler(state, 'createRequestLoading');
      })
      .addCase(createRequest.fulfilled, (state) => {
        successHandler(state, 'createRequestLoading', 'Request created');
        history.push('/procurement');
      })
      .addCase(createRequest.rejected, (state, action) => {
        rejectionHandler(state, action, 'createRequestLoading');
      })
      .addCase(addEvents.pending, (state) => {
        pendingHandler(state, 'addEventsLoading');
      })
      .addCase(addEvents.fulfilled, (state) => {
        successHandler(state, 'addEventsLoading');
        history.push('/procurement');
      })
      .addCase(addEvents.rejected, (state, action) => {
        rejectionHandler(state, action, 'addEventsLoading');
      });
  },
});

export default procurementSlice.reducer;
