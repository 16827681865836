/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Table from '@/components/table/appTable';
import searchIcon from '@/assets/images/icons/search-icon.svg';
import filterIcon from '@/assets/images/icons/filter-icon.svg';
import useRequestHistoryTableColumns from './requestHistoryTableColumns';

const RequestHistoryTable = ({ fetchData, pageCount, tableHeading, onSearch }) => {
  const { inboundData, loading } = useSelector((state) => state.inbound);
  const [searchTerm, setSearchTerm] = useState('');
  const columns = useRequestHistoryTableColumns();

  const handleChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
    onSearch(value);
  };

  return (
    <>
      <div className="mb-7 mt-12 flex flex-col justify-between lg:flex-row ">
        <h3 className="mb-2 text-2xl font-semibold lg:mb-0">{tableHeading}</h3>
      </div>
      <div className="overflow-hidden text-gray-900">
        <main className="overflow-hidden">
          <div className="w-full">
            <Table columns={columns} data={inboundData} fetchData={fetchData} loading={loading} pageCount={pageCount} />
          </div>
        </main>
      </div>
    </>
  );
};

export default RequestHistoryTable;
