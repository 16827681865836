/* eslint-disable jsx-a11y/control-has-associated-label */
import { XCircleIcon } from '@heroicons/react/24/solid';
import { v4 as uuid } from 'uuid';

export default function ErrorAlert({ errors, onClose }) {
  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <button type="button" onClick={onClose} className="flex-shrink-0 cursor-pointer">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </button>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">There were errors with your upload</h3>
          <div className="mt-2 text-sm text-red-700">
            <ul className="list-disc space-y-1 pl-5">
              {errors.map((error) => {
                return <li key={uuid()}>{error}</li>;
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
