/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import AuthPageLayout from '@/layout/authPageLayout';
import { validateOrg } from '@/redux/features/user/userSlice';
import AuthInput from './shared/authInput';
import * as authService from '@/services/authService';
import Button from './shared/authButton';
import loader from '@/assets/images/loader.gif';

const OrgId = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const [orgId, setOrgId] = useState('');

  const redirectPath = location.state?.path || '/';

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(validateOrg(orgId));
  };

  if (authService.getToken()) {
    return <Navigate to={redirectPath} replace />;
  }

  const disabledButtonCondition = user.validateOrgLoading || !orgId;

  return (
    <AuthPageLayout title="Welcome back" subTitle="Please enter your organization ID">
      <form onSubmit={handleSubmit}>
        <div className="mt-5">
          <label htmlFor="orgId" className="mb-2 text-base text-[#383838]">
            Organization ID
          </label>
          <AuthInput type="text" name="orgId" id="orgId" onChange={(e) => setOrgId(e.target.value)} value={orgId} required placeholder="Enter organization ID" />
        </div>
        <Button type="submit" btnDisabled={disabledButtonCondition}>
          {user.validateOrgLoading ? <img className="mx-auto w-7" src={loader} alt="loader" /> : 'SUBMIT'}
        </Button>
      </form>
    </AuthPageLayout>
  );
};

export default OrgId;
