import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import Card from '../card';
import leftArrowIcon from '@/assets/images/icons/left-arrow-icon.svg';
import cloudIcon from '@/assets/images/icons/cloud-icon.svg';

const FormTemplate = ({ initialValues, validationSchema, onSubmit, title, submitBtnText, children, ...otherProps }) => {
  const navigate = useNavigate();

  return (
    <Card>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} {...otherProps}>
        {(formik) => {
          return (
            <Form className="rounded-t-lg bg-white px-8 pb-7 pt-8">
              <div className="flex justify-between">
                <button className="mr-2 flex h-[34px] items-center rounded border border-[#c3c3c3] bg-white px-5 text-sm text-[#646464]" type="button" onClick={() => navigate(-1)}>
                  <span className="mr-[10px]">
                    <img src={leftArrowIcon} alt="plus icon" />
                  </span>
                  Back
                </button>
              </div>
              <h3 className="mb-6 mt-10 text-2xl font-semibold">{title}</h3>
              {/* Form */}
              <div className="grid grid-cols-2 gap-x-6 gap-y-2">{children}</div>
              <div className="flex gap-4">
                <button className="submit-button" type="submit" disabled={!formik.isValid || formik.isSubmitting}>
                  <span className="mr-[10px]">
                    <img src={cloudIcon} alt="plus icon" />
                  </span>
                  {submitBtnText}
                </button>
                <button className="mr-2 flex h-[38px] items-center rounded border border-[#c3c3c3] bg-white px-5 text-sm text-[#646464]" type="button" onClick={() => navigate(-1)}>
                  Cancel
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Card>
  );
};

export default FormTemplate;
