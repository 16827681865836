import Regions from './regions';
import Zones from './zones';

const RegionAndZonesHome = () => {
  return (
    <>
      <Regions />
      <Zones />
    </>
  );
};

export default RegionAndZonesHome;
