import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '@/services/instance';
import { pendingHandler, rejectionHandler, successHandler } from '@/shared/requestHandlers';

export const getOverviewChartsData = createAsyncThunk('overviewCharts/getOverviewChartsData', async () => {
  const { data } = await instance.get('/overview');
  return data;
});

export const getOverviewChartsDataFiltered = createAsyncThunk('overviewCharts/getOverviewChartsDataFiltered', async (queryParams) => {
  const { data } = await instance.get(`/overview${queryParams}`);
  return data;
});

const initialState = { data: null, loading: false, error: '' };

const overviewChartsSlice = createSlice({
  name: 'overviewCharts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOverviewChartsData.pending, (state) => {
        pendingHandler(state, 'loading');
      })
      .addCase(getOverviewChartsData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        successHandler(state, 'loading');
      })
      .addCase(getOverviewChartsData.rejected, (state, action) => {
        rejectionHandler(state, action, 'loading');
      })
      .addCase(getOverviewChartsDataFiltered.pending, (state) => {
        pendingHandler(state, 'loading');
      })
      .addCase(getOverviewChartsDataFiltered.fulfilled, (state, action) => {
        state.data = action.payload.data;
        successHandler(state, 'loading');
      })
      .addCase(getOverviewChartsDataFiltered.rejected, (state, action) => {
        rejectionHandler(state, action, 'loading');
      });
  },
});

export default overviewChartsSlice.reducer;
