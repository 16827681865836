/* eslint-disable no-unused-vars */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Spinner from '@/components/Spinner';
import ViewItemTemplate from '@/components/templates/viewItemTemplate';
import GridItem from '@/components/gridItem';
import { Badge } from '@/components/ui/badge';
import { getOneOrder } from '@/redux/features/orders/ordersSlice';

const ViewTransshipment = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { orderItem, itemLoading } = useSelector((state) => state.orders);

  useEffect(() => {
    dispatch(getOneOrder(params.id));
  }, [dispatch, params.id]);

  return (
    <ViewItemTemplate title="Transshipment Details" actionText="Edit Details" btnClickRoute="edit">
      {itemLoading && <Spinner />}
      <div className="grid grid-cols-3 gap-8">
        <GridItem title="Supplying Store" content={orderItem?.supplyingStore?.store?.name || '-'} />
        <GridItem title="Receiving Store" content={orderItem?.receivingStore?.store?.name || '-'} />
        <GridItem title="Product Type" content={orderItem?.product?.category?.name || '-'} />
        <GridItem title="Product Name" content={orderItem?.product?.name} />
        <GridItem title="Weight(Kg)" content={orderItem?.weight || '-'} />
        <GridItem title="Quantity" content={orderItem?.quantity || '-'} />
        <GridItem title="Status" content={<Badge variant={orderItem?.status === 'completed' ? 'primary' : 'destructive'}>{orderItem?.status}</Badge>} />
      </div>
    </ViewItemTemplate>
  );
};

export default ViewTransshipment;
