import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Spinner from '@/components/Spinner';
import ViewItemTemplate from '@/components/templates/viewItemTemplate';
import { getOutboundItem } from '@/redux/features/outbound/outboundSlice';
import GridItem from '@/components/gridItem';

const ViewOutboundItem = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { outboundItem, itemLoading } = useSelector((state) => state.outbound);

  useEffect(() => {
    dispatch(getOutboundItem(params.id));
  }, []);

  return (
    <ViewItemTemplate title="Outbound Activity" actionText="Edit Details" btnClickRoute={`/outbound/edit/${params.id}`}>
      {itemLoading && <Spinner />}
      <div className="grid grid-cols-3 gap-8">
        <GridItem title="Outbound Item" content={outboundItem?.boundItem?.name || '-'} />
        <GridItem title="Warehouse" content={outboundItem?.warehouse?.store?.name || '-'} />
        <GridItem title="Category" content={outboundItem?.category?.name || '-'} />
        <GridItem title="Description/Activity" content={outboundItem?.description?.name || '-'} />
        <GridItem title="Receiver" content={outboundItem?.destination?.name || '-'} />
        <GridItem title="Weight" content={outboundItem?.weight ?? '-'} />
        <GridItem title="Unit" content={outboundItem?.unitOfMeasurement?.name || '-'} />
        <GridItem title="QTY" content={outboundItem?.quantity ?? '-'} />
        <GridItem title="Moisture" content={outboundItem?.moisture ?? '-'} />
        <GridItem title="Purity" content={outboundItem?.purity ?? '-'} />
        <GridItem title="Cost" content={outboundItem?.cost ?? '-'} />
        <GridItem title="Destination" content={outboundItem?.destination?.name || '-'} />
        <GridItem title="Driver" content={outboundItem?.driverName || '-'} />
        <GridItem title="Phone Number" content={outboundItem?.phoneNo || '-'} />
        <GridItem title="Plate Number" content={outboundItem?.plateNo || '-'} />
        <GridItem title="Thrive Rep" content={outboundItem?.thriveRep || '-'} />
        <GridItem title="Brought By" content={outboundItem?.broughtBy || '-'} />
        <GridItem title="Comment" content={outboundItem?.comment || '-'} />
      </div>
    </ViewItemTemplate>
  );
};

export default ViewOutboundItem;
