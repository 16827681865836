/* eslint-disable react/button-has-type */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Spinner from '@/components/Spinner';
import ViewItemTemplate from '@/components/templates/viewItemTemplate';
import { getInboundItem } from '@/redux/features/inbound/inboundSlice';
import GridItem from '@/components/gridItem';
import FullPaymentDialog from '../finance/fullPaymentDialog';
import PartPaymentDialog from '../finance/partPaymentDialog';
import PaymentHistoryTable from '../finance/paymentHistoryTable';

const ViewInboundItem = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  // Using this location to determine where to render payment button.
  const location = useLocation();
  const { inboundItem, itemLoading } = useSelector((state) => state.inbound);

  const grnImage = inboundItem?.multiWaybillPhoto[0]?.imageUrl;
  const waybillImage = inboundItem?.multiWaybillPhoto;

  const viewGRNImage = () => {
    navigate('/inbound/grn', { state: { imageUrl: grnImage } });
  };

  const viewWaybillImage = () => {
    navigate('/inbound/waybill', { state: { imageUrl: waybillImage } });
  };

  useEffect(() => {
    dispatch(getInboundItem(params.id));
  }, [dispatch, params.id]);

  return (
    <>
      <ViewItemTemplate title="Inbound Details" actionText="Edit Details" btnClickRoute={`/inbound/edit/${params.id}`} footer={location.pathname.includes('/finance/inbound-order') && <Footer />}>
        {itemLoading && <Spinner />}
        <div className="grid grid-cols-3 gap-8">
          <GridItem title="Inbound Item" content={inboundItem?.boundItem?.name || '-'} />
          <GridItem title="Warehouse" content={inboundItem?.warehouse?.store?.name || '-'} />
          <GridItem title="Category" content={inboundItem?.category?.name || '-'} />
          <GridItem title="Description/Activity" content={inboundItem?.description?.name || '-'} />
          <GridItem title="Supplier" content={inboundItem?.supplierName?.name || '-'} />
          <GridItem title="Weight" content={inboundItem?.weight ?? '-'} />
          <GridItem title="Unit" content={inboundItem?.unitOfMeasurement?.name || '-'} />
          <GridItem title="QTY" content={inboundItem?.quantity || '-'} />
          <GridItem title="Moisture" content={inboundItem?.moisture ?? '-'} />
          <GridItem title="Purity" content={inboundItem?.purity ?? '-'} />
          <GridItem title="Cost" content={inboundItem?.cost ?? '-'} />
          <GridItem title="Driver" content={inboundItem?.driverName || '-'} />
          <GridItem title="Phone Number" content={inboundItem?.phoneNo || '-'} />
          <GridItem title="Plate Number" content={inboundItem?.plateNo || '-'} />
          <GridItem title="Thrive Rep" content={inboundItem?.thriveRep || '-'} />
          <GridItem title="Brought By" content={inboundItem?.broughtBy || '-'} />
          <GridItem title="Comment" content={inboundItem?.comment || '-'} />
          <GridItem
            title="Waybill Photo"
            content={
              inboundItem?.multiWaybillPhoto?.length > 0 ? (
                // eslint-disable-next-line react/button-has-type
                <button className="text-sm font-semibold text-[#551a8b] underline" onClick={viewWaybillImage}>
                  Link
                </button>
              ) : (
                <div className="text-sm font-semibold text-[#2B2B2B]">-</div>
              )
            }
          />
          <GridItem title="Weight" content={inboundItem?.weight || '-'} />
          <GridItem
            title="GRN"
            content={
              inboundItem?.multiWaybillPhoto?.length > 0 ? (
                <div className="flex gap-2">
                  <span>{inboundItem?.multiWaybillPhoto.map((res) => res.name).join(', ')}</span>
                  <button className="text-sm font-semibold text-[#551a8b] underline" onClick={viewGRNImage}>
                    View Image
                  </button>
                </div>
              ) : (
                <div className="text-sm font-semibold text-[#2B2B2B]">-</div>
              )
            }
          />
        </div>
      </ViewItemTemplate>
      {/* Remove payment history table from the inbound page */}
      {location.pathname.includes('/finance/inbound-order') && <PaymentHistoryTable />}
    </>
  );
};
// eslint-disable-next-line no-lone-blocks

const Footer = () => {
  return (
    <div className="mt-7 flex items-center justify-end">
      <div className="flex items-center gap-3">
        <PartPaymentDialog GRN="sample image url" />
        <FullPaymentDialog GRN="sample image url" />
      </div>
    </div>
  );
};

export default ViewInboundItem;
