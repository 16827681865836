import { Field } from 'formik';
import classNames from 'classnames';

const TextArea = (props) => {
  const { label, name, ...rest } = props;

  return (
    <div>
      <label className="form-control-label" htmlFor={name}>
        {label}
      </label>
      <Field name={name}>
        {({
          field,
          //  form,
          meta,
        }) => {
          return (
            <>
              <textarea className="form-control-textarea" rows="4" {...field} {...rest} />
              <small
                className={classNames('text-[red]', 'block', 'opacity-0', {
                  'opacity-100': meta.touched && meta.error,
                })}
              >
                {meta.error || 'error'}
              </small>
            </>
          );
        }}
      </Field>
    </div>
  );
};

export default TextArea;
