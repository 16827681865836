/* eslint-disable no-alert */
import { useMemo } from 'react';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { ActionsHeader, ActionsPopover, StoreCountStatusPill } from '@/components/table/appTableNew';

export default function useStoreCountTableColumns(handleDelete) {
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        Header: 'Item',
        accessor: 'commodityName',
        Cell: ({ value }) => <span>{value || '--'}</span>,
      },

      {
        Header: 'Auditor',
        accessor: '',
        Cell: ({ row }) => {
          const record = row.original;
          return <span>{`${record.user?.first_name} ${record.user?.last_name}`}</span>;
        },
      },
      {
        Header: 'Warehouse',
        accessor: 'store.store.name',
        Cell: ({ value }) => <span>{value || '--'}</span>,
      },
      {
        Header: 'Region',
        accessor: 'user.region.value',
        Cell: ({ value }) => <span>{value || '--'}</span>,
      },
      {
        Header: 'Date',
        accessor: 'created_at',
        Cell: ({ value }) => <span>{format(new Date(value), 'EEE, MMM d, yyyy h:mm a')}</span>,
      },
      {
        Header: 'Comment',
        accessor: 'comments',
        Cell: ({ value }) => <span className="inline-block max-w-[100px] truncate align-middle">{value[value.length - 1] ? value[value.length - 1]?.text : '--'}</span>,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => <StoreCountStatusPill value={value} />,
      },
      {
        Header: ActionsHeader,
        accessor: '_id',
        Cell: ({ value }) => (
          <ActionsPopover
            actions={[
              {
                text: 'View',
                onClickAction: () => navigate(value),
              },
              {
                text: 'Delete',
                onClickAction: () => {
                  const response = window.confirm('Are you sure you want to delete this item?');

                  if (response) {
                    handleDelete(value);
                  }
                },
              },
            ]}
          />
        ),
      },
    ],
    [],
  );

  return columns;
}
