/* eslint-disable no-unused-vars */
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '@/components/Spinner';
import Table, { ActionsPopover, ActionsPopoverr, FormatDate } from '@/components/table/appTable';
import { deleteRegion } from '@/redux/features/settings/regionsAndZonesSettings/regionSlice';

const AddRegionTable = ({ fetchData, pageCount, setInitialPageSize, initialPageSize }) => {
  const dispatch = useDispatch();

  const handleDelete = useCallback(
    (id) => {
      dispatch(deleteRegion(id))
        .unwrap()
        .then(() => {
          fetchData({ pageSize: initialPageSize, pageIndex: 0 });
        });
    },
    [initialPageSize],
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Label',
        accessor: 'label',
      },
      {
        Header: 'Value',
        accessor: 'value',
      },
      {
        Header: 'Date Created',
        accessor: 'created_at',
        Cell: FormatDate,
      },
      {
        Header: '',
        accessor: '_id',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ value }) => (
          <ActionsPopover
            actions={[
              {
                text: 'Edit',
                onClickAction: () => null,
              },
              {
                text: 'Delete',
                onClickAction: () => {
                  const response = window.confirm('Confirm delete?');
                  if (response) {
                    handleDelete(value);
                  }
                },
              },
            ]}
          />
        ),
      },
    ],
    [initialPageSize],
  );

  const { regions, getRegionsLoading, deleteRegionLoading } = useSelector((state) => state.region);

  return (
    <div className="relative overflow-hidden text-gray-900">
      {deleteRegionLoading && <Spinner />}
      <main className="overflow-hidden">
        <div className="w-full">
          <Table columns={columns} data={regions} fetchData={fetchData} loading={getRegionsLoading} pageCount={pageCount} pageSize={initialPageSize} setInitialPageSize={setInitialPageSize} />
        </div>
      </main>
    </div>
  );
};

export default AddRegionTable;
