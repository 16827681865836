import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Table, { ActionsPopover, FormatDate, ToggleSwitch } from '@/components/table/appTable';
import { deleteSupplier } from '@/redux/features/settings/inboundSettings/inboundLogisticsSlice';
import Spinner from '@/components/Spinner';

const LogisticsTable = ({ fetchData, pageCount, setInitialPageSize, initialPageSize }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDelete = useCallback(
    (id) => {
      dispatch(deleteSupplier(id))
        .unwrap()
        .then(() => {
          fetchData({ pageSize: initialPageSize, pageIndex: 0 });
        });
    },
    [initialPageSize],
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Supplier Name',
        accessor: 'name',
      },
      {
        Header: 'Date Created',
        accessor: 'created_at',
        Cell: FormatDate,
      },
      {
        Header: 'Status',
        accessor: 'active',
        Cell: ToggleSwitch,
      },
      {
        Header: '',
        accessor: '_id',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ value }) => (
          <ActionsPopover
            actions={[
              {
                text: 'Edit',
                onClickAction: () => navigate(`/settings/inbound/edit/logistics/${value}`),
              },
              {
                text: 'Delete',
                onClickAction: () => {
                  const response = window.confirm('Are you sure you want to delete this item?');

                  if (response) {
                    handleDelete(value);
                  }
                },
              },
            ]}
          />
        ),
      },
    ],
    [handleDelete],
  );

  const { suppliers, getSuppliersLoading, deleteSupplierLoading } = useSelector((state) => state.inboundLogistics);

  return (
    <div className="relative overflow-hidden text-gray-900">
      {deleteSupplierLoading && <Spinner />}
      <main className="overflow-hidden">
        <div className="w-full">
          <Table columns={columns} data={suppliers} fetchData={fetchData} loading={getSuppliersLoading} pageCount={pageCount} pageSize={initialPageSize} setInitialPageSize={setInitialPageSize} />
        </div>
      </main>
    </div>
  );
};

export default LogisticsTable;
