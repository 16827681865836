/* eslint-disable no-plusplus */
import { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCategories } from '@/redux/features/settings/outboundSettings/outboundCategorySlice';
import { buildQueryParams } from '@/shared/buildQueryParams';
import AddCategoriesForm from './addCategoriesForm';
import AddCategoriesTable from './addCategoriesTable';

// Initial Query Params object
const userQuery = { skip: 0, limit: 5 };

const AddCategories = () => {
  const dispatch = useDispatch();
  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = useRef(0);
  const [initialPageSize, setInitialPageSize] = useState(2);

  const fetchData = useCallback(({ pageSize, pageIndex }) => {
    userQuery.limit = pageSize;
    userQuery.skip = pageIndex * pageSize;

    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current) {
      dispatch(getCategories(buildQueryParams(userQuery)))
        .unwrap()
        .then((result) => {
          setPageCount(Math.ceil(result.data.totalDocumentCount / pageSize));
        });
    }
  }, []);

  return (
    <div className="mb-9">
      <h3 className="mb-6 text-2xl font-semibold">Add Categories</h3>
      <AddCategoriesForm initialPageSize={initialPageSize} fetchData={fetchData} />
      <AddCategoriesTable setInitialPageSize={setInitialPageSize} initialPageSize={initialPageSize} fetchData={fetchData} pageCount={pageCount} />
    </div>
  );
};

export default AddCategories;
