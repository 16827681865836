/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import FormTemplate from '@/components/templates/formTemplate';
import Spinner from '@/components/Spinner';
import FormikControl from '@/components/formik/formikControl';
import { getAllUnits } from '@/redux/features/settings/settingsSlice';
import { createRequest } from '@/redux/features/procurement/procurementSlice';

const NewRequest = () => {
  const dispatch = useDispatch();
  const { getAllUnitsLoading } = useSelector((state) => state.settings);
  const { createRequestLoading } = useSelector((state) => state.procurement);
  // const [itemOptions, setItemOptions] = useState([
  //   { label: 'Select Item', value: '', isDisabled: true },
  //   { label: 'Maize', value: 1 },
  //   { label: 'Rice', value: 2 },
  // ])
  const [unitOptions, setUnitOptions] = useState([]);
  const [initialValues, setInitialValues] = useState({
    description: '',
    item: '',
    quantity: 0,
    amount: 0,
    unit: '',
    comment: '',
  });

  const validationSchema = Yup.object({
    description: Yup.string().required('Required'),
    quantity: Yup.number('Not a number').required('Required').integer('Must be an integer'),
    unit: Yup.string().required('Required'),
    item: Yup.string().required('Required'),
    amount: Yup.number('Not a number').required('Required'),
    comment: Yup.string().required('Required'),
  });

  const onSubmit = (values, submitProps) => {
    dispatch(createRequest(values));
    // .unwrap()
    // .then(() => {
    //   fetchData({ pageSize: 2, pageIndex: 0 })
    // })
    submitProps.resetForm();
  };

  useEffect(() => {
    // Get Units
    dispatch(getAllUnits())
      .unwrap()
      .then((result) => {
        // console.log('Units', result.data.data)
        const options = [];
        result.data.data.forEach((value) => {
          options.push({
            label: value.name,
            value: value._id,
          });
        });
        setUnitOptions([{ label: 'Select Unit', value: '', isDisabled: true }, ...options]);
      });
  }, []);

  const loading = getAllUnitsLoading || createRequestLoading;

  return (
    <FormTemplate initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} title="New Request" submitBtnText="Create Request" enableReinitialize>
      {loading && <Spinner />}
      <div>
        <FormikControl control="input" type="text" label="Description" name="description" placeholder="Enter item description" />
      </div>
      {/* <div>
        <FormikControl control="select" label="Item" name="item" options={itemOptions} />
      </div> */}
      <div>
        <FormikControl control="input" type="text" label="Item" name="item" placeholder="Enter item name" />
      </div>
      <div>
        <FormikControl control="input" type="number" label="Quantity" name="quantity" placeholder="e.g 5" />
      </div>
      <div>
        <FormikControl control="input" type="number" label="Amount" name="amount" placeholder="N0.00" />
      </div>
      <div>
        <FormikControl control="select" label="Unit" name="unit" options={unitOptions} />
      </div>
      <div />
      <div>
        <FormikControl control="textarea" label="Comment" name="comment" placeholder="Enter comment" />
      </div>
    </FormTemplate>
  );
};

export default NewRequest;
