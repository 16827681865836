/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import ViewItemTemplate from '@/components/templates/viewItemTemplate';

const WaybillImage = () => {
  const location = useLocation();
  const { imageUrl } = location.state || {};
  const images = Object.values(imageUrl || {});
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <ViewItemTemplate title="Waybill" headerCta={<div />}>
      <div className="flex h-80 w-full flex-col items-center justify-center">
        {images.length > 0 ? (
          <div className="relative h-full w-full">
            <img src={images[currentIndex].imageUrl} alt={`Waybill ${currentIndex}`} className="h-full w-full object-contain" />
            <button className="absolute left-0 top-1/2 -translate-y-1/2 transform bg-[#64b548] p-2 text-white" onClick={goToPrevious}>
              {'<'}
            </button>
            <button className="absolute right-0 top-1/2 -translate-y-1/2 transform bg-[#64b548] p-2 text-white" onClick={goToNext}>
              {'>'}
            </button>
          </div>
        ) : (
          <p>No image available</p>
        )}
      </div>
    </ViewItemTemplate>
  );
};

export default WaybillImage;
