import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import instance from '@/services/instance';
import { pendingHandler, rejectionHandler, successHandler } from '@/shared/requestHandlers';

export const getOutboundData = createAsyncThunk('outbound/getOutboundData', async (queryParams) => {
  const { data } = await instance.get(`/outBound/getAll${queryParams}`);
  return data;
});

export const exportOutboundData = createAsyncThunk('outbound/exportOutboundData', async () => {
  const { data } = await instance.get(`/outBound/export`);
  return data;
});

export const getOutboundItem = createAsyncThunk('outbound/getOutboundItem', async (id) => {
  const { data } = await instance.get(`/outBound/getOne?_id=${id}`);
  return data;
});

export const editOutboundItem = createAsyncThunk('outbound/editOutboundItem', async (payload) => {
  const { data } = await instance.patch(`/outBound`, payload);
  return data;
});

export const getOutboundPercentages = createAsyncThunk('outbound/getOutboundPercentages', async () => {
  const { data } = await instance.get('/outBound/getPercentages');
  return data;
});

export const createBulkOutbound = createAsyncThunk('outbound/createBulkOutbound', async (payload) => {
  const { data } = await instance.post('/outBound/createMany', payload);
  return data;
});

export const deleteOutboundItem = createAsyncThunk('inbound/deleteOutboundItem', async (id) => {
  const { data } = await instance.delete(`/outBound?_id=${id}`);
  return data;
});

const initialState = {
  outboundData: [],
  outboundItem: null,
  totalBags: 0,
  totalCost: 0,
  totalWeight: 0,
  percentages: [],
  pageCount: 0,
  loading: false,
  itemLoading: false,
  editItemLoading: false,
  deleteItemLoading: false,
  exportOutboundDataLoading: false,
  getOutboundPercentagesLoading: false,
  createBulkOutboundLoading: false,
  error: '',
};

const outboundSlice = createSlice({
  name: 'outbound',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOutboundData.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(getOutboundData.fulfilled, (state, { payload: { data } }) => {
        state.outboundData = data.data;
        state.totalBags = data.totalBags;
        state.totalCost = data.totalCost;
        state.totalWeight = data.totalWeight;
        state.loading = false;
        state.error = '';
      })
      .addCase(getOutboundData.rejected, (state, action) => {
        state.error = action.error.message || 'An error occurred';
        state.loading = false;
        toast.error(action.error.message || 'An error occurred');
      })
      .addCase(exportOutboundData.pending, (state) => {
        state.exportOutboundDataLoading = true;
        state.error = '';
      })
      .addCase(exportOutboundData.fulfilled, (state) => {
        state.exportOutboundDataLoading = false;
        state.error = '';
      })
      .addCase(exportOutboundData.rejected, (state, action) => {
        state.error = action.error.message || 'An error occurred';
        state.exportOutboundDataLoading = false;
        toast.error(action.error.message || 'An error occurred');
      })
      .addCase(getOutboundItem.pending, (state) => {
        state.itemLoading = true;
        state.error = '';
      })
      .addCase(getOutboundItem.fulfilled, (state, { payload: { data } }) => {
        state.outboundItem = data;
        state.itemLoading = false;
        state.error = '';
      })
      .addCase(getOutboundItem.rejected, (state, action) => {
        state.error = action.error.message || 'An error occurred';
        state.itemLoading = false;
        toast.error(action.error.message || 'An error occurred');
      })
      .addCase(editOutboundItem.pending, (state) => {
        state.editItemLoading = true;
        state.error = '';
      })
      .addCase(editOutboundItem.fulfilled, (state) => {
        state.editItemLoading = false;
        state.error = '';
        toast.success('Item updated');
      })
      .addCase(editOutboundItem.rejected, (state, action) => {
        state.error = action.error.message || 'An error occurred';
        state.editItemLoading = false;
        toast.error(action.error.message || 'An error occurred');
      })
      .addCase(getOutboundPercentages.pending, (state) => {
        state.getOutboundPercentagesLoading = true;
        state.error = '';
      })
      .addCase(getOutboundPercentages.fulfilled, (state, { payload: { data } }) => {
        state.percentages = data;
        state.getOutboundPercentagesLoading = false;
        state.error = '';
      })
      .addCase(getOutboundPercentages.rejected, (state, action) => {
        state.error = action.error.message || 'An error occurred';
        state.getOutboundPercentagesLoading = false;
        toast.error(action.error.message || 'An error occurred');
      })
      .addCase(createBulkOutbound.pending, (state) => {
        state.createBulkOutboundLoading = true;
        state.error = '';
      })
      .addCase(createBulkOutbound.fulfilled, (state) => {
        state.createBulkOutboundLoading = false;
        state.error = '';
        toast.success('Items imported');
      })
      .addCase(createBulkOutbound.rejected, (state, action) => {
        state.error = action.error.message || 'An error occurred';
        state.createBulkOutboundLoading = false;
        toast.error(action.error.message || 'An error occurred');
      })
      .addCase(deleteOutboundItem.pending, (state) => {
        pendingHandler(state, 'deleteItemLoading');
      })
      .addCase(deleteOutboundItem.fulfilled, (state) => {
        successHandler(state, 'deleteItemLoading', 'Outbound item deleted');
      })
      .addCase(deleteOutboundItem.rejected, (state, action) => {
        rejectionHandler(state, action, 'deleteItemLoading');
      });
  },
});

export default outboundSlice.reducer;
