/* eslint-disable no-shadow */
export const roleNames = {
  'Super Admin': 'Super Admin',
  Admin: 'Admin',
  User: 'User',
  'Store Manager': 'Store Manager',
};

export const roles = [
  { label: roleNames['Super Admin'], value: 'superAdmin' },
  { label: roleNames.Admin, value: 'admin' },
  { label: roleNames.User, value: 'user' },
  { label: roleNames['Store Manager'], value: 'storeManager' },
];

export const getRowLabel = (row) => {
  const role = roles.find((role) => role.value === row);
  return role ? role.label : '';
};

export const requestStatus = {
  pending: 'Pending',
  rejected: 'Rejected',
  toBePaid: 'To be Paid',
  completed: 'Completed',
};
