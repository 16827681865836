import axios from 'axios';
import jwtDecode from 'jwt-decode';
import * as authService from './authService';

const API = process.env.REACT_APP_API_URL_RESOURCES_STAGING + '/api/v1';

const instance = axios.create({
  baseURL: API,
});

instance.defaults.headers.post['Content-Type'] = 'application/json';

instance.interceptors.request.use(
  (config) => {
    const token = authService.getToken();
    const customerId = localStorage.getItem('customerId');

    if (customerId) {
      config.headers['x-ims-customerid'] = customerId;
    }

    if (token) {
      const user = jwtDecode(token);
      const isExpired = new Date().getTime() / 1000 - user.exp > 0;

      if (!isExpired) config.headers.authorization = `Bearer ${token}`;

      if (isExpired) {
        delete config.headers.authorization;
        authService.signOut();
      }
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  },
);

export default instance;
