/* eslint-disable no-unused-vars */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Spinner from '@/components/Spinner';
import ViewItemTemplate from '@/components/templates/viewItemTemplate';
import GridItem from '@/components/gridItem';
import { Badge } from '@/components/ui/badge';
import { getOneOrder } from '@/redux/features/orders/ordersSlice';

const ViewSalesOrder = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { orderItem, loading } = useSelector((state) => state.orders);

  useEffect(() => {
    dispatch(getOneOrder(params.id));
  }, [dispatch, params.id]);

  return (
    <ViewItemTemplate title="Sales Order Details" actionText="Edit Details" btnClickRoute="edit">
      {loading && <Spinner />}
      <div className="grid grid-cols-3 gap-8">
        <GridItem title="Store ID" content={orderItem?.store?.store?.id || '-'} />
        <GridItem title="Store Name" content={orderItem?.store?.store?.name || '-'} />
        <GridItem title="Supplier ID" content={orderItem?.supplier?._id || '-'} />
        <GridItem title="Supplier Name" content={orderItem?.supplier?.name || '-'} />
        <GridItem title="Product Type" content={orderItem?.product?.category?.name || '-'} />
        <GridItem title="Product Name" content={orderItem?.product?.name || '-'} />
        <GridItem title="Quantity" content={orderItem?.quantity || '-'} />
        <GridItem title="Status" content={<Badge variant={orderItem?.status === 'completed' ? 'primary' : 'destructive'}>{orderItem?.status}</Badge>} />
      </div>
    </ViewItemTemplate>
  );
};

export default ViewSalesOrder;
