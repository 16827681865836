/* eslint-disable react/forbid-prop-types */
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { PropTypes } from 'prop-types';

const CustomDateRangePicker = ({ dateRange, ...otherProps }) => {
  const [showPlaceholder, setShowPlaceholder] = useState(true);

  useEffect(() => {
    const [startDate, endDate] = dateRange;
    if (!startDate && !endDate) {
      setShowPlaceholder(true);
    } else {
      setShowPlaceholder(false);
    }
  }, [dateRange]);

  return (
    <div className="relative inline-block">
      <DatePicker
        {...otherProps}
        // isClearable
        dateFormat="d MMM, yyyy"
        className="h-[38px] w-[200px] rounded border border-[#C3C3C3] px-2 text-center text-sm font-medium text-[#646464] outline-none ring-0 focus:border-green"
      />
      {showPlaceholder && (
        <div style={{ pointerEvents: 'none' }} className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] whitespace-nowrap text-sm text-[#646464]">
          Select Date Range
        </div>
      )}
    </div>
  );
};

CustomDateRangePicker.propTypes = {
  dateRange: PropTypes.array.isRequired,
};

export default CustomDateRangePicker;
