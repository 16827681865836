import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '@/services/instance';
import { pendingHandler, rejectionHandler, successHandler } from '@/shared/requestHandlers';

export const addUnit = createAsyncThunk('unitSettings/addUnit', async (postData) => {
  const { data } = await instance.post('/units', postData);
  return data;
});

export const editUnit = createAsyncThunk('unitSettings/editUnit', async (patchData) => {
  const { data } = await instance.patch('/units', patchData);
  return data;
});

export const deleteUnit = createAsyncThunk('unitSettings/deleteUnit', async (id) => {
  const { data } = await instance.delete(`/units?_id=${id}`);
  return data;
});

export const getUnit = createAsyncThunk('unitSettings/getUnit', async (id) => {
  const { data } = await instance.get(`/units/getOne?_id=${id}`);
  return data;
});

export const getUnits = createAsyncThunk('unitSettings/getUnits', async (queryParams) => {
  const { data } = await instance.get(`/units${queryParams}&bound=outbound`);
  return data;
});

export const getAllUnits = createAsyncThunk('unitSettings/getAllUnits', async () => {
  const { data } = await instance.get('/units?bound=outbound');
  return data;
});

const initialState = {
  units: [],
  allUnits: [],
  addUnitLoading: false,
  editUnitLoading: false,
  deleteUnitLoading: false,
  getUnitLoading: false,
  getUnitsLoading: false,
  getAllUnitsLoading: false,
  error: '',
};

const outboundUnitSlice = createSlice({
  name: 'unitSettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addUnit.pending, (state) => {
        pendingHandler(state, 'addUnitLoading');
      })
      .addCase(addUnit.fulfilled, (state) => {
        // state.units.push(data)
        successHandler(state, 'addUnitLoading', 'Unit added');
      })
      .addCase(addUnit.rejected, (state, action) => {
        rejectionHandler(state, action, 'addUnitLoading');
      })
      .addCase(editUnit.pending, (state) => {
        pendingHandler(state, 'editUnitLoading');
      })
      .addCase(editUnit.fulfilled, (state) => {
        successHandler(state, 'editUnitLoading', 'Unit updated');
      })
      .addCase(editUnit.rejected, (state, action) => {
        rejectionHandler(state, action, 'editUnitLoading');
      })
      .addCase(deleteUnit.pending, (state) => {
        pendingHandler(state, 'deleteUnitLoading');
      })
      .addCase(deleteUnit.fulfilled, (state) => {
        successHandler(state, 'deleteUnitLoading', 'Unit deleted');
      })
      .addCase(deleteUnit.rejected, (state, action) => {
        rejectionHandler(state, action, 'deleteUnitLoading');
      })
      .addCase(getUnit.pending, (state) => {
        pendingHandler(state, 'getUnitLoading');
      })
      .addCase(getUnit.fulfilled, (state) => {
        successHandler(state, 'getUnitLoading');
      })
      .addCase(getUnit.rejected, (state, action) => {
        rejectionHandler(state, action, 'getUnitLoading');
      })
      .addCase(getUnits.pending, (state) => {
        pendingHandler(state, 'getUnitsLoading');
      })
      .addCase(getUnits.fulfilled, (state, { payload: { data } }) => {
        state.units = data.data;
        successHandler(state, 'getUnitsLoading');
      })
      .addCase(getUnits.rejected, (state, action) => {
        rejectionHandler(state, action, 'getUnitsLoading');
      })
      .addCase(getAllUnits.pending, (state) => {
        pendingHandler(state, 'getAllUnitsLoading');
      })
      .addCase(getAllUnits.fulfilled, (state, { payload: { data } }) => {
        state.allUnits = data.data;
        successHandler(state, 'getAllUnitsLoading');
      })
      .addCase(getAllUnits.rejected, (state, action) => {
        rejectionHandler(state, action, 'getAllUnitsLoading');
      });
  },
});

export default outboundUnitSlice.reducer;
