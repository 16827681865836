import React, { useEffect, useState } from 'react';
import Backdrop from '@/components/backdrop';
import RejectRequest from '@/components/modals/rejectRequest';
import ApproveRequest from '@/components/modals/approveRequest';
import { ActionsContent } from '@/components/table/appTable';
import RequestsEllipsisPopop from '@/components/table/requestsEllipsisPopop';
import usePermissions, { roleMapper } from '@/hooks/usePermissions';

export const RequestTableActions = ({ value }) => {
  const [showPopop, setShowPopop] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const roles = ['RM', 'Head of Ops', 'CFO', 'Account Manager'];
  const permissions = usePermissions();
  const [canAct, setCanAct] = useState(false);
  const [requestEventsComplete, setRequestEventsComplete] = useState(false);

  // eslint-disable-next-line no-shadow
  const canPerformAction = (requestEvents, currentRole, roles) => {
    const performedRoles = requestEvents
      .map((event) => ({ ...event, role: roleMapper[event.role] }))
      .filter((event) => event.event !== 'pending')
      .map((event) => event.role);
    const currentRoleIndex = roles.indexOf(currentRole);
    const previousRoles = roles.slice(0, currentRoleIndex);

    // eslint-disable-next-line no-restricted-syntax
    for (const role of previousRoles) {
      if (!performedRoles.includes(role)) {
        return false;
      }
    }

    return true;
  };

  const requestEventsCompleted = (requestEvents) => {
    return requestEvents.every((event) => event.event !== 'pending');
  };

  useEffect(() => {
    // console.log(value.description, canPerformAction(value.requestEvents, permissions.role, roles));
    setCanAct(canPerformAction(value.requestEvents, permissions.role, roles));
    setRequestEventsComplete(requestEventsCompleted(value.requestEvents));
  }, []);

  const rejectRequest = () => {
    setOpenRejectModal(true);
  };

  const approveRequest = () => {
    setOpenApproveModal(true);
  };

  // CFO cannot approve/reject request

  return (
    <>
      <Backdrop classNames="flex items-center justify-center" show={openRejectModal} setShow={setOpenRejectModal}>
        <RejectRequest id={value.id} setShow={setOpenRejectModal} />
      </Backdrop>
      <Backdrop classNames="flex items-center justify-center" show={openApproveModal} setShow={setOpenApproveModal}>
        <ApproveRequest id={value.id} setShow={setOpenApproveModal} />
      </Backdrop>
      <div className="flex items-center gap-3">
        {/* RM cannot approve/reject requests */}
        {/* {permissions.role !== 'RM' && ( */}
        <div className="flex items-center gap-3">
          <button
            type="button"
            onClick={approveRequest}
            disabled={!canAct || requestEventsComplete}
            className="h-fit rounded-[3px] border border-transparent bg-[#64B548] px-4 py-[5px] text-[11px] font-semibold text-white disabled:cursor-not-allowed disabled:opacity-80"
          >
            Approve
          </button>
          <button
            type="button"
            onClick={rejectRequest}
            disabled={!canAct || requestEventsComplete}
            className="h-fit rounded-[3px] border border-[#BF3636] bg-white px-4 py-[5px] text-[11px] font-semibold text-[#BF3636] disabled:cursor-not-allowed disabled:opacity-80"
          >
            Reject
          </button>
        </div>
        {/* )} */}
        <ActionsContent showPopop={showPopop} setShowPopop={setShowPopop}>
          <RequestsEllipsisPopop canAct={canAct} requestEventsComplete={requestEventsComplete} role={permissions.role} id={value.id} />
        </ActionsContent>
      </div>
    </>
  );
};

export default RequestTableActions;
