/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import AuthPageLayout from '../../layout/authPageLayout';
import { loginUser } from '../../redux/features/user/userSlice';
import AuthInput from './shared/authInput';
import * as authService from '../../services/authService';
import Button from './shared/authButton';

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const [userDetails, setUserDetails] = useState({
    username: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [disableButton, setDisableButton] = useState(true);

  const redirectPath = location.state?.path || '/';

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({ ...userDetails, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      loginUser({
        phone_number: userDetails.username,
        password: userDetails.password,
      }),
    );
  };

  if (authService.getToken()) {
    return <Navigate to={redirectPath} replace />;
  }

  return (
    <AuthPageLayout title="Reset Password" subTitle="Please input your new password">
      <form autoComplete="off" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="username" className="mb-2 text-base text-[#383838]">
            Password
          </label>
          <AuthInput type="password" name="password" id="password" onChange={handleChange} value={userDetails.username} required placeholder="Enter new password" />
        </div>
        <Button type="button" onClick={() => navigate('/otp-verify')}>
          Confirm Password
        </Button>
      </form>
    </AuthPageLayout>
  );
};

export default ResetPassword;
