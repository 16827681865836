export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const formatAmount = (amount) => {
  return amount.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};
