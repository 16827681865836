import { Navigate, useLocation } from 'react-router-dom';
import * as authService from '../services/authService';

const PrivateRouter = ({ children }) => {
  const location = useLocation();

  if (!authService.getToken()) {
    return <Navigate to="/orgid" state={{ path: location.pathname }} />;
  }

  return children;
};

export default PrivateRouter;
