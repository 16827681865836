import React, { useEffect, useState } from 'react';
import { useDebounce } from 'usehooks-ts';
import searchIcon from '@/assets/images/icons/search-icon.svg';

const SearchLogistics = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);

  const handleChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
    onSearch(value);
  };

  useEffect(() => {
    onSearch(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  return (
    <div className="form-group">
      <label className="form-control-label" id="search-supplier">
        Search
      </label>
      <div className="relative">
        <input onChange={handleChange} type="search" value={searchTerm} className="form-control-input px-7" id="search-supplier" />
        <img className="absolute left-[10px] top-[50%] w-[12px] translate-y-[-50%]" src={searchIcon} alt="Search icon" />
      </div>
    </div>
  );
};

export default SearchLogistics;
