import { combineReducers } from '@reduxjs/toolkit';
import user from './features/user/userSlice';
import inbound from './features/inbound/inboundSlice';
import outbound from './features/outbound/outboundSlice';
import warehouse from './features/warehouse/warehouseSlice';
import inboundUnits from './features/settings/inboundSettings/inboundUnitsSlice';
import inboundActivity from './features/settings/inboundSettings/inboundActivitySlice';
import inboundCategory from './features/settings/inboundSettings/inboundCategorySlice';
import inboundItem from './features/settings/inboundSettings/inboundItemSlice';
import inboundLogistics from './features/settings/inboundSettings/inboundLogisticsSlice';
import outboundUnit from './features/settings/outboundSettings/outboundUnitSlice';
import outboundActivity from './features/settings/outboundSettings/outboundActivitySlice';
import outboundCategory from './features/settings/outboundSettings/outboundCategorySlice';
import outboundLogistics from './features/settings/outboundSettings/outboundLogisticsSlice';
import outboundItem from './features/settings/outboundSettings/outboundItemSlice';
import overviewCharts from './features/charts/overviewChartsSlice';
import procurement from './features/procurement/procurementSlice';
import settings from './features/settings/settingsSlice';
import imageType from './features/settings/imageTypeSettings/imageTypeSlice';
import zone from './features/settings/regionsAndZonesSettings/zoneSlice';
import region from './features/settings/regionsAndZonesSettings/regionSlice';
import storeCount from './features/storeCount/storeCountSlice';
import orders from './features/orders/ordersSlice';
import auditLogs from './features/settings/auditLog/auditLogSlice';

const reducer = combineReducers({
  user,
  inbound,
  inboundUnits,
  inboundActivity,
  inboundCategory,
  inboundItem,
  inboundLogistics,
  outboundUnit,
  outboundActivity,
  outboundCategory,
  outboundLogistics,
  outboundItem,
  outbound,
  warehouse,
  overviewCharts,
  procurement,
  settings,
  imageType,
  zone,
  region,
  storeCount,
  orders,
  auditLogs,
});

export default reducer;
