/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { XMarkIcon } from '@heroicons/react/24/solid';
import CustomDateRangePicker from '@/components/form/customDateRangePicker';
// import CustomSelect from '../../components/form/customSelect'
import downloadIcon from '@/assets/images/icons/downloadIcon.svg';
import { exportStoreData, getStoreData, getWarehouse } from '@/redux/features/warehouse/warehouseSlice';
import MetricCard from '@/components/metricCard/metricCard';
import StoreTable from './storeTable';
import { buildQueryParams } from '@/shared/buildQueryParams';
import { getOverviewChartsDataFiltered } from '@/redux/features/charts/overviewChartsSlice';
import Spinner from '@/components/Spinner';
import leftArrowIcon from '@/assets/images/icons/left-arrow-icon.svg';

const activityOptions = [{ value: 'activity', label: 'All Activities' }];

// Initial Query Params object
let userQuery = { skip: 0, limit: 5 };

const ViewStore = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [selectedActivityOption, setSelectedActivityOption] = useState(activityOptions[0]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [warehouse, setWarehouse] = useState(null);

  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = useRef(0);
  const csvLinkRef = useRef(null);

  const chartsQuery = { warehouse: params.id };

  const { data: chartsData, loading: chartsDataLoading } = useSelector((state) => state.overviewCharts);
  const [exportedData, setExportedData] = useState([]);

  // CSV Heders
  const headers = [
    { label: 'Date & Time', key: 'date' },
    { label: 'Category', key: 'category' },
    { label: 'Type', key: 'type' },
    { label: 'Amount', key: 'amount' },
    { label: 'Units', key: 'units' },
  ];

  // FetchData function for Paginated React Table
  const fetchData = useCallback(({ pageSize, pageIndex }) => {
    userQuery.limit = pageSize;
    userQuery.skip = pageIndex * pageSize;
    userQuery.warehouse = params.id;

    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current) {
      dispatch(getStoreData(buildQueryParams(userQuery)))
        .unwrap()
        .then((result) => {
          setPageCount(Math.ceil(result.data.totalDocumentCount / pageSize));
        });
    }
  }, []);

  useEffect(() => {
    dispatch(getOverviewChartsDataFiltered(buildQueryParams(chartsQuery)));
  }, []);

  useEffect(() => {
    // Fetch data when both the start date and end date are not null
    if (startDate && endDate) {
      userQuery.startDate = new Date(startDate).toISOString();
      userQuery.endDate = new Date(endDate).toISOString();
      fetchData({ pageSize: 5, pageIndex: 0 });
      dispatch(getOverviewChartsDataFiltered(buildQueryParams({ startDate: userQuery.startDate, endDate: userQuery.endDate })));
    }
  }, [startDate, endDate, fetchData]);

  useEffect(() => {
    dispatch(getWarehouse(params.id))
      .unwrap()
      .then((response) => {
        // console.log('Warehouse', response.data)
        setWarehouse(response.data);
        // setInitialValues({
        //   name: response.data.store.name,
        //   id: response.data.store.id,
        //   address: response.data.store.address,
        //   state: response.data.store.state,
        //   lga: response.data.store.lga,
        //   manager: response.data.manager,
        // })
      });
  }, []);

  const clearFilters = () => {
    setDateRange([null, null]);
    // setSelectedActivityOption(activityOptions[0])
    // setTableHeading('All Activities')
    userQuery = { skip: 0, limit: 5 };
    fetchData({ pageSize: 5, pageIndex: 0 });
  };

  const exportData = () => {
    const query = { ...userQuery };
    delete query.skip;
    delete query.limit;
    dispatch(exportStoreData(buildQueryParams(query)))
      .unwrap()
      .then((result) => {
        // console.log('Store data', result.data.data)
        setExportedData(
          result.data.data.map((res) => ({
            date: res?.date,
            category: res?.boundType === 'outbound' ? res?.outbound?.category?.name : res?.inbound?.category?.name,
            type: res?.type || '',
            amount: res?.boundType === 'outbound' ? res?.outbound?.cost : res?.inbound?.cost,
            units: res?.boundType === 'outbound' ? `-${res?.outbound?.totalBags}` : `+${res?.inbound?.totalBags}`,
          })),
        );
      });
  };

  useEffect(() => {
    if (exportedData.length) csvLinkRef.current.link.click();
  }, [exportedData]);

  const totalBalanceTotal = chartsData?.balances.reduce((acc, cum) => acc + cum.totalBalance, 0);
  const openingBalanceTotal = chartsData?.balances.reduce((acc, cum) => acc + cum.openingBalance, 0);
  const closingBalanceTotal = chartsData?.balances.reduce((acc, cum) => acc + cum.closingBalance, 0);

  return (
    <>
      {chartsDataLoading && <Spinner />}
      <div className="mb-8 flex flex-col justify-between gap-3 lg:flex lg:flex-row lg:gap-0">
        <div className="flex items-center gap-3">
          <button type="button" onClick={() => navigate(-1)} className="cursor-pointer">
            <img src={leftArrowIcon} alt="plus icon" />
          </button>
          <h1 className="text-2xl font-semibold text-title-color">{warehouse?.store?.name}</h1>
        </div>
        <div className="filters flex flex-col gap-4 md:flex-row md:items-center">
          <div>
            <CustomDateRangePicker
              selectsRange
              startDate={startDate}
              endDate={endDate}
              onChange={(update) => {
                setDateRange(update);
              }}
              dateRange={dateRange}
            />
          </div>
          {/* <CustomSelect
            value={selectedActivityOption}
            onChange={(option) => setSelectedActivityOption(option)}
            options={activityOptions}
          /> */}
          <button type="button" onClick={exportData} className="flex h-[38px] w-fit items-center rounded bg-[#64B548] px-4 text-sm text-white">
            <span className="mr-[10px]">
              <img src={downloadIcon} alt="plus icon" />
            </span>
            Download
          </button>
          {/* Hidden CSV Download Link */}
          <CSVLink data={exportedData} headers={headers} filename={`${warehouse?.store?.name}.csv`} ref={csvLinkRef} />
          <button type="button" onClick={clearFilters} className="flex h-[38px] w-fit items-center gap-2 rounded text-sm text-[#646464]">
            <span className="inline-block">Clear Filters</span>
            <XMarkIcon className="h-5 w-5 text-red-500" />
          </button>
        </div>
      </div>
      <div className="mb-5 grid justify-between gap-8 md:grid-cols-2 lg:grid-cols-3">
        <MetricCard title="Total Balance" gradient="green" leftUnit="naira" leftValue={totalBalanceTotal} data={chartsData?.balances} />
        <MetricCard title="Opening Balance" gradient="purple" leftUnit="naira" leftValue={openingBalanceTotal} data={chartsData?.balances} />
        <MetricCard title="Closing Balance" gradient="yellow" leftUnit="naira" leftValue={closingBalanceTotal} data={chartsData?.balances} />
      </div>
      <StoreTable fetchData={fetchData} pageCount={pageCount} />
    </>
  );
};

export default ViewStore;
