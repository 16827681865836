import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import FormikControl from '@/components/formik/formikControl';
import plusIcon from '@/assets/images/icons/plus-icon.svg';
import HiddenElement from '@/components/formik/hiddenElement';
import Spinner from '@/components/Spinner';
import { getAllZones } from '@/redux/features/settings/regionsAndZonesSettings/zoneSlice';
import { addRegion } from '@/redux/features/settings/regionsAndZonesSettings/regionSlice';

const AddRegionForm = ({ fetchData, initialPageSize }) => {
  const dispatch = useDispatch();
  const { regions, addRegionLoading } = useSelector((state) => state.region);
  const { getAllZonesLoading } = useSelector((state) => state.zone);
  const [zoneOptions, setZoneOptions] = useState([]);

  const regionId = useMemo(() => {
    const regionNumbers = regions.map((region) => region.value.replace(/[a-z]/gi, ''));
    if (regionNumbers.length > 0) {
      return `r${Number(_.max(regionNumbers)) + 1}`;
    }
    return 'r1';
  }, [regions]);

  const initialValues = {
    label: '',
    value: regionId,
    zones: [],
  };

  const validationSchema = Yup.object({
    label: Yup.string().required('Required'),
    value: Yup.string().required('Required'),
    zones: Yup.array().min(1, 'Required').of(Yup.string().required('Required')),
  });

  const onSubmit = (values, submitProps) => {
    dispatch(addRegion({ ...values }))
      .unwrap()
      .then(() => {
        fetchData({ pageSize: initialPageSize, pageIndex: 0 });
      });
    submitProps.resetForm();
  };

  useEffect(() => {
    dispatch(getAllZones())
      .unwrap()
      .then((result) => {
        const options = [];
        result.data.data.forEach((value) => {
          options.push({
            label: value.label,
            value: value._id,
          });
        });
        setZoneOptions([{ label: 'Select Zones', value: '-', isDisabled: true }, ...options]);
      });
  }, []);

  const loading = addRegionLoading || getAllZonesLoading;

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
      {(formik) => {
        return (
          <Form className="relative rounded-t-lg bg-white px-8 pb-7 pt-8">
            {loading && <Spinner />}
            <div className="flex gap-7">
              <div className="form-group">
                <FormikControl control="input" type="text" label="Name" name="label" placeholder="Region name" />
              </div>
              <div className="form-group">
                <FormikControl control="input" type="text" label="ID" name="value" placeholder="Region id" disabled />
              </div>
              <div className="w-[290px]">
                <FormikControl control="select" label="Zones" name="zones" options={zoneOptions} isMulti />
              </div>
              <div>
                <label htmlFor="submit-btn" className="form-control-label pointer-events-none opacity-0">
                  Button
                </label>
                <button className="submit-button whitespace-nowrap" type="submit" disabled={!formik.isValid || formik.isSubmitting}>
                  <span className="mr-[10px]">
                    <img src={plusIcon} alt="plus icon" />
                  </span>
                  Add Region
                </button>
                <HiddenElement />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddRegionForm;
