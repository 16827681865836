/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-interactive-element-to-noninteractive-role */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useRef } from 'react';
import { useTable, usePagination, useRowSelect } from 'react-table';
import Select from 'react-select';
import ReactPaginate from 'react-paginate';
import { motion, AnimatePresence } from 'framer-motion';
import { v4 as uuid } from 'uuid';
import format from 'date-fns/format';
import { Button } from './button';
import { classNames } from './utils';
import ellipsis from '@/assets/images/icons/ellipsis.svg';
import '@/scss/style.scss';
import EllipsisPopop from './ellipsisPopop';
import { requestStatus } from '@/shared/roles';
import ActionsContainer from './actionsContainer';
import ActionsPopoverContent from './actionsPopoverContent';

export function ActionsHeader({ show }) {
  return <span className={`ml-auto ${!show ? 'pointer-events-none opacity-0' : ''}`}>Action</span>;
}

export function FormatDate({ value }) {
  return <span>{format(new Date(value.toString()), 'EEE, MMM d, yyyy hh:mm aa')}</span>;
}

export function StatusPill({ value, colorScheme }) {
  const status = value || 'unknown';
  const colorSchemes = [
    { bgColor: '#EAEEFB', textColor: '#2244AA' },
    { bgColor: '#E7FAFD', textColor: '#0CA5C0' },
    { bgColor: '#FFF6E5', textColor: '#CC8500' },
    { bgColor: '#EDF6EA', textColor: '#48A928' },
  ];

  return <span className={classNames('leading-wide px-2 py-[2px] text-[11px] font-medium uppercase shadow-sm', `rounded bg-[${colorSchemes[colorScheme].bgColor}] text-[${colorSchemes[colorScheme].textColor}]`)}>{status}</span>;
}

export function RequestStatusPill({ value }) {
  const status = value || 'unknown';

  return (
    <span
      className={classNames(
        'leading-wide rounded px-2 py-[2px] text-xs font-medium shadow-sm',
        status === 'pending' ? 'bg-[#E7FAFD] text-[#0CA5C0]' : null,
        status === 'rejected' ? 'bg-[#F9EBEB] text-[#9F2D2D]' : null,
        status === 'toBePaid' ? 'bg-[#EAEEFB] text-[#2244AA]' : null,
        status === 'completed' ? 'bg-[#EDF6EA] text-[#429A24]' : null,
      )}
    >
      {requestStatus[value]}
    </span>
  );
}

export function StoreCountStatusPill({ value }) {
  const status = value || 'unknown';

  return <span className={classNames('leading-wide rounded px-2 py-[2px] text-xs font-medium capitalize shadow-sm', status === 'pending' && 'bg-[#F9EBEB] text-[#9F2D2D]', status === 'resolved' && 'bg-[#EDF6EA] text-[#429A24]')}>{value}</span>;
}

export function ToggleSwitch({ value }) {
  const checkboxRef = useRef();

  useEffect(() => {
    checkboxRef.current.checked = value;
  }, [value]);

  return (
    <label htmlFor="toggle" className="pointer-events-none flex cursor-pointer items-center">
      <div className="relative">
        <input ref={checkboxRef} type="checkbox" id="toggle" className="sr-only" />

        <div className="block h-[19px] w-[32px] rounded-full bg-[#D8DAE5]" />

        <div className="dot absolute left-[3px] top-[3px] h-[13px] w-[13px] rounded-full bg-white transition" />
      </div>
    </label>
  );
}

export const ActionsContent = ({ showPopop, setShowPopop, disabled, children }) => {
  const popopRef = useRef(null);

  const hideOnClickOutside = (e) => {
    if (!popopRef.current) return;
    const cur = popopRef.current;
    const node = e.target;
    if (cur.contains(node)) return;
    setShowPopop(false);
  };

  useEffect(() => {
    document.addEventListener('click', hideOnClickOutside);

    return () => document.removeEventListener('click', hideOnClickOutside);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => {
    if (disabled) return;
    setShowPopop(!showPopop);
  };

  return (
    <button type="button" ref={popopRef} className="relative w-8">
      <img onClick={handleClick} src={ellipsis} alt="ellipsis" />
      <AnimatePresence>
        {showPopop && (
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ type: 'tween', duration: 0.2 }}
            exit={{
              opacity: 0,
              scale: 0,
              transition: { ease: 'easeInOut', duration: 0.2 },
            }}
            className="absolute -top-7 right-[-15px] z-10 flex w-[210px] flex-col gap-5 rounded bg-white py-5 pl-4 text-left shadow-lg"
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </button>
  );
};

export const Ellipsis = ({ value, itemType, disabled, onDelete }) => {
  return (
    <ActionsContainer disabled={disabled}>
      <EllipsisPopop id={value} itemType={itemType} onDelete={onDelete} />
    </ActionsContainer>
  );
};

export const ActionsPopover = ({ actions, disabled, trigger, side, sideOffset, alignOffset, align }) => {
  return (
    <ActionsContainer disabled={disabled} trigger={trigger} side={side} sideOffset={sideOffset} alignOffset={alignOffset} align={align}>
      <ActionsPopoverContent actions={actions} />
    </ActionsContainer>
  );
};

// const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
//   const defaultRef = useRef();
//   const resolvedRef = ref || defaultRef;

//   useEffect(() => {
//     resolvedRef.current.indeterminate = indeterminate;
//   }, [resolvedRef, indeterminate]);

//   return (
//     <input type="checkbox" ref={resolvedRef} {...rest} />
//   );
// });

const pageSizeOptions = [
  { value: 2, label: 2 },
  { value: 5, label: 5 },
  { value: 10, label: 10 },
];

// Custom styles for table react-select
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: '30px',
    height: '30px',
    boxShadow: state.isFocused ? null : null,
  }),

  valueContainer: (provided) => ({
    ...provided,
    height: '30px',
    padding: '0 6px',
  }),

  input: (provided) => ({
    ...provided,
    margin: '0px',
  }),
  // indicatorSeparator: state => ({
  //   display: 'none',
  // }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '30px',
  }),
};

function AppTable({ columns, data, fetchData, loading, pageCount: controlledPageCount, pageSize: controlledPageSize = 5, setInitialPageSize }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,

    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,

    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: controlledPageSize },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    usePagination,
    useRowSelect,
  );

  // use this ref to keep track of updating internal table state
  const tableStateUpdateRef = useRef(false);

  useEffect(() => {
    tableStateUpdateRef.current = true;
    fetchData({ pageIndex, pageSize });
  }, [fetchData, pageIndex, pageSize]);

  /* reset the page index to 0 when the table data updates due to something
  other than internal table state changes
  */
  useEffect(() => {
    if (!tableStateUpdateRef.current) {
      gotoPage(0);
    }
  }, [data, gotoPage]);

  // clear our ref when the data is loaded, after we perform any side effects
  useEffect(() => {
    tableStateUpdateRef.current = false;
  }, [data]);

  // Render the UI for your table
  return (
    <>
      <div className="flex flex-col overflow-auto">
        <div className="inline-block min-w-full align-middle">
          <div className="sm:rounded-lg">
            <table {...getTableProps()} className="min-w-full table-fixed">
              <thead className="">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, index) => (
                      <th scope="col" className={`${index === 0 ? 'sticky left-0 w-10' : ''} group whitespace-nowrap bg-[#EDF6EA] px-6 py-3 text-left text-sm font-medium tracking-wider text-[#878787]`} {...column.getHeaderProps()}>
                        <div className="flex items-center justify-between">{column.render('Header')}</div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              {loading ? (
                <tbody className="bg-white">
                  {Array(pageSize)
                    .fill(null)
                    .map(() => {
                      return (
                        <tr key={uuid()} className="border-b border-[#F4F4F4]">
                          {Array(columns.length)
                            .fill(null)
                            .map((_, index) => {
                              return (
                                <td key={uuid()} className={`${index === 0 ? 'sticky bg-white' : ''}whitespace-nowrap left-0 w-10 px-6 py-4 text-sm text-[#2B2B2B]`} role="cell">
                                  <div className="h-[32px] w-[100px] animate-pulse rounded-md bg-gray-300" />
                                </td>
                              );
                            })}
                        </tr>
                      );
                    })}
                </tbody>
              ) : (
                <tbody {...getTableBodyProps()} className="bg-white">
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr className="border-b border-[#F4F4F4]" {...row.getRowProps()}>
                        {row.cells.map((cell, index) => {
                          return (
                            <td {...cell.getCellProps()} className={`${index === 0 ? 'sticky left-0 w-10 bg-white' : ''} whitespace-nowrap px-6 py-4 text-sm text-[#2B2B2B]`} role="cell">
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                  {!page.length && !loading && (
                    <tr>
                      <td className="text-md whitespace-nowrap px-6 py-4 text-center font-semibold text-[#2B2B2B]" colSpan="10000">
                        No records
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
      {/* Pagination */}
      <div className="mb-4 bg-white">
        <div className="whitespace-nowrap px-6 py-4 text-sm text-[#2B2B2B]">
          <div className="flex items-center justify-between">
            <div className="flex flex-1 justify-between text-[#646464] sm:hidden">
              <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
                Previous
              </Button>
              <Button onClick={() => nextPage()} disabled={!canNextPage}>
                Next
              </Button>
            </div>
            <div className="hidden sm:flex-1 sm:items-center sm:justify-between md:flex">
              <div className="flex items-center gap-x-2">
                <div>
                  <span className="sr-only">Items Per Page</span>
                  <Select
                    className="text-sm text-[#646464] shadow-sm outline-none"
                    value={pageSizeOptions ? pageSizeOptions.find((option) => option.value === pageSize) : ''}
                    onChange={(option) => {
                      setPageSize(option.value);
                      gotoPage(0);
                      if (setInitialPageSize) {
                        setInitialPageSize(option.value);
                      }
                    }}
                    options={pageSizeOptions}
                    getOptionLabel={(option) => `Show ${option.label}`}
                    menuPlacement="top"
                    styles={customStyles}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 4,
                      colors: {
                        ...theme.colors,
                        primary25: '#EDF6EA',
                        primary: '#64B548',
                      },
                    })}
                    isSearchable={false}
                  />
                </div>
              </div>
              <div>
                <nav className="relative z-0 inline-flex -space-x-px" aria-label="Pagination">
                  <ReactPaginate
                    breakLabel="..."
                    // nextLabel="next >"
                    nextLabel=">"
                    onPageChange={(e) => {
                      gotoPage(Number(e.selected));
                    }}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    // previousLabel="< previous"
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    containerClassName="flex items-center text-[#646464] text-[13px]"
                    pageClassName="h-[32px] flex items-center justify-center hover:bg-[#F9FAFB] transition"
                    pageLinkClassName="h-full px-3 flex items-center"
                    breakClassName="h-[32px] px-3 flex items-center hover:bg-[#F9FAFB] transition"
                    activeClassName="bg-[#EDF6EA] text-[#48A928] hover:bg-[#EDF6EA] transition"
                    previousClassName="h-[32px] w-[32px] flex items-center justify-center hover:bg-[#F9FAFB] transition"
                    previousLinkClassName="h-full w-full flex items-center justify-center"
                    nextClassName="h-[32px] w-[32px] flex items-center justify-center hover:bg-[#F9FAFB] transition"
                    nextLinkClassName="h-full w-full flex items-center justify-center"
                  />
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AppTable;
