/* eslint-disable no-unused-vars */
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { ActionsHeader, ActionsPopover } from '@/components/table/appTable';

export default function useInboundDetailsTable() {
  const navigate = useNavigate();
  const columns = useMemo(
    () => [
      {
        Header: 'Item',
        accessor: 'boundItem.name',
        Cell: ({ value }) => (!value ? <span>-</span> : <span>{value}</span>),
      },
      {
        Header: 'Units',
        accessor: 'unitOfMeasurement.name',
        Cell: ({ value }) => (!value ? <span>-</span> : <span>{value}</span>),
      },
      {
        Header: 'Category',
        accessor: 'category.name',
        Cell: ({ value }) => (!value ? <span>-</span> : <span>{value}</span>),
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
      },
      {
        Header: 'Supplier',
        accessor: 'supplierName.name',
        Cell: ({ value }) => (!value ? <span>-</span> : <span>{value}</span>),
      },
      {
        Header: 'Warehouse',
        accessor: 'warehouse.store.name',
        Cell: ({ value }) => (!value ? <span>-</span> : <span className="text-[#429A24]">{value}</span>),
      },
      {
        Header: 'Store ID',
        accessor: 'warehouse.store.id',
        Cell: ({ value }) => (!value ? <span>-</span> : <span>{value}</span>),
      },
      {
        Header: 'Driver',
        accessor: 'driverName',
      },
      {
        Header: 'P.Number',
        accessor: 'phoneNo',
      },
      {
        Header: 'Plate NO',
        accessor: 'plateNo',
        Cell: ({ value }) => <span className="inline-block max-w-[150px] truncate text-[#A6A6A6]">{value}</span>,
      },
      {
        Header: 'Region',
        accessor: 'region.label',
        Cell: ({ value }) => <span className="text-[#A6A6A6]">{value || '--'}</span>,
      },
      {
        Header: 'Date',
        accessor: 'created_at',
        Cell: ({ value }) => <span>{format(new Date(value), 'EEE, MMM d, yyyy h:mm a')}</span>,
      },
      {
        Header: ActionsHeader,
        accessor: '_id',
        Cell: ({ value }) => (
          <ActionsPopover
            actions={[
              {
                text: 'View',
                onClickAction: () => navigate(`/finance/inbound-order/${value}`),
              },
            ]}
          />
        ),
      },
    ],
    [navigate],
  );

  return columns;
}
