import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

const settingsNavs = [
  { route: 'inbound', title: 'Inbound' },
  { route: 'outbound', title: 'Outbound' },
  { route: 'warehouse', title: 'Warehouse' },
  { route: 'regions-zones', title: 'Regions & Zones' },
  { route: 'audit-log', title: 'Audit' },
];

const Settings = () => {
  return (
    <>
      <div className="mb-9">
        {settingsNavs.map((nav) => (
          <NavLink key={uuid()} to={nav.route} className={({ isActive }) => `mr-2 rounded border px-5 py-[10px] text-sm ${isActive ? 'border-transparent bg-[#64B548] text-white' : 'border-[#c3c3c3] bg-white text-[#646464]'}`}>
            {nav.title}
          </NavLink>
        ))}
      </div>
      <Outlet />
    </>
  );
};

export default Settings;
