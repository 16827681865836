import { useState, useEffect } from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { useDispatch, useSelector } from 'react-redux';
import MetricCard from '@/components/metricCard/metricCard';
import CustomDateRangePicker from '@/components/form/customDateRangePicker';
import { getOverviewChartsData, getOverviewChartsDataFiltered } from '@/redux/features/charts/overviewChartsSlice';
import Spinner from '@/components/Spinner';
import { buildQueryParams } from '@/shared/buildQueryParams';

// Initial Query Params object
const userQuery = {};

const Overview = () => {
  const currentDateMinus7 = new Date();
  currentDateMinus7.setDate(new Date().getDate() - 7);
  // const [selectedOption, setSelectedOption] = useState(options[0])
  // const [dateRange, setDateRange] = useState([currentDateMinus7, new Date()])
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.overviewCharts);

  // const handleChange = (selectedOption) => {
  //   setSelectedOption(selectedOption)
  // }

  useEffect(() => {
    if (startDate && endDate) {
      userQuery.startDate = new Date(startDate).toISOString();
      userQuery.endDate = new Date(endDate).toISOString();
      dispatch(getOverviewChartsDataFiltered(buildQueryParams(userQuery)));
    }
  }, [startDate, endDate]);

  const handleDateChange = (update) => {
    setDateRange(update);
  };

  useEffect(() => {
    dispatch(getOverviewChartsData());
  }, []);

  const clearFilters = () => {
    dispatch(getOverviewChartsData());
    setDateRange([null, null]);
  };

  const inboundTotal = data?.inbounds.reduce((acc, cum) => acc + cum.weight, 0);
  const outboundTotal = data?.outbounds.reduce((acc, cum) => acc + cum.weight, 0);
  const stockTotal = data?.balances.reduce((acc, cum) => acc + cum.totalStock, 0);
  const totalBalanceTotal = data?.balances.reduce((acc, cum) => acc + cum.totalBalance, 0);
  const openingBalanceTotal = data?.balances.reduce((acc, cum) => acc + cum.openingBalance, 0);
  const closingBalanceTotal = data?.balances.reduce((acc, cum) => acc + cum.closingBalance, 0);

  return (
    <div className="relative">
      {loading && <Spinner />}
      <div className="mb-8 flex flex-col justify-between gap-3 lg:flex lg:flex-row lg:gap-0">
        <h1 className="text-2xl font-semibold text-title-color">Overview</h1>
        <div className="filters flex flex-col gap-4 md:flex-row md:items-center">
          {/* <CustomSelect value={selectedOption} onChange={handleChange} options={options} /> */}
          <div>
            <CustomDateRangePicker selectsRange startDate={startDate} endDate={endDate} onChange={handleDateChange} dateRange={dateRange} />
          </div>
          {/* <button className="h-[38px] rounded px-4 text-sm border border-[#c3c3c3] bg-white text-[#646464] flex items-center gap-2 w-fit">
            <img src={downloadIcon} alt="download Icon" />
            <span className='inline-block'>Export</span>
          </button> */}
          {/* Clear Filters */}
          <button type="button" onClick={clearFilters} className="flex h-[38px] w-fit items-center gap-2 rounded text-sm text-[#646464]">
            <span className="inline-block">Clear Filters</span>
            <XMarkIcon className="h-5 w-5 text-red-500" />
          </button>
        </div>
      </div>
      <div className="mb-5 grid grid-cols-1 justify-between gap-8 md:grid-cols-2 lg:grid-cols-3">
        <MetricCard title="Total Stock" gradient="green" leftUnit="MT" leftValue={stockTotal} data={data?.balances} />
        <MetricCard title="Inbound" gradient="purple" leftUnit="MT" leftValue={inboundTotal} data={data?.inbounds} />
        <MetricCard title="Outbound" gradient="yellow" leftUnit="MT" leftValue={outboundTotal} data={data?.outbounds} />
        <MetricCard title="Total Balance" gradient="green" leftUnit="naira" leftValue={totalBalanceTotal} data={data?.balances} />
        <MetricCard title="Opening Balance" gradient="purple" leftUnit="naira" leftValue={openingBalanceTotal} data={data?.balances} />
        <MetricCard title="Closing Balance" gradient="yellow" leftUnit="naira" leftValue={closingBalanceTotal} data={data?.balances} />
      </div>
    </div>
  );
};

export default Overview;
