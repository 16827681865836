import React from 'react'
import logo from '../assets/images/logo.png'

const AuthPageLayout = ({ children, title, subTitle }) => {
  return (
    <div className="bg-neutral flex items-center justify-center h-screen">
      <div className="w-[90%] max-w-[700px] bg-white shadow-md py-10 px-4 md:px-16">
        <div className="text-center">
          <div className="w-[72px] mx-auto mb-8">
            <img className="w-full" src={logo} alt="logo" />
          </div>
          <h1 className="text-2xl font-semibold mb-[6px]">{title}</h1>
          <p className="text-ash text-md mb-12 w-[60%] mx-auto">{subTitle}</p>
        </div>
        {children}
      </div>
    </div>
  )
}

export default AuthPageLayout
