import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import FormikControl from '@/components/formik/formikControl';
import Spinner from '@/components/Spinner';
import FormTemplate from '@/components/templates/formTemplate';
import { editCategory, getCategory } from '@/redux/features/settings/inboundSettings/inboundCategorySlice';

const EditCategory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { editCategoryLoading, getCategoryLoading } = useSelector((state) => state.inboundCategory);
  const [initialValues, setInitialValues] = useState({
    name: '',
    active: false,
  });

  const statusOptions = [
    { label: 'Select status', value: '', isDisabled: true },
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
  ];

  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    active: Yup.bool('Value must be true or false').required('Required'),
  });

  const onSubmit = (values) => {
    dispatch(editCategory({ id: params.id, ...values }))
      .unwrap()
      .then(() => {
        navigate(-1);
      });
  };

  useEffect(() => {
    dispatch(getCategory(params.id))
      .unwrap()
      .then((response) => {
        setInitialValues({
          name: response.data.name,
          active: response.data.active,
        });
      });
  }, []);

  const loading = editCategoryLoading || getCategoryLoading;

  return (
    <FormTemplate initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} title="Edit Category" submitBtnText="Save Changes" enableReinitialize>
      {loading && <Spinner />}
      <div>
        <FormikControl control="input" type="text" label="Category Name" name="name" placeholder="Category Name" />
      </div>
      <div>
        <FormikControl control="select" label="Status" name="active" options={statusOptions} />
      </div>
    </FormTemplate>
  );
};

export default EditCategory;
