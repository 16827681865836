const Clip = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3435 1.73219C10.6835 1.07219 9.806 0.742188 8.9285 0.742188C8.066 0.742188 7.196 1.07219 6.536 1.73219L1.046 7.22219C0.573496 7.69469 0.333496 8.31719 0.333496 8.93219C0.333496 9.54719 0.565996 10.1772 1.046 10.6497C1.511 11.1147 2.1335 11.3472 2.756 11.3472C3.3785 11.3472 4.001 11.1147 4.481 10.6347L9.9635 5.14469C10.5335 4.56719 10.5335 3.65969 9.971 3.09719C9.4085 2.53469 8.4785 2.52719 7.9085 3.09719L3.356 7.65719C3.176 7.84469 3.176 8.14469 3.3635 8.33219C3.551 8.51969 3.851 8.51969 4.046 8.33969L8.5985 3.77969C8.786 3.59219 9.101 3.59219 9.281 3.77219C9.4685 3.95969 9.4685 4.27469 9.281 4.46219L3.7985 9.95219C3.236 10.5147 2.2685 10.5072 1.7285 9.95969C1.166 9.39719 1.181 8.44469 1.736 7.88969L7.2335 2.40719C8.1635 1.47719 9.746 1.46219 10.6685 2.38469C11.5985 3.31469 11.5985 4.90469 10.6685 5.83469L5.3285 11.1822C5.1485 11.3697 5.1485 11.6622 5.336 11.8422C5.516 12.0222 5.8085 12.0222 5.996 11.8497V11.8572L11.3435 6.50969C12.0035 5.86469 12.3335 5.00219 12.3335 4.13219C12.3335 3.26219 12.0035 2.39219 11.3435 1.73219Z"
        fill="#3D3D3D"
      />
    </svg>
  );
};

export default Clip;
