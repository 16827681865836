import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '@/services/instance';

export const getAuditLogs = createAsyncThunk('AuditLogs', async (queryParams) => {
  const { data } = await instance.get(`logs${queryParams}`);
  return data;
});

const initialState = {
  auditLogs: [],
  getAuditLogsTypeLoading: false,
};

const auditLogSlice = createSlice({
  name: 'auditLogs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAuditLogs.pending, (state) => {
        state.getAuditLogsTypeLoading = true;
      })
      .addCase(getAuditLogs.fulfilled, (state, { payload: { data } }) => {
        state.auditLogs = data.data;
        state.getAuditLogsTypeLoading = false;
      })
      .addCase(getAuditLogs.rejected, (state) => {
        state.getAuditLogsTypeLoading = false;
      });
  },
});

export default auditLogSlice.reducer;
