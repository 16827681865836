const GridItem = ({ title, content }) => {
  return (
    <div>
      <div className="text-[11px] font-bold text-[#A6A6A6]">{title}</div>
      <div className="text-sm font-semibold text-[#2B2B2B]">{content}</div>
    </div>
  );
};

export default GridItem;
