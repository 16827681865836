import { v4 as uuid } from 'uuid';
import ActionStyle from './actionStyle';

const ActionsPopoverContent = ({ actions }) => {
  return (
    <>
      {actions.map((action) => (
        <ActionStyle key={uuid()} onClick={action.onClickAction}>
          {action.text}
        </ActionStyle>
      ))}
    </>
  );
};

export default ActionsPopoverContent;
