import { useNavigate } from 'react-router-dom';
import Card from '../card';
import leftArrowIcon from '@/assets/images/icons/left-arrow-icon.svg';
import penIcon from '@/assets/images/icons/pen-icon.svg';
import { cn } from '@/lib/utils';

const ViewItemTemplate = ({ actionText, title, children, btnClickRoute, headerCta, footer, fullWidth = false }) => {
  const navigate = useNavigate();

  return (
    <Card className={cn(fullWidth && 'min-w-full')}>
      <div className="pb-5 pt-5">
        <div className="flex justify-between">
          <button className="mr-2 flex h-[34px] items-center rounded border border-[#c3c3c3] bg-white px-5 text-sm italic text-[#646464]" type="button" onClick={() => navigate(-1)}>
            <span className="mr-[10px]">
              <img src={leftArrowIcon} alt="plus icon" />
            </span>
            Back
          </button>
          {headerCta || (
            <button type="button" onClick={() => navigate(btnClickRoute)} className="submit-button">
              <span className="mr-[10px]">
                <img src={penIcon} alt="plus icon" />
              </span>
              {actionText}
            </button>
          )}
        </div>
        <h3 className="mb-6 mt-10 text-2xl font-semibold text-[#3D3D3D]">{title}</h3>
        {children}
        {footer}
      </div>
    </Card>
  );
};

export default ViewItemTemplate;
