import ReactSelect from 'react-select';
import { Field } from 'formik';
import classNames from 'classnames';

// Custom styles for table react-select
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: '34px',
    boxShadow: state.isFocused ? null : null,
  }),

  valueContainer: (provided) => ({
    ...provided,
    minHeight: '34px',
    padding: '0 6px',
  }),

  input: (provided) => ({
    ...provided,
    margin: '0px',
  }),

  indicatorsContainer: (provided) => ({
    ...provided,
    minHeight: '34px',
  }),
};

const Select = (props) => {
  const { label, name, options, isMulti, isSearchable, ...rest } = props;

  const onChange = (form, field, option) => {
    form.setFieldValue(field.name, isMulti ? option.map((item) => item.value) : option.value);
  };

  const getValue = (field) => {
    if (options) {
      if (isMulti) {
        // For multi-select, find options whose values are included in initialValues
        return options.filter((option) => field.value.includes(option.value));
      }
      // For single select, find the option whose value matches initialValues
      return options.find((option) => option.value === field.value);
    }
    return isMulti ? [] : ''; // Return empty array for multi-select, empty string for single select
  };

  return (
    <div className="form-control">
      <label className="form-control-label" htmlFor={name}>
        {label}
      </label>
      <Field name={name}>
        {({ form, field, meta }) => {
          // const { setFieldValue } = form;
          const {
            // value,
            onBlur,
          } = field;
          return (
            <>
              <ReactSelect
                className="w-full text-[13px]"
                id={name}
                {...rest}
                value={getValue(field)}
                onChange={(val) => onChange(form, field, val)}
                onBlur={onBlur}
                options={options}
                menuPlacement="auto"
                styles={customStyles}
                isSearchable={isSearchable}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 4,
                  colors: {
                    ...theme.colors,
                    primary25: '#EDF6EA',
                    primary: '#64B548',
                  },
                })}
                isMulti={isMulti}
              />
              <small
                className={classNames('text-[red]', 'block', 'opacity-0', {
                  'opacity-100': meta.touched && meta.error,
                })}
              >
                {meta.error || 'error'}
              </small>
            </>
          );
        }}
      </Field>
    </div>
  );
};

export default Select;
