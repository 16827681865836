import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import Table from '@/components/table/appTable';
import useInboundDetailsTable from './inboundDetailsTableColumn';
import searchIcon from '@/assets/images/icons/search-icon.svg';

const InboundDetailsTable = ({ fetchData, pageCount, setPageSize, initialPageSize, setPage }) => {
  const { inboundData, loading } = useSelector((state) => state.inbound);
  useEffect(() => {
    fetchData({ pageSize: initialPageSize, pageIndex: 0 });
  }, [fetchData, initialPageSize]);

  const columns = useInboundDetailsTable();
  // @TODO -- Please investigate the difference between AppTableNew and AppTable
  return (
    <>
      <div className="mb-7 mt-12 flex flex-col justify-between lg:flex-row ">
        <div className="flex flex-col gap-3 md:flex-row md:items-center md:gap-4">
          <div className="relative h-[32px] overflow-auto">
            <input className="form-input mb-2 h-full w-[25vw] min-w-[300px] rounded border border-[#C3C3C3] bg-white px-7 text-[13px] placeholder-[#7D7D7D] focus:outline-none md:mb-0" type="search" name="search" id="search" placeholder="Search" />
            <img className="absolute left-[10px] top-[50%] w-[12px] translate-y-[-50%]" src={searchIcon} alt="Search icon" />
          </div>
        </div>
      </div>
      <div className="overflow-hidden text-gray-900">
        <main className="overflow-hidden">
          <div className="w-full">
            <Table columns={columns} data={inboundData} fetchData={fetchData} loading={loading} pageCount={pageCount} pageSize={initialPageSize} setInitialPageSize={setPageSize} setPage={setPage} />
          </div>
        </main>
      </div>
    </>
  );
};

export default InboundDetailsTable;
