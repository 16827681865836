import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import MetricCard from '@/components/metricCard/metricCard';
import { Button } from '@/components/ui/button';

import { buildQueryParams } from '@/shared/buildQueryParams';
import { getAllOrdersData, getAMetricCardData } from '@/redux/features/orders/ordersSlice';

import AllFinanceTable from './allFinanceTable';
import PendingOrdersTable from './pendingOrdersTable';
import CompletedOrdersTable from './completedOrdersTable';

const FinanceHome = () => {
  const dispatch = useDispatch();
  const { metricCardData } = useSelector((state) => state.orders);

  // Table states
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [pageCount, setPageCount] = useState(0);

  // Query Params
  const [userQuery, setUserQuery] = useState({});

  // fetch data for cards
  useEffect(() => {
    dispatch(getAMetricCardData());
  }, [dispatch]);

  // Update userQuery when page size and current page change
  useEffect(() => {
    setUserQuery((prevState) => ({ ...prevState, skip: page * pageSize, limit: pageSize }));
  }, [page, pageSize]);

  // Fetch data when user query changes
  useEffect(() => {
    if (!_.isEmpty(userQuery)) {
      dispatch(getAllOrdersData(buildQueryParams(userQuery)))
        .unwrap()
        .then((result) => {
          setPageCount(Math.ceil(result.data.totalDocumentCount / userQuery.limit));
        });
    }
  }, [dispatch, userQuery]);

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabs, setTabs] = useState([
    {
      title: 'All Orders',
      active: true,
      status: '',
    },
    {
      title: 'Pending Orders',
      active: false,
      status: 'pending',
    },
    {
      title: 'Completed Orders',
      active: false,
      status: 'completed',
    },
  ]);

  const setActiveTab = (selectedTab, index) => {
    setActiveTabIndex(index);
    setTabs(
      tabs.map((tab) => {
        tab.active = tab.title === selectedTab.title;
        return tab;
      }),
    );
  };

  const renderTable = () => {
    switch (activeTabIndex) {
      case 0:
        return <AllFinanceTable pageCount={pageCount} setPageSize={setPageSize} pageSize={pageSize} setPage={setPage} tableHeading={tabs[activeTabIndex].title} />;
      case 1:
        return <PendingOrdersTable pageCount={pageCount} setPageSize={setPageSize} pageSize={pageSize} setPage={setPage} tableHeading={tabs[activeTabIndex].title} />;
      case 2:
        return <CompletedOrdersTable pageCount={pageCount} setPageSize={setPageSize} pageSize={pageSize} setPage={setPage} tableHeading={tabs[activeTabIndex].title} />;
      default:
        return <AllFinanceTable pageCount={pageCount} setPageSize={setPageSize} pageSize={pageSize} setPage={setPage} tableHeading={tabs[activeTabIndex].title} />;
    }
  };

  return (
    <>
      <div className="mb-8 flex flex-col justify-between gap-3 lg:flex lg:flex-row lg:gap-0">
        <h1 className="text-2xl font-semibold text-title-color">Finance</h1>
      </div>
      <div className="mb-5 grid grid-cols-1 justify-between gap-8 md:grid-cols-2 lg:grid-cols-3">
        <MetricCard title="Total Created" leftUnit="" leftValue={metricCardData?.totalDocumentCount} />
        <MetricCard title="Total Pending" leftUnit="" leftValue={metricCardData?.totalPending} />
        <MetricCard title="Total Completed" leftUnit="" leftValue={metricCardData?.totalCompleted} />
      </div>
      <div className="mt-6 flex gap-4 overflow-auto">
        {tabs.map((tab, index) => (
          <Button key={uuid()} variant={tab.active ? 'default' : 'outline'} className="rounded" size="sm" onClick={() => setActiveTab(tab, index)}>
            {tab.title}
          </Button>
        ))}
      </div>
      {renderTable()}
    </>
  );
};

export default FinanceHome;
