/* eslint-disable jsx-a11y/label-has-associated-control */
import { Fragment } from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from './textError';

const RadioButtons = (props) => {
  const { label, name, options, ...rest } = props;
  return (
    <div className="form-group">
      <label className="form-control-label">{label}</label>
      <Field name={name}>
        {({ field }) => {
          return options.map((option) => {
            return (
              <Fragment key={option.key}>
                <input type="radio" className="form-check-input" id={option.value} {...field} {...rest} value={option.value} checked={field.value === option.value} />
                <label className="form-check-label" htmlFor={option.value}>
                  {option.key}
                </label>
              </Fragment>
            );
          });
        }}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
};

export default RadioButtons;
