import { Outlet } from 'react-router-dom';
import Layout from './layout';
import ErrorBoundary from '../components/errorBoundary';

const DefaultLayout = () => {
  return (
    <Layout>
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    </Layout>
  );
};

export default DefaultLayout;
