import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { getPaymentHistory } from '@/redux/features/inbound/inboundSlice';

const PaymentHistoryTable = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const payments = useSelector((state) => state.inbound.getPaymentHistory);
  const loading = useSelector((state) => state.inbound.loading);

  const pageSize = 3;
  const columns = Array(3).fill(null);

  useEffect(() => {
    dispatch(getPaymentHistory(params.id));
  }, [dispatch, params.id]);

  const formatPaymentDateTime = (paidAt) => {
    const paymentDate = new Date(paidAt);
    const dateString = paymentDate.toLocaleDateString();
    const timeString = paymentDate.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
    return `${dateString}, ${timeString}`;
  };
  return (
    <div className="mb-9">
      <h3 className="text-1xl mb-6 mt-8 font-semibold">Payment History</h3>
      {loading ? (
        <div className="bg-white">
          {Array(pageSize)
            .fill(null)
            .map(() => (
              <div key={uuid()} className="flex justify-between border-[#c3c3c3] bg-white  py-4">
                {columns.map((_, index) => (
                  <div key={uuid()} className={`whitespace-nowrap ${index === 0 ? 'sticky bg-white' : ''} px-6 text-sm text-[#2B2B2B]`}>
                    <div className="h-[32px] w-[100px] animate-pulse rounded-md bg-gray-300" />
                  </div>
                ))}
              </div>
            ))}
        </div>
      ) : (
        payments.map((payment) => (
          <div key={payment._id} className="flex w-full justify-between border-[#c3c3c3] bg-white px-6 py-4 text-[12px] font-semibold">
            <span>&#8358; {payment.amount}</span>
            <span>{formatPaymentDateTime(payment.paidAt).toLocaleString()}</span>
            <span>{payment.paymentType}</span>
          </div>
        ))
      )}
    </div>
  );
};

export default PaymentHistoryTable;
