import { useLayoutEffect, useState } from 'react';
import { Router } from 'react-router-dom';

const CustomRouter = ({ basename, children, history, ...otherProps }) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router basename={basename} location={state.location} navigationType={state.action} navigator={history} {...otherProps}>
      {children}
    </Router>
  );
};

export default CustomRouter;
