import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '@/components/Spinner';
import Table, { ActionsPopover, FormatDate } from '@/components/table/appTable';
import { deleteZone } from '@/redux/features/settings/regionsAndZonesSettings/zoneSlice';

const AddZoneTable = ({ fetchData, pageCount, setInitialPageSize, initialPageSize }) => {
  const dispatch = useDispatch();

  const handleDelete = useCallback(
    (id) => {
      dispatch(deleteZone(id))
        .unwrap()
        .then(() => {
          fetchData({ pageSize: initialPageSize, pageIndex: 0 });
        });
    },
    [initialPageSize],
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Label',
        accessor: 'label',
      },
      {
        Header: 'Value',
        accessor: 'value',
      },
      {
        Header: 'Date Created',
        accessor: 'created_at',
        Cell: FormatDate,
      },
      {
        Header: '',
        accessor: '_id',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ value }) => (
          <ActionsPopover
            actions={[
              {
                text: 'Edit',
                onClickAction: () => null,
              },
              {
                text: 'Delete',
                onClickAction: () => {
                  const response = window.confirm('Confirm delete?');
                  if (response) {
                    handleDelete(value);
                  }
                },
              },
            ]}
          />
        ),
      },
    ],
    [initialPageSize],
  );

  const { zones, getZonesLoading, deleteZoneLoading } = useSelector((state) => state.zone);

  return (
    <div className="relative overflow-hidden text-gray-900">
      {deleteZoneLoading && <Spinner />}
      <main className="overflow-hidden">
        <div className="w-full">
          <Table columns={columns} data={zones} fetchData={fetchData} loading={getZonesLoading} pageCount={pageCount} pageSize={initialPageSize} setInitialPageSize={setInitialPageSize} />
        </div>
      </main>
    </div>
  );
};

export default AddZoneTable;
