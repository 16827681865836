import { NavLink } from 'react-router-dom';
import overviewIcon from './assets/images/icons/overview-icon.svg';
import overviewIconActive from './assets/images/icons/overview-active-icon.svg';
import inboundIcon from './assets/images/icons/inbound-icon.svg';
import inboundIconActive from './assets/images/icons/inbound-active-icon.svg';
import outboundIcon from './assets/images/icons/outbound-icon.svg';
import outboundIconActive from './assets/images/icons/outbound-active-icon.svg';
import warehouseIcon from './assets/images/icons/warehouse-icon.svg';
import warehouseIconActive from './assets/images/icons/warehouse-active-icon.svg';
import settingsIcon from './assets/images/icons/settings-icon.svg';
import settingsIconActive from './assets/images/icons/settings-active-icon.svg';
import adminIcon from './assets/images/icons/admin-icon.svg';
import adminIconActive from './assets/images/icons/admin-active-icon.svg';
// import procurementIcon from './assets/images/icons/procurement-icon.svg';
import storeCountIcon from './assets/images/icons/storeCount-icon.svg';
import storeCountActiveIcon from './assets/images/icons/storeCount-active-icon.svg';
import financeActiveIcon from './assets/images/icons/finance-active-icon.svg';
import financeIcon from './assets/images/icons/finance-icon.svg';
import orderRecordsIcon from './assets/images/icons/orderRecords-icon.svg';
import orderRecordsActiveIcon from './assets/images/icons/orderRecords-active-icon.svg';

const imgClassName = 'w-full ';

// eslint-disable-next-line no-underscore-dangle
const _nav = [
  {
    component: NavLink,
    name: 'Overview',
    to: '/',
    icon: <img className={imgClassName} src={overviewIcon} alt="overview icon" />,
    activeIcon: <img className={imgClassName} src={overviewIconActive} alt="overview active icon" />,
  },
  {
    component: NavLink,
    name: 'Inbound',
    to: '/inbound',
    icon: <img className={imgClassName} src={inboundIcon} alt="inbound icon" />,
    activeIcon: <img className={imgClassName} src={inboundIconActive} alt="inbound icon" />,
  },
  {
    component: NavLink,
    name: 'Outbound',
    to: '/outbound',
    icon: <img className={imgClassName} src={outboundIcon} alt="outbound icon" />,
    activeIcon: <img className={imgClassName} src={outboundIconActive} alt="outbound icon" />,
  },
  {
    component: NavLink,
    name: 'Store Count',
    to: '/store-count',
    icon: <img className={imgClassName} src={storeCountIcon} alt="store count icon" />,
    activeIcon: <img className={imgClassName} src={storeCountActiveIcon} alt="store count icon" />,
  },
  {
    component: NavLink,
    name: 'Warehouse',
    to: '/warehouse',
    icon: <img className={imgClassName} src={warehouseIcon} alt="warehouse icon" />,
    activeIcon: <img className={imgClassName} src={warehouseIconActive} alt="warehouse icon" />,
  },
  // {
  //   component: NavLink,
  //   name: 'Procurement',
  //   to: '/procurement',
  //   icon: <img className={`${imgClassName}bg-[#383838]`} src={procurementIcon} alt="procurement icon" />,
  //   activeIcon: <img className={`${imgClassName}bg-green`} src={procurementIcon} alt="procurement icon" />,
  // },
  {
    component: NavLink,
    name: 'Order Records',
    to: '/order-records',
    icon: <img className={imgClassName} src={orderRecordsIcon} alt="order records icon" />,
    activeIcon: <img className={imgClassName} src={orderRecordsActiveIcon} alt="order records icon" />,
    // hidden: false,
  },
  {
    component: NavLink,
    name: 'Finance',
    to: '/finance',
    icon: <img className={imgClassName} src={financeIcon} alt="finance icon" />,
    activeIcon: <img className={imgClassName} src={financeActiveIcon} alt="finance icon" />,
  },
  {
    component: NavLink,
    name: 'Settings',
    to: '/settings',
    icon: <img className={imgClassName} src={settingsIcon} alt="settings icon" />,
    activeIcon: <img className={imgClassName} src={settingsIconActive} alt="settings icon" />,
  },
  {
    component: NavLink,
    name: 'Admin',
    to: '/admin',
    icon: <img className={imgClassName} src={adminIcon} alt="admin icon" />,
    activeIcon: <img className={imgClassName} src={adminIconActive} alt="admin icon" />,
  },
];

export default _nav;
