import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '@/components/table/appTable';
import searchIcon from '@/assets/images/icons/search-icon.svg';
import useInboundTableColumns from './inboundTableColumns';
import { deleteInboundItem } from '@/redux/features/inbound/inboundSlice';

const InboundTable = ({ fetchData, pageCount, tableHeading, onSearch, setInitialPageSize, initialPageSize }) => {
  const dispatch = useDispatch();
  const { inboundData, loading } = useSelector((state) => state.inbound);
  const [searchTerm, setSearchTerm] = useState('');

  const handleDelete = useCallback(
    (id) => {
      dispatch(deleteInboundItem(id))
        .unwrap()
        .then(() => {
          fetchData({ pageSize: initialPageSize, pageIndex: 0 });
        });
    },
    [dispatch, fetchData, initialPageSize],
  );

  const columns = useInboundTableColumns(handleDelete);

  const handleChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
    onSearch(value);
  };

  return (
    <>
      <div className="mb-7 mt-12 flex flex-col justify-between lg:flex-row ">
        <h3 className="mb-2 text-2xl font-semibold lg:mb-0">{tableHeading}</h3>
        <div className="flex flex-col gap-3 md:flex-row md:items-center md:gap-4">
          <div className="relative h-[32px] overflow-auto">
            <input
              onChange={handleChange}
              className="form-input mb-2 h-full w-[25vw] min-w-[300px] rounded border border-[#C3C3C3] bg-white px-7 text-[13px] placeholder-[#7D7D7D] focus:outline-none md:mb-0"
              type="search"
              name="search"
              id="search"
              value={searchTerm}
              placeholder="Search by warehouse"
            />
            <img className="absolute left-[10px] top-[50%] w-[12px] translate-y-[-50%]" src={searchIcon} alt="Search icon" />
          </div>
        </div>
      </div>
      <div className="overflow-hidden text-gray-900">
        <main className="overflow-hidden">
          <div className="w-full">
            <Table columns={columns} data={inboundData} fetchData={fetchData} loading={loading} pageCount={pageCount} pageSize={initialPageSize} setInitialPageSize={setInitialPageSize} />
          </div>
        </main>
      </div>
    </>
  );
};

export default InboundTable;
