/* eslint-disable no-plusplus */
import { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getSuppliers } from '@/redux/features/settings/inboundSettings/inboundLogisticsSlice';
import { buildQueryParams } from '@/shared/buildQueryParams';
import LogisticsForm from './logisticsForm';
import LogisticsTable from './logisticsTable';
import SearchLogistics from './searchLogistics';

// Initial Query Params object
const userQuery = { skip: 0, limit: 5 };

const Logistics = () => {
  const dispatch = useDispatch();
  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = useRef(0);
  const [initialPageSize, setInitialPageSize] = useState(2);

  const fetchData = useCallback(({ pageSize, pageIndex }) => {
    userQuery.limit = pageSize;
    userQuery.skip = pageIndex * pageSize;

    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current) {
      dispatch(getSuppliers(buildQueryParams(userQuery)))
        .unwrap()
        .then((result) => {
          setPageCount(Math.ceil(result.data.totalDocumentCount / pageSize));
        });
    }
  }, []);

  const handleSearch = (term) => {
    if (term) {
      userQuery.search = term;
    } else {
      delete userQuery.search;
    }
    fetchData({ pageSize: 2, pageIndex: 0 });
  };

  return (
    <div className="mb-9">
      <h3 className="mb-6 text-2xl font-semibold">Logistics</h3>
      <div className="relative">
        <LogisticsForm initialPageSize={initialPageSize} fetchData={fetchData} />
        <div className="absolute right-8 top-[50%] translate-y-[-50%]">
          <SearchLogistics onSearch={handleSearch} />
        </div>
      </div>
      <LogisticsTable setInitialPageSize={setInitialPageSize} initialPageSize={initialPageSize} fetchData={fetchData} pageCount={pageCount} />
    </div>
  );
};

export default Logistics;
